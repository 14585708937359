import { css } from "@emotion/react";
import top001 from "components/img/top/top001.jpg";

export const styles = {
  rootPC: css`
    display: flex;
  `,

  rootSP: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  `,

  decorationPC: css`
    background-image: url(${top001});
    background-size: cover;
    background-position: left;
    height: 100vh;
    width: 50vw;
  `,

  decorationSP: css`
    height: 0px;
    width: 0px;
  `,

  rootAddEventPC: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
    height: 100vh;
    overflow: scroll;
  `,

  rootAddEventSP: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 340px;
  `,

  stepper: css`
    margin: 20px 0;
  `,

  stepperLabel: css`
    font-size: 19px;
    font-weight: bold;
  `,

  stepperContent: css`
    width: 300px;
  `,
};
