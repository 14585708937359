import React, { useState, useMemo } from "react";

// 外部パッケージ
import useMedia from "use-media";
import { useForm, Controller } from "react-hook-form";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRecoilState } from "recoil";
import { userInputDataAtom } from "../common/UserInputDataAtom";

// MUI
import { Box, Button } from "@mui/material";

// その他
import theme from "components/MuiTheme";
import { ThumbnailImage } from "components/common/ThumbnailImage";

const styles = {
  h1: css`
    padding-top: 110px;
    font-size: 48px;
    color: #fff;
    text-shadow: 0 0 0.2em rgba(0, 0, 0, 1);
    height: 50%;
  `,
};

const NewThumbnail = (props) => {
  const [userInputData, setUserInputData] = useRecoilState(userInputDataAtom);
  const { control, handleSubmit } = useForm();

  // サムネイル変更
  // HACK 複数枚アップロード
  const [thumbnailImageFile, setThumbnail] = useState(userInputData.thumbnailImageFile);
  const handleIconChange = (event) => {
    if (event.target.files !== null) {
      const file = event.target.files[0];
      setThumbnail(event.target.files[0]);
      setUserInputData({
        ...userInputData,
        thumbnailImageFile: file,
        requiresUploadThumbnail: true,
        requiresRemoveOldThumbnail: !!userInputData.thumbnailImageKey, // keyがある = 既にS3に登録されている = 古いものは削除
      });
    }
  };

  const onSubmit = (data) => {
    props.changeBtnStatus();
    props.handleNext();
  };

  const ThumbnailImageView = useMemo(() => {
    if (thumbnailImageFile) {
      // 今回サムネイルが設定された場合
      return <ThumbnailImage thumbnailImageFile={thumbnailImageFile} size={300} />;
    } else {
      return <ThumbnailImage thumbnailImageKey={userInputData.thumbnailImageKey} size={300} />;
    }
  }, [thumbnailImageFile, userInputData.thumbnailImageKey]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      {ThumbnailImageView}
      <Controller
        control={control}
        name="thumbnailImageFile"
        render={({ field }) => (
          <input
            {...field}
            id="image"
            type="file"
            accept="image/*"
            onChange={handleIconChange}
            style={{ display: "none" }}
          />
        )}
      />
      <label htmlFor="image">
        <Button
          color="secondary"
          size="small"
          variant="outlined"
          component="span"
          style={{ margin: "15px 0 5px 0 ", fontSize: 14 }}
        >
          端末からアップロード
        </Button>
      </label>
      <Box sx={{ mb: 2 }}>
        <Button type="submit" disabled={props.status} variant="contained" sx={{ mt: 1, mr: 1 }}>
          次へ
        </Button>
      </Box>
    </Box>
  );
};

export default NewThumbnail;

const NewThumbnailTitle = () => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  return (
    <div>
      {!isWide && (
        <h1 css={styles.h1}>
          サムネイルを設定する
          <br />
          <br />
          イベントの魅力が伝わる
          <br />
          写真を選びましょう
        </h1>
      )}
    </div>
  );
};

export { NewThumbnailTitle };
