import React from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useMedia from "use-media";
import SimpleImageSlider from "react-simple-image-slider";
import { useNavigate, useLocation } from "react-router-dom";

// Material UI
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import PeopleIcon from "@mui/icons-material/People";
import CampaignIcon from "@mui/icons-material/Campaign";
import { Button, Rating } from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import Divider from "@mui/material/Divider";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import WcIcon from "@mui/icons-material/Wc";
import WifiIcon from "@mui/icons-material/Wifi";
import TvIcon from "@mui/icons-material/Tv";
import KitchenIcon from "@mui/icons-material/Kitchen";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import SpeakerIcon from "@mui/icons-material/Speaker";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import DeckIcon from "@mui/icons-material/Deck";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CameraIndoorIcon from "@mui/icons-material/CameraIndoor";
import PowerIcon from "@mui/icons-material/Power";
import { ThemeProvider } from "@mui/system";

// AWS
import { API, graphqlOperation } from "aws-amplify";

// その他
import { createSpace } from "graphql/mutations";
import { spaces } from "components/common/SelectValues";
import { receptions } from "components/common/SelectValues";
import theme from "components/MuiTheme";
import { useAuth } from "components/auth/AuthCommon";
import { UserIcon } from "components/common/UserIcon";
import { convertValueToLabel } from "components/common/Utils";
import { locale } from "components/common/SelectValues";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${theme.palette.grey[200]};
  `,

  rootSpacePC: css`
    padding: 30px 100px 0 100px;
    max-width: 1000px;
    background: white;
  `,

  rootSpaceSP: css`
    padding: 15px 15px 0 15px;
    max-width: 340px;
    background: white;
  `,

  thumbnailPC: css`
    width: 600px;
    border-radius: 10px;
    margin-bottom: 20px;
  `,

  thumbnailSP: css`
    width: 330px;
    border-radius: 10px;
    margin-bottom: 15px;
  `,

  titleCategoryPC: css`
    display: flex;
    flex-direction: column;
    & div {
      display: flex;
      background: ${theme.palette.secondary.light};
      color: ${theme.palette.secondary.contrastText};
      border-radius: 16px;
      padding-left: 8px;
      padding-right: 8px;
      margin-right: auto;
      font-size: 17px;
      margin-top: 20px;
    }

    & h1 {
      display: flex;
      font-size: 29px;
    }
  `,

  titleCategorySP: css`
    display: flex;
    flex-direction: column;
    & div {
      display: flex;
      background: ${theme.palette.secondary.light};
      color: ${theme.palette.secondary.contrastText};
      border-radius: 16px;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 14px;
      text-align: center;
      margin-right: auto;
      margin-top: 15px;
    }
    & h1 {
      display: flex;
      font-size: 16.5px;
    }
  `,

  detailsPC: css`
    display: flex;
    flex-direction: column;
    margin: 0 10px 20px 10px;
    & div {
      display: flex;
    }
  `,

  detailsSP: css`
    display: flex;
    flex-direction: column;
    margin: 0 0px 20px 0px;
  `,

  detailsListPC: css`
    width: 120px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  `,

  detailsListSP: css`
    /* width: 100px; */
    display: flex;
    align-items: center;
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: bolder;
  `,

  lastDetailsList: css`
    width: 120px;
    display: flex;
    align-items: left;
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: auto;
  `,
  muiIconPC: css`
    margin-right: 5px;
    color: #606060;
  `,

  muiIconSP: css`
    margin-right: 2px;
    margin-left: 15px;
    color: #606060;
    font-size: 19px;
  `,

  organizerPC: css`
    display: flex;
    align-items: center;
    margin: 0 0 10px 0px;
  `,

  organizerSP: css`
    display: flex;
    align-items: center;
    margin: 0 0 10px 0px;
  `,

  infomationSP: css`
    display: flex;
    font-size: 14.5px;
    margin: 0 0 15px 30px;
    text-align: left;
  `,

  participant: css`
    width: 20px;
    height: 20px;
  `,

  overview: css`
    max-width: 600px;
    text-align: left;
    margin-bottom: 20px;
  `,

  submitButton: css`
    margin-bottom: 20px;
  `,

  facilityPC: css`
    max-width: 600px;
    padding: 2% 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,

  facilitySP: css`
    padding: 2% 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 330px;
  `,

  timePC: css`
    max-width: 600px;
    flex-direction: column;
    margin-left: 20px;
    padding-left: 100px;
    padding-bottom: 10px;
    margin-top: -35px;
  `,

  timeSP: css`
    padding: 2% 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 330px;
  `,
};

// TODO 前画面から入力された値を表示する
// TODO [S3]サムネイル登録
// TODO [DB]DBにレコードを登録する（他テーブル連携あり）（この画面で表示されていた項目が編集できること）
// TODO 「戻る」ボタン押下時に入力された値を送り返す
// TODO [S3]サムネイルの設定は、前画面から送られたS3のキーを元に画像をダウンロードして表示する

const Space = () => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  const auth = useAuth();

  const params = useLocation().state;

  const navigate = useNavigate();

  const [currentState, setCurrentState] = React.useState({});

  const createspaceIconKeyInUser = () => {
    const sd = new Date(params.Price.startDate);
    const ed = new Date(params.Price.endDate);
    const utcSunStartTime = params.Price.sunStartTime;
    const utcSunEndTime = params.Price.sunEndTime;
    const utcMonStartTime = params.Price.monStartTime;
    const utcMonEndTime = params.Price.monEndTime;
    const utcTueStartTime = params.Price.tueStartTime;
    const utcTueEndTime = params.Price.tueEndTime;
    const utcWedStartTime = params.Price.wedStartTime;
    const utcWedEndTime = params.Price.wedEndTime;
    const utcThuStartTime = params.Price.thuStartTime;
    const utcThuEndTime = params.Price.thuEndTime;
    const utcFriStartTime = params.Price.friStartTime;
    const utcFriEndTime = params.Price.friEndTime;
    const utcSatStartTime = params.Price.satStartTime;
    const utcSatEndTime = params.Price.satEndTime;
    const utcHolStartTime = params.Price.holStartTime;
    const utcHolEndTime = params.Price.holEndTime;

    const utcStartDate =
      sd.getFullYear() + "-" + ("0" + (sd.getMonth() + 1)).slice(-2) + "-" + ("0" + sd.getDate()).slice(-2);

    const utcEndDate =
      ed.getFullYear() + "-" + ("0" + (ed.getMonth() + 1)).slice(-2) + "-" + ("0" + ed.getDate()).slice(-2);

    const facilities = [];
    if (params.Facility.power) facilities.push("facility_000");
    if (params.Facility.cloth) facilities.push("facility_001");
    if (params.Facility.air) facilities.push("facility_002");
    if (params.Facility.range) facilities.push("facility_003");
    if (params.Facility.kit) facilities.push("facility_004");
    if (params.Facility.wifi) facilities.push("facility_005");
    if (params.Facility.tv) facilities.push("facility_006");
    if (params.Facility.camera) facilities.push("facility_007");
    if (params.Facility.music) facilities.push("facility_008");
    if (params.Facility.bbq) facilities.push("facility_009");
    if (params.Facility.toilet) facilities.push("facility_010");
    if (params.Facility.mat) facilities.push("facility_011");
    if (params.Facility.teras) facilities.push("facility_012");
    if (params.Facility.parking) facilities.push("facility_013");
    if (params.Facility.rif) facilities.push("facility_014");
    if (params.Facility.car) facilities.push("facility_015");

    const variables = {
      input: {
        images: params.NewThumbnail,
        name: params.Type.spaceName,
        overview: params.Type.overview,
        categoryID: params.Type.spaceCategory,
        size: params.Type.roomSize,
        capacity: params.Type.roomCapacity,
        postalCode: params.Address.postCode1 + params.Address.postCode2,
        prefCode: params.Address.prefectures,
        city: params.Address.cities,
        address: params.Address.address,
        contact: params.Facility.contact,
        feePerHour: params.Price.feePerHour,
        startDate: utcStartDate,
        endDate: utcEndDate,
        userID: auth.userID,
        sunAvailableStartTime: utcSunStartTime,
        sunAvailableEndTime: utcSunEndTime,
        monAvailableStartTime: utcMonStartTime,
        monAvailableEndTime: utcMonEndTime,
        tueAvailableStartTime: utcTueStartTime,
        tueAvailableEndTime: utcTueEndTime,
        wedAvailableStartTime: utcWedStartTime,
        wedAvailableEndTime: utcWedEndTime,
        thuAvailableStartTime: utcThuStartTime,
        thuAvailableEndTime: utcThuEndTime,
        friAvailableStartTime: utcFriStartTime,
        friAvailableEndTime: utcFriEndTime,
        satAvailableStartTime: utcSatStartTime,
        satAvailableEndTime: utcSatEndTime,
        holAvailableStartTime: utcHolStartTime,
        holAvailableEndTime: utcHolEndTime,
        reservationPeriod: params.Price.reservationPeriod,
        reservationApproval: params.Price.reservationApproval,
      },
    };

    console.log(facilities);

    try {
      const result = API.graphql(graphqlOperation(createSpace, variables));
      console.log(result);
      return Promise.resolve("success");
    } catch (error) {
      console.log(error);
      return Promise.reject("ファイルのアップロードに失敗しました。（DB）");
    }
  };

  const onChangeUserIcon = (event) => {
    createspaceIconKeyInUser().catch((error) => {
      alert(error);
      return;
    });
  };

  const value = {
    currentState,
    setCurrentState,
  };

  return (
    <div css={styles.root}>
      <div css={isWide ? styles.rootSpaceSP : styles.rootSpacePC}>
        <SimpleImageSlider
          css={isWide ? styles.thumbnailSP : styles.thumbnailPC}
          width={isWide ? 340 : 600}
          height={isWide ? 340 : 600}
          bordeRadius={10}
          images={params.NewThumbnail}
          showBullets={true}
          showNavs={true}
          alt="サムネイル"
        />
        <div css={isWide ? styles.titleCategorySP : styles.titleCategoryPC}>
          <div>{spaces[params.Type.spaceCategory - 1].label}</div>
          <h1>{params.Type.spaceName}</h1>
        </div>
        <div css={styles.overview}>{params.Type.overview}</div>
        <div css={isWide ? styles.detailsSP : styles.detailsPC}>
          <div>
            <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
              <LocationOnIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
              場所
            </div>
            <div css={isWide && styles.infomationSP}>
              {convertValueToLabel(locale, params.Address.prefectures)}
              {params.Address.cities}
              {params.Address.address}
            </div>
          </div>
          <div>
            <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
              <CurrencyYenIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
              料金
            </div>
            <div css={isWide && styles.infomationSP}>{params.Price.feePerHour}円/時間</div>
          </div>
          <div>
            <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
              <PeopleIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
              収容人数
            </div>
            <div css={isWide && styles.infomationSP}>〜{params.Type.roomCapacity}人</div>
          </div>
          <div>
            <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
              <StoreIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
              サイズ
            </div>
            <div css={isWide && styles.infomationSP}>{params.Type.roomSize}㎡</div>
          </div>
          <div>
            <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
              <CampaignIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
              オーナー
            </div>
            <div css={isWide && styles.infomationSP}>
              <UserIcon
                userID={auth.userID}
                size={25}
                options={{
                  labeled: true,
                  enableLink: true,
                }}
                css={styles.organizerIcon}
                alt="プロフィール"
              />
              <Rating name="read-only" value={value} readOnly />
            </div>
          </div>
          <Divider />
          <h3>提供設備・サービス</h3>
          <div css={isWide ? styles.facilitySP : styles.facilityPC}>
            {params.Facility.power && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <PowerIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                電源
              </div>
            )}
            {params.Facility.air && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <DeviceThermostatIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                エアコン
              </div>
            )}
            {params.Facility.toilet && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <WcIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                トイレ
              </div>
            )}
            {params.Facility.wifi && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <WifiIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                Wi-Fi
              </div>
            )}
            {params.Facility.tv && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <TvIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                テレビ
              </div>
            )}
            {params.Facility.rif && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <KitchenIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                冷蔵庫
              </div>
            )}

            {params.Facility.range && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <MicrowaveIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                電子レンジ
              </div>
            )}
            {params.Facility.kitchen && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <RestaurantIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                キッチン
              </div>
            )}
            {params.Facility.music && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <SpeakerIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                スピーカー
              </div>
            )}
            {params.Facility.bbq && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <OutdoorGrillIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                BBQ設備
              </div>
            )}
            {params.Facility.cloth && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <CheckroomIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                更衣室
              </div>
            )}

            {params.Facility.teras && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <DeckIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                テラス
              </div>
            )}
            {params.Facility.parking && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <LocalParkingIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                無料駐車場
              </div>
            )}
            {params.Facility.car && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <DriveEtaIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                有料駐車場
              </div>
            )}
            {params.Facility.mat && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <SelfImprovementIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                ヨガマット
              </div>
            )}
            {params.Facility.camera && (
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
                <CameraIndoorIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
                防犯カメラ
              </div>
            )}
          </div>
          <div css={styles.overview}>{params.Facility.contact}</div>
        </div>
        <Divider />

        <div css={isWide ? styles.rootSpaceSP : styles.rootSpacePC}>
          <h3>利用可能時間と予約方法</h3>{" "}
          <div css={isWide ? styles.detailsSP : styles.detailsPC}>
            <div>
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>　 掲載期間</div>
              <div css={isWide && styles.infomationSP}>
                {params.Price.startDate}〜{params.Price.endDate}
              </div>
            </div>

            <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>　 曜日時間</div>
            <div css={isWide && styles.infomationSP}>
              <div css={isWide ? styles.timeSP : styles.timePC}>
                {params.Price.sunCheck && (
                  <div>
                    日曜日：{params.Price.sunStartTime}〜{params.Price.sunEndTime}
                  </div>
                )}
                {params.Price.monCheck && (
                  <div>
                    月曜日：{params.Price.monStartTime}〜{params.Price.monEndTime}
                  </div>
                )}
                {params.Price.tueCheck && (
                  <div>
                    火曜日：{params.Price.tueStartTime}〜{params.Price.tueEndTime}
                  </div>
                )}
                {params.Price.wedCheck && (
                  <div>
                    水曜日：{params.Price.wedStartTime}〜{params.Price.wedEndTime}
                  </div>
                )}
                {params.Price.thuCheck && (
                  <div>
                    木曜日：{params.Price.thuStartTime}〜{params.Price.thuEndTime}
                  </div>
                )}
                {params.Price.friCheck && (
                  <div>
                    金曜日：{params.Price.friStartTime}〜{params.Price.friEndTime}
                  </div>
                )}
                {params.Price.satCheck && (
                  <div>
                    土曜日：{params.Price.satStartTime}〜{params.Price.satEndTime}
                  </div>
                )}
                {params.Price.holCheck && (
                  <div>
                    祝日　：{params.Price.holStartTime}〜{params.Price.holEndTime}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>　 予約期間</div>
              <div css={isWide && styles.infomationSP}>{params.Price.reservationPeriod}日前まで</div>
            </div>
            <div>
              <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>　 承認方法</div>
              <div css={isWide && styles.infomationSP}>{receptions[params.Price.reservationApproval - 1].label}</div>
            </div>
          </div>
        </div>

        <ThemeProvider theme={theme}>
          <Button type="submit" fullWidth variant="contained" onClick={onChangeUserIcon} css={styles.submitButton}>
            スペースを登録する
          </Button>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            fullWidth
            css={styles.submitButton}
          >
            入力画面に戻る
          </Button>
        </ThemeProvider>
      </div>
    </div>
  );
};
export default Space;
