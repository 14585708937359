import React, { memo } from "react";
import "./App.css";
import Header from "./components/Header";
import Top from "./components/Top";
import Home from "./components/Home";
// import Recomendation from "./components/Recomendation";
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SignIn from "components/auth/SignIn";
import Profile from "./components/myPage/Profile";
import Message from "./components/Message/Message";
import AddSpace from "components/AddSpace/AddSpace";
import SignUp from "components/auth/SignUp";
import EditProfile from "./components/myPage/EditProfile";
import Term from "./components/Term";
import AddEvent from "./components/AddEvent/AddEvent";
import ChangePassword from "components/auth/ChangePassword";
import ChangePasswordCompleted from "components/auth/ChangePasswordCompleted";
import ForgotPassword from "components/auth/ForgotPassword";
import ForgotPasswordSubmit from "components/auth/ForgotPasswordSubmit";
import ForgotPasswordCompleted from "components/auth/ForgotPasswordCompleted";
import DeletUser from "components/auth/DeleteUser";
import NotFound from "components/NotFound";
import Ebizrule from "components/Ebizrule";
import Policy from "components/Policy";
import Usage from "./components/Usage/Usage";
import FindSpace from "components/FindSpace/FindSpace";
import SpaceHome from "components/FindSpace/SpaceHome";
import SpaceRecomendation from "components/FindSpace/SpaceRecomendation";
import Event from "components/Event";
import Payment from "components/Payment/Payment";
import RateReview from "components/myPage/RateReview";
import Space from "components/AddSpace/Space";
import SearchResult from "components/SearchResult/SearchResult";
import CancelEvent from "components/CancelEvent";

import { useAuth } from "./components/auth/AuthCommon";

import { RecoilRoot } from "recoil";

const appStyle = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
};

const App = memo(() => {
  const auth = useAuth();

  //未ログインの状態で各ページにアクセスさせない制御
  const PrivateRoute = ({ children }) => {
    if (!auth.isLoading) {
      if (auth.userID) {
        return children;
      } else {
        return <Navigate to="/signIn" />;
      }
    } else {
      return children;
    }
  };

  // ログインしている状態でログイン画面にアクセスさせない制御
  const NotSignedRoute = ({ children }) => {
    if (auth.isAuthenticated) {
      return <Navigate to="/" />;
    } else {
      return children;
    }
  };

  return (
    <div className="App">
      <div style={appStyle}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route
              path="/"
              element={
                <div>
                  <Top />
                  <Home />
                  {/* <Recomendation /> */}
                </div>
              }
            />
            <Route
              path="event/:eventID"
              element={
                <RecoilRoot>
                  <Event />
                </RecoilRoot>
              }
            />
            <Route
              path="addEvent"
              element={
                <RecoilRoot>
                  <PrivateRoute>
                    <AddEvent />
                  </PrivateRoute>
                </RecoilRoot>
              }
            />
            <Route path="message" element={<Message />} />
            <Route
              path="signIn"
              element={
                <NotSignedRoute>
                  <SignIn />
                </NotSignedRoute>
              }
            />
            <Route
              path="signUp"
              element={
                <NotSignedRoute>
                  <SignUp />
                </NotSignedRoute>
              }
            />
            <Route
              path="changePassword"
              element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              }
            />
            <Route
              path="changePasswordCompleted"
              element={
                <PrivateRoute>
                  <ChangePasswordCompleted />
                </PrivateRoute>
              }
            />
            <Route path="forgotPassword" element={<ForgotPassword />} />
            <Route path="forgotPasswordSubmit" element={<ForgotPasswordSubmit />} />
            <Route path="forgotPasswordCompleted" element={<ForgotPasswordCompleted />} />
            <Route
              path="unsubscribe"
              element={
                <PrivateRoute>
                  <DeletUser />
                </PrivateRoute>
              }
            />
            <Route
              path="profile"
              element={
                <PrivateRoute>
                  <Profile isMyPage={true} />
                </PrivateRoute>
              }
            />
            <Route path="profile/:userID" element={<Profile isMyPage={false} />} />
            <Route
              path="profile/edit"
              element={
                <PrivateRoute>
                  <EditProfile />
                </PrivateRoute>
              }
            />
            <Route path="term" element={<Term />} />

            <Route path="*" element={<NotFound />} />
            <Route path="ebizrule" element={<Ebizrule />} />
            <Route path="policy" element={<Policy />} />
            <Route path="usage" element={<Usage />} />
            <Route
              path="addspace"
              element={
                <PrivateRoute>
                  <AddSpace />
                </PrivateRoute>
              }
            />
            <Route
              path="payment"
              element={
                <PrivateRoute>
                  <Payment />
                </PrivateRoute>
              }
            />
            <Route
              path="rateReview"
              element={
                <PrivateRoute>
                  <RateReview />
                </PrivateRoute>
              }
            />
            <Route path="space" element={<Space />} />
            <Route path="searchResult/*" element={<SearchResult />} />
            <Route
              path="cancelEvent"
              element={
                <PrivateRoute>
                  <CancelEvent />
                </PrivateRoute>
              }
            />
            <Route
              path="findspace"
              element={
                <div>
                  <FindSpace />
                  <SpaceHome />
                  <SpaceRecomendation />
                </div>
              }
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
});

export default App;
