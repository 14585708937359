import React, { useState, useEffect } from "react";

// 外部パッケージ
import { useLocation, useNavigate } from "react-router-dom";
import useMedia from "use-media";
import { useRecoilState } from "recoil";
import { userInputDataAtom } from "../common/UserInputDataAtom";
import { getCurrentDate } from "components/common/Utils";

// MUI
import { Box, ThemeProvider, Typography, Stepper, Step, StepLabel, StepContent } from "@mui/material";

// その他
import NewThumbnail, { NewThumbnailTitle } from "components/AddEvent/NewThumbnail";
import NewDetails, { NewDetailsTitle } from "components/AddEvent/NewDetails";
import NewCategoryTarget, { NewCategoryTargetTitle } from "components/AddEvent/NewCategoryTarget";
import NewOverview, { NewOverviewTitle } from "components/AddEvent/NewOverview";
import theme from "components/MuiTheme";
import { useAuth } from "components/auth/AuthCommon";
import { getDebugger } from "components/Debugger";
/** @jsxImportSource @emotion/react */
import { styles } from "./AddEventStyles";
var debug = getDebugger(false);

// ここからメインのRegisterEventコンポーネント
function AddEvent() {
  debug("AddEvent is loading.");
  const auth = useAuth();
  const location = useLocation().state;
  const [userInputData, setUserInputData] = useRecoilState(userInputDataAtom);

  useEffect(() => {
    if (location) {
      setUserInputData({
        ...userInputData,
        thumbnailImageKey: location?.currentState?.thumbnailImageKey || "",
        NewDetails: {
          eventName: location?.currentState?.name || "",
          startDate: location?.currentState?.startDate || getCurrentDate(),
          endDate: location?.currentState?.endDate || getCurrentDate(),
          startTime: location?.currentState?.startTime || "00:00",
          endTime: location?.currentState?.endTime || "00:00",
          postCode1: location?.currentState?.postCode1 || "",
          postCode2: location?.currentState?.postCode2 || "",
          prefectures: location?.currentState?.prefecture || "",
          cities: location?.currentState?.city || "",
          address: location?.currentState?.address || "",
        },
        NewCategoryTarget: {
          hashtag1: location?.currentState?.hashtags[0] || "",
          hashtag2: location?.currentState?.hashtags[1] || "",
          hashtag3: location?.currentState?.hashtags[2] || "",
          hashtag4: location?.currentState?.hashtags[3] || "",
          mainCategory: location?.currentState?.mainCategory || "",
        },
        NewTicketSupport: {
          ticketName: "",
          ticketPrice: 0,
          ticketSalesNumber: 0,
        },
        NewOverview: {
          overview: location?.currentState?.overview || "",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setUserInputData]);

  // 次へボタンダブルクリック制御
  const [btnStatus, setBtnStatus] = useState(false);

  const changeBtnStatus = () => {
    setTimeout(() => {
      setBtnStatus(false);
    }, 1000);

    setBtnStatus(true);
  };

  // ステッパー
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const navigate = useNavigate();
  const createPreview = () => {
    navigate("/event/preview", {
      state: {
        currentState: userInputData,
        userID: auth.userID,
        eventID: location?.eventID || "",
      },
    });
  };

  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });

  const steps = [
    {
      label: "サムネイルを設定する",
      description: [
        <NewThumbnail
          key="NewThumbnail"
          handleNext={handleNext}
          status={btnStatus}
          changeBtnStatus={changeBtnStatus}
        />,
      ],
      optional: "イベントの魅力が伝わる写真を選びましょう",
      title: [<NewThumbnailTitle key="NewThumbnailTitle" />],
    },
    {
      label: "イベントの基本情報を書く",
      description: [
        <NewDetails
          key="NewDetails"
          handleNext={handleNext}
          handleBack={handleBack}
          status={btnStatus}
          changeBtnStatus={changeBtnStatus}
        />,
      ],
      optional: "いつ、どこで、どんなイベントを開催するのか",
      title: [<NewDetailsTitle key="NewDetailsTitle" />],
    },
    {
      label: "カテゴリー/ターゲットを絞る",
      description: [
        <NewCategoryTarget
          key="NewCategoryTarget"
          handleNext={handleNext}
          handleBack={handleBack}
          status={btnStatus}
          changeBtnStatus={changeBtnStatus}
        />,
      ],
      optional: "イベントの情報を届けたい人を設定しましょう",
      title: [<NewCategoryTargetTitle key="NewCategoryTargetTitle" />],
    },
    // TODO チケット機能を追加する
    // {
    //   label: "チケット作成",
    //   description: [
    //     <NewTicketSupport
    //       key="NewTicketSupport"
    //       handleNext={handleNext}
    //       handleBack={handleBack}
    //       status={btnStatus}
    //       changeBtnStatus={changeBtnStatus}
    //     />,
    //   ],
    //   optional: "参加料の価格を決めましょう",
    //   title: [<NewTicketSupportTitle key="NewTicketSupportTitle" />],
    // },
    {
      label: "イベント概要を書く",
      description: [
        <NewOverview
          key="NewOverview"
          createPreview={createPreview}
          handleBack={handleBack}
          status={btnStatus}
          changeBtnStatus={changeBtnStatus}
        />,
      ],
      optional: "このイベントの魅力を存分に伝わる文章を作成しよう",
      title: [<NewOverviewTitle key="NewOverviewTitle" />],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <main css={isWide ? styles.rootSP : styles.rootPC}>
        <div css={isWide ? styles.decorationSP : styles.decorationPC}>{steps[activeStep].title}</div>
        <Box noValidate css={isWide ? styles.rootAddEventSP : styles.rootAddEventPC}>
          <Stepper activeStep={activeStep} orientation="vertical" css={styles.stepper}>
            {steps.map((step, index) => (
              <Step key={`step_${index}`}>
                <StepLabel optional={<Typography variant="caption">{step.optional}</Typography>}>
                  <div css={styles.stepperLabel}>{step.label}</div>
                </StepLabel>
                <StepContent css={styles.stepperContent}>{step.description}</StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </main>
    </ThemeProvider>
  );
}

export default AddEvent;
