// TODO サムネイル未設定時の処理
import { useCallback, useMemo } from "react";

// 外部パッケージ
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useMedia from "use-media";

// MUI
import FeedIcon from "@mui/icons-material/Feed";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
// import PeopleIcon from "@mui/icons-material/People";
import CampaignIcon from "@mui/icons-material/Campaign";
// import { Avatar, AvatarGroup } from "@mui/material";
import { Button, CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/system";

// その他
import theme from "components/MuiTheme";
import { MainCategoriesType, locale } from "components/common/SelectValues";
import { UserIcon } from "components/common/UserIcon";
import { getDebugger } from "components/Debugger";
import { convertValueToLabel, formatDateTimeToEventDateTime } from "./common/Utils";
import { useAuth } from "./auth/AuthCommon";
import { EventStatus } from "models";

import Hashtag from "./common/parts/Hashtag/Hashtag";
/** @jsxImportSource @emotion/react */
import { styles } from "components/AddEvent/EventStyles";
import MainCategoryLabel from "./common/parts/MainCategoryLabel/MainCategoryLabel";
import { useEvent } from "components/AddEvent/EventHooks";
import { ThumbnailImage } from "./common/ThumbnailImage";

var debug = getDebugger(false);

const Event = () => {
  debug("Event.js is rendering.");

  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  const auth = useAuth();
  const { eventID } = useParams();
  const location = useLocation().state;
  const { eventValues, isDBLoading, isS3Loading, onClickCreateEvent, onClickUpdateEvent } = useEvent(eventID, location);

  const navigate = useNavigate();
  const toCancelEvent = useCallback(() => {
    navigate("/cancelEvent", { state: { userID: eventValues.userID, eventID: eventID } });
  }, [navigate, eventValues, eventID]);
  const toEditEvent = useCallback(() => {
    navigate("/addEvent", { state: { userID: eventValues.userID, eventID: eventID, currentState: eventValues } });
  }, [navigate, eventValues, eventID]);
  const backToPreviousPage = () => {
    navigate(-1);
  };

  const ThumbnailImageView = useMemo(() => {
    if (eventValues.thumbnailImageFile) {
      debug("calling by the data");
      // thumbnailImageFileが存在する = 今回サムネイルが登録されているため、keyの有無に関わらず最新を表示する
      return <ThumbnailImage thumbnailImageFile={eventValues.thumbnailImageFile} size={isWide ? 340 : 600} />;
    } else {
      debug("calling by the key");
      // サムネイルが設定されていない（keyがない）場合、デフォルトアイコンを表示する
      return <ThumbnailImage thumbnailImageKey={eventValues.thumbnailImageKey} size={isWide ? 340 : 600} />;
    }
  }, [eventValues, isWide]);

  !eventValues.name && (
    <ThemeProvider theme={theme}>
      <h3 style={{ height: "100vh" }}>
        <CircularProgress />
      </h3>
    </ThemeProvider>
  );

  return (
    <div css={styles.root}>
      <div css={isWide ? styles.rootEventSP : styles.rootEventPC}>
        {ThumbnailImageView}
        <div css={isWide ? styles.titleCategorySP : styles.titleCategoryPC}>
          <MainCategoryLabel
            mainCategory={eventValues.mainCategory as MainCategoriesType}
            options={{ size: isWide ? "small" : "large" }}
          />
          <h1>{eventValues.name}</h1>
        </div>
        <div css={isWide ? styles.hashtagsSP : styles.hashtagsPC}>
          <Hashtag hashtagList={eventValues.hashtags ?? []} options={{ size: isWide ? "small" : "medium" }} />
        </div>
        <div css={isWide ? styles.detailsSP : styles.detailsPC}>
          <div>
            <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
              <CampaignIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
              <div>主催者</div>
            </div>
            <div css={isWide ? styles.organizerSP : styles.organizerPC}>
              <UserIcon
                userID={eventValues.userID}
                size={29}
                options={{
                  labeled: true,
                  labeledSize: isWide ? 14.5 : undefined,
                  // rated: true,
                  enableLink: true,
                }}
              />
            </div>
          </div>
          <div>
            <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
              <EventIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
              日時
            </div>
            <div css={isWide && styles.informationSP}>
              {formatDateTimeToEventDateTime(
                eventValues.startDate + " " + eventValues.startTime,
                eventValues.endDate + " " + eventValues.endTime
              )}
            </div>
          </div>
          <div>
            <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
              <LocationOnIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
              場所
            </div>
            {location?.eventID === "" || eventID === "preview" ? (
              <div css={isWide && styles.informationSP}>
                〒{eventValues.postCode1}-{eventValues.postCode2} {convertValueToLabel(locale, eventValues.prefecture)}{" "}
                {eventValues.city} {eventValues.address}
              </div>
            ) : (
              <div css={isWide && styles.informationSP}>
                {convertValueToLabel(locale, eventValues.prefecture)} {eventValues.city} {eventValues.address}
              </div>
            )}
          </div>
          {/* // TODO チケット情報を表示する */}
          {/* <div>
            <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
              <CurrencyYenIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
              参加費
            </div>
            <div css={isWide && styles.informationSP}>
              {eventValues.ticketPrice && eventValues.ticketPrice.toLocaleString("ja")}円
            </div>
          </div> 
          <div>
            <div css={isWide ? styles.detailsListSP : styles.detailsListPC}>
              <PeopleIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
              人数
            </div>
            <div css={isWide && styles.informationSP}>
              {eventValues.participant} 名
              <AvatarGroup max={isWide ? 12 : 23}>
                {eventValues.participantIcons &&
                  eventValues.participantIcons.map((icon, index) => (
                    // TODO UserIcon導入
                    <Avatar
                      key={index}
                      src={require("./img/icon/S__2478653" + icon.src + ".jpg")}
                      alt={icon.alt}
                      css={styles.participant}
                    />
                  ))}
              </AvatarGroup>
            </div>
          </div>*/}
          <div>
            <div css={isWide ? styles.detailsListSP : styles.lastDetailsListPC}>
              <FeedIcon css={isWide ? styles.muiIconSP : styles.muiIconPC} />
              概要
            </div>
            <div css={isWide ? styles.informationSP : styles.overview}>{eventValues.overview}</div>
          </div>
        </div>
        <ThemeProvider theme={theme}>
          {location && eventID === "preview" ? (
            <div>
              {location.eventID === "" || location.eventID === "preview" ? (
                <Button
                  fullWidth
                  onClick={onClickCreateEvent}
                  disabled={isDBLoading || isS3Loading}
                  variant="contained"
                  css={styles.submitButton}
                >
                  {isS3Loading
                    ? "イベント画像をアップロード中..."
                    : isDBLoading
                    ? "イベントを作成中..."
                    : "イベントを作成する"}
                </Button>
              ) : (
                <Button
                  fullWidth
                  onClick={onClickUpdateEvent}
                  disabled={isDBLoading || isS3Loading}
                  variant="contained"
                  css={styles.submitButton}
                >
                  {isS3Loading
                    ? "イベント画像をアップロード中..."
                    : isDBLoading
                    ? "イベントを更新中..."
                    : "イベント情報を更新する"}
                </Button>
              )}
              <Button onClick={backToPreviousPage} fullWidth css={styles.submitButton}>
                入力画面に戻る
              </Button>
            </div>
          ) : auth.userID === eventValues.userID ? (
            <div>
              {eventValues.status !== EventStatus.COMPLETED && eventValues.status !== EventStatus.CANCELED && (
                <Button fullWidth onClick={toCancelEvent} css={styles.submitButton}>
                  イベントを中止する
                </Button>
              )}
              <Button fullWidth onClick={toEditEvent} css={styles.submitButton}>
                イベントを編集する
              </Button>
              <Button fullWidth onClick={backToPreviousPage} css={styles.submitButton}>
                戻る
              </Button>
            </div>
          ) : (
            // TODO チケット購入へ
            // <Button
            //   fullWidth
            //   onClick={() => {
            //     alert("イベントに参加する");
            //   }}
            //   variant="contained"
            //   css={styles.submitButton}
            // >
            //   イベントに参加する
            // </Button>
            <>
              <Button fullWidth onClick={backToPreviousPage} css={styles.submitButton}>
                戻る
              </Button>
            </>
          )}
        </ThemeProvider>
      </div>
    </div>
  );
};

export default Event;
