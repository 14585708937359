import React, { useState, useEffect } from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

// MUI
import { Box, ThemeProvider, Typography, Button, TextField, MenuItem, Modal, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// AWS
import { API, graphqlOperation } from "aws-amplify";

// コンポーネント
import theme from "components/MuiTheme";
import { locale, jobs } from "components/common/SelectValues";
import { UserIcon } from "components/common/UserIcon";
import { useAuth } from "components/auth/AuthCommon";
import { getDebugger } from "components/Debugger";
import { getUser } from "graphql/queries";
import { updateUserForEditProfile } from "graphql/customQueries";
import { useS3 } from "hooks/useS3Hooks";
import { S3_PREFIX } from "utils/constants";
import { formatDate } from "components/common/Utils";

// スタイル
const root = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: theme.palette.grey[200],
};

const rootEditProfile = {
  marginTop: 20,
  marginBottom: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: " 0 30px 30px 30px",
  background: "white",
  borderRadius: 5,
};

const editProfileStyle = {
  width: 300,
  paddingTop: 20,
};

const iconStyle = {
  margin: "15px 0",
};

const buttonStyle = {
  marginTop: 15,
  height: 40,
};

const styles = {
  modalDiv: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 4%;

    & .modalTitleTypography {
      font-weight: 400;
      text-align: left;
    }

    & .modalCloseIconGrid {
      text-align: right;
      position: relative;
      top: -23px;
      left: 23px;
    }
  `,
};

var debug = getDebugger(false);

function EditProfile() {
  debug("EditProfile is loading.");
  const auth = useAuth();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isDBLoading, setIsDBLoading] = React.useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);
  const navigate = useNavigate();
  const { putFileToS3, removeFileFromS3 } = useS3();

  // 職業、住所のプルダウン
  const [values, setValues] = useState({
    nickname: "",
    locale: "",
    job: "",
    profile: "",
    userIconKey: "",
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  // 初期処理
  useEffect(() => {
    const runAPI = async () => {
      debug("useEffect is loading.");
      debug("auth.userID", auth.userID);
      if (auth.userID === "") return;
      const result = await API.graphql(graphqlOperation(getUser, { id: auth.userID }));
      debug(result);
      setValues({
        nickname: result.data.getUser.nickname,
        locale: result.data.getUser.locale,
        job: result.data.getUser.job,
        profile: result.data.getUser.profile,
        userIconKey: result.data.getUser.userIconKey,
      });
    };

    runAPI().catch((error) => {
      debug(error);
      alert("ユーザIDの取得に失敗しました。");
    });
  }, [auth.userID]);

  // アイコン画像更新
  const onChangeUserIcon = async (event) => {
    try {
      if (event.target.files !== null) {
        const file = event.target.files[0];
        const dateTime = formatDate(new Date(), { isUTC: true, hasTime: true }); // YYYYMMDDhhmmss
        const result = await putFileToS3(dateTime + file.name, file, {
          prefix: S3_PREFIX.USER_ICON,
          maxWidthOrHeight: 400,
        });

        if (values.userIconKey) {
          await removeFileFromS3(values.userIconKey);
        }

        await updateUserIconKeyInUser(result.key);
        alert("アイコンを更新しました。");
        // HACK アイコンだけリロードしたい
        window.location.reload(true);
      }
    } catch (error) {
      debug(error);
      alert("アイコンの更新に失敗しました。");
    }
  };

  const updateUserIconKeyInUser = async (key) => {
    const variables = {
      input: {
        id: auth.userID,
        userIconKey: key,
      },
    };

    // TODO [S3][DB]プロフィールで画像を変更時、画面を更新しないと反映されない
    try {
      await API.graphql(graphqlOperation(updateUserForEditProfile, variables));
      return Promise.resolve("success");
    } catch (error) {
      debug(error);
      return Promise.reject("ファイルのアップロードに失敗しました。（DB）");
    }
  };

  // プロフィール更新
  const onSubmitUpdate = (event) => {
    updateAttributesInUser(event);
  };

  const updateAttributesInUser = async (event) => {
    if (isDBLoading) return;
    setIsDBLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const variables = {
      input: {
        id: auth.userID,
        nickname: data.get("nickname"),
        locale: data.get("locale"),
        job: data.get("job"),
        profile: data.get("profile"),
      },
    };

    try {
      await API.graphql(graphqlOperation(updateUserForEditProfile, variables));
      handleModalOpen();
    } catch (error) {
      debug(error);
      alert("プロフィールの更新に失敗しました。");
    } finally {
      setIsDBLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box style={root}>
        <Box component="form" onSubmit={onSubmitUpdate} noValidate style={rootEditProfile}>
          <Typography component="h1" variant="h5" style={editProfileStyle}>
            プロフィール編集
          </Typography>
          <div style={iconStyle}>
            <UserIcon userID={auth.userID} size={150} />
          </div>
          <input id="image" type="file" accept="image/*" onChange={onChangeUserIcon} style={{ display: "none" }} />
          <label htmlFor="image">
            <Button variant="contained" color="secondary" component="span">
              {auth.isLoading ? "変更中…" : "アイコン変更"}
            </Button>
          </label>
          <TextField
            color="secondary"
            margin="normal"
            autoComplete="nickname"
            name="nickname"
            required
            fullWidth
            id="inputNickname"
            label="ニックネーム(10字以内)"
            value={values.nickname}
            inputProps={{ maxLength: 10 }}
            onChange={handleChange("nickname")}
          />
          <TextField
            select
            name="locale"
            id="inputLocale"
            label="住まい"
            onChange={handleChange("locale")}
            value={values.locale}
            variant="outlined"
            color="secondary"
            margin="normal"
            fullWidth
            style={{ textAlign: "left" }}
          >
            {locale.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            name="job"
            id="inputJob"
            label="職業"
            onChange={handleChange("job")}
            value={values.job}
            variant="outlined"
            color="secondary"
            margin="normal"
            fullWidth
            style={{ textAlign: "left" }}
          >
            {jobs.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            color="secondary"
            margin="normal"
            multiline
            rows={5}
            required
            fullWidth
            id="inputProfile"
            label="自己紹介(110字以内)"
            name="profile"
            autoComplete="profile"
            inputProps={{ maxLength: 110 }}
            value={values.profile}
            onChange={handleChange("profile")}
          />
          <Button style={buttonStyle} type="submit" fullWidth variant="contained">
            {auth.isLoading ? "更新中..." : "更新"}
          </Button>
          <Button style={buttonStyle} fullWidth onClick={() => navigate("/profile")}>
            戻る
          </Button>
        </Box>
        <Modal open={isModalOpen} onClose={handleModalClose}>
          <div css={styles.modalDiv}>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item>プロフィールを更新しました。</Grid>
              <Grid item className="modalCloseIconGrid">
                <IconButton onClick={handleModalClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Button style={buttonStyle} fullWidth onClick={() => navigate("/profile")}>
              マイページに戻る
            </Button>
          </div>
        </Modal>
      </Box>
    </ThemeProvider>
  );
}

export default EditProfile;
