/** アプリケーション定数クラス */
export const applicationConst = {
  /** 画面表示条件 */
  displayCondition: {
    always: "always",
    authenticated: "authenticated",
    unauthenticated: "unauthenticated",
  },

  /** 曜日 */
  days: [
    {
      label: "日曜日",
      dayIndex: "sun",
    },
    {
      label: "月曜日",
      dayIndex: "mon",
    },
    {
      label: "火曜日",
      dayIndex: "tue",
    },
    {
      label: "水曜日",
      dayIndex: "wed",
    },
    {
      label: "木曜日",
      dayIndex: "thu",
    },
    {
      label: "金曜日",
      dayIndex: "fri",
    },
    {
      label: "土曜日",
      dayIndex: "sat",
    },
    {
      label: "祝日　",
      dayIndex: "hol",
    },
  ],

  /** ハッシュタグ登録上限 */
  maximumHashtags: 4,
};

/** S3のprefix */
export const S3_PREFIX = {
  USER_ICON: "userIcon",
  THUMBNAIL: "thumbnail",
};
