import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { getEvent } from "graphql/queries";
import { getDebugger } from "components/Debugger";

// AWS
import { API, graphqlOperation } from "aws-amplify";
import { createEvent, updateEvent } from "graphql/mutations";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { Event } from "models/API";
import { Location } from "react-router-dom";
import { eventValuesType } from "./EventStateHooks";

var debug = getDebugger(false);

/**
 * イベントの登録・更新のHooks
 */
export const useEventDB = () => {
  const [isDBLoading, setIsDBLoading] = useState(false);
  /**
   * イベント情報を取得する
   */
  const getEventInfo = useCallback(async (eventID: string) => {
    try {
      const result = (await API.graphql(graphqlOperation(getEvent, { id: eventID }))) as GraphQLResult<{
        getEvent: Event;
      }>;
      debug(result);
      if (!result || !result.data) throw new Error("イベント情報の取得に失敗しました。(DB)");

      return {
        thumbnailImageKey: result.data.getEvent.thumbnailImageKey,
        mainCategory: result.data.getEvent.mainCategory,
        name: result.data.getEvent.name,
        hashtags: result.data.getEvent.hashtags,
        userID: result.data.getEvent.userID,
        prefecture: result.data.getEvent.prefecture,
        city: result.data.getEvent.city,
        // TODO schema.graphqlを適正化させてから扱えるようにする
        // ticketPrice: result.data.getEvent.Tickets,
        // participant: result.data.getEvent.EventEntries,
        // participantIcons: result.data.getEvent.EventEntries,
        overview: result.data.getEvent.overview,
        startDate: result.data.getEvent.startDate,
        endDate: result.data.getEvent.endDate,
        startTime: result.data.getEvent.startTime,
        endTime: result.data.getEvent.endTime,
        postCode1: result.data.getEvent.postCode1,
        postCode2: result.data.getEvent.postCode2,
        address: result.data.getEvent.address,
        /* ticketのデータ取得について要確認 */
        ticketName: "",
        // ticketSalesNumber: "",
        status: result.data.getEvent.status,
      };
    } catch (error) {
      debug(error);
      return await Promise.reject("イベント情報の取得に失敗しました。(DB)");
    }
  }, []);

  /**
   * チケット情報を取得す処理
   * // TODO DBからチケット情報を取得する
   */
  const setEventValuesForTicket = useCallback(
    (setEventValues: Dispatch<SetStateAction<eventValuesType>>, result: eventValuesType) => {
      setEventValues({
        ...result,
        // TODO schema.graphqlを適正化させてから扱えるようにする
        //  ticketPrice: result.ticketPrice,
        ticketPrice: "(サンプル)1,500 ",
        // participant: result.participant,
      });
    },
    []
  );

  /** DBにイベントを登録する */
  const createEventInFront = useCallback(
    async (location: Location["state"], thumbnailImageKey: Event["thumbnailImageKey"]) => {
      const variables = {
        input: {
          // id は自動生成
          name: location.currentState.NewDetails.eventName,
          thumbnailImageKey: thumbnailImageKey,
          mainCategory: location.currentState.NewCategoryTarget.mainCategory,
          overview: location.currentState.NewOverview.overview,
          status: "AVAILABLE",
          userID: location.userID,
          startDate: location.currentState.NewDetails.startDate,
          startTime: location.currentState.NewDetails.startTime,
          endDate: location.currentState.NewDetails.endDate,
          endTime: location.currentState.NewDetails.endTime,
          // TODO schema.graphqlを適正化させてから扱えるようにする
          // EventEntries: {
          //   userID: location.userID,
          //   status: "VERIFIED",
          // },
          postCode1: location.currentState.NewDetails.postCode1,
          postCode2: location.currentState.NewDetails.postCode2,
          prefecture: location.currentState.NewDetails.prefectures,
          city: location.currentState.NewDetails.cities,
          address: location.currentState.NewDetails.address,
          hashtags: [
            location.currentState.NewCategoryTarget.hashtag1,
            location.currentState.NewCategoryTarget.hashtag2,
            location.currentState.NewCategoryTarget.hashtag3,
            location.currentState.NewCategoryTarget.hashtag4,
          ],
          // TODO schema.graphqlを適正化させてから扱えるようにする
          // Tickets: "",
        },
      };
      debug(variables);
      try {
        setIsDBLoading(true);
        await API.graphql(graphqlOperation(createEvent, variables));
        return await Promise.resolve({
          success: true,
          message: "イベント登録が完了しました。",
        });
      } catch (error) {
        return await Promise.reject({
          success: false,
          message: "イベント登録に失敗しました。（DB）",
          error,
        });
      } finally {
        setIsDBLoading(false);
      }
    },
    [setIsDBLoading]
  );

  /** DBのイベントを更新する */
  const updateEventInFront = useCallback(
    async (location: Location["state"], thumbnailImageKey: string) => {
      const variables = {
        input: {
          id: location.eventID, //event id は既存のものをキーとする
          name: location.currentState.NewDetails.eventName,
          thumbnailImageKey: thumbnailImageKey,
          mainCategory: location.currentState.NewCategoryTarget.mainCategory,
          overview: location.currentState.NewOverview.overview,
          status: "AVAILABLE",
          userID: location.userID,
          startDate: location.currentState.NewDetails.startDate,
          startTime: location.currentState.NewDetails.startTime,
          endDate: location.currentState.NewDetails.endDate,
          endTime: location.currentState.NewDetails.endTime,
          // TODO schema.graphqlを適正化させてから扱えるようにする
          // EventEntries: {
          //   userID: location.userID,
          //   status: "VERIFIED",
          // },
          postCode1: location.currentState.NewDetails.postCode1,
          postCode2: location.currentState.NewDetails.postCode2,
          prefecture: location.currentState.NewDetails.prefectures,
          city: location.currentState.NewDetails.cities,
          address: location.currentState.NewDetails.address,
          hashtags: [
            location.currentState.NewCategoryTarget.hashtag1,
            location.currentState.NewCategoryTarget.hashtag2,
            location.currentState.NewCategoryTarget.hashtag3,
            location.currentState.NewCategoryTarget.hashtag4,
          ],
          // TODO schema.graphqlを適正化させてから扱えるようにする
          // Tickets: "",
        },
      };
      debug(variables);

      try {
        setIsDBLoading(true);
        await API.graphql(graphqlOperation(updateEvent, variables));
        return await Promise.resolve({
          success: true,
          message: "イベント更新が完了しました。",
        });
      } catch (error) {
        return await Promise.reject({
          success: false,
          message: "イベント更新に失敗しました。（DB）",
          error,
        });
      } finally {
        setIsDBLoading(false);
      }
    },
    [setIsDBLoading]
  );

  return {
    getEventInfo,
    setEventValuesForTicket,
    createEventInFront,
    updateEventInFront,
    isDBLoading,
  };
};
