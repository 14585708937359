import { useState, useCallback, useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

export const useSearchBar = () => {
  const [values, setValues] = useState({
    prefecture: "",
    date: "",
    mainCategory: "",
    hashtag: "",
  });
  const onChangeValues = useCallback(
    (prop) => (event) => {
      setValues((prevValues) => {
        return { ...prevValues, [prop]: event.target.value };
      });
    },
    []
  );

  const [isModalOpen, setModalOpen] = useState(false);
  const handleModalOpen = useCallback(() => setModalOpen(true), []);
  const handleModalClose = useCallback(() => setModalOpen(false), []);

  const params = createSearchParams({
    prefecture: values.prefecture,
    date: values.date,
    mainCategory: values.mainCategory,
    hashtag: values.hashtag,
  }).toString();

  const navigate = useNavigate();
  const toSearchResult = useCallback(
    (event) => {
      event.preventDefault();
      if (isModalOpen) handleModalClose();
      navigate(`/searchResult?${params}`);
    },
    [isModalOpen, handleModalClose, navigate, params]
  );

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const prefecture = params.get("prefecture") ? params.get("prefecture") : "";
    const date = params.get("date") ? params.get("date") : "";
    const mainCategory = params.get("mainCategory") ? params.get("mainCategory") : "";
    const hashtag = params.get("hashtag") ? params.get("hashtag") : "";
    setValues({ prefecture, date, mainCategory, hashtag });
  }, []);

  return {
    values,
    onChangeValues,
    isModalOpen,
    handleModalOpen,
    handleModalClose,
    toSearchResult,
  };
};
