import React from "react";

// MUI
import { Grid, Input, Modal } from "@mui/material/";
import MessageSettingIcon from "@mui/icons-material/Settings";
import MessageAddMediaIcon from "@mui/icons-material/AddPhotoAlternate";
import MessageSendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// その他
import MessageSettingPC from "./MessageSettingPC";

// スタイル
const styles = {
  bodySpacerStyle: css`
    display: "flex",
    height: "780px",
    width: "1473px",
    border: "solid #808080",
    borderWidth: "5px 5px 5px 5px",
  `,
  messageExchangeGrid: css`
    display: "table-cell",
    width: "1000px",
    height: "716.5px",
    border: "solid #808080",
    borderWidth: "0px 0px 0px 0px",
  `,
  messageExchangeHeaderGrid: css`
    display: "table-cell",
    width: "1000px",
    height: "30px",
  `,
  selectedUserProfPhotoStyle: css`
    display: "flex",
    width: "45px",
    height: "45px",
    borderRadius: "45px",
  `,
  messageExchangeHeaderUserGrid: css`
    display: "table-cell",
    width: "945px",
    height: "45px",
    textAlign: "-webkit-center",
    verticalAlign: "middle",
  `,
  messageSettingIconGrid: css`
    display: "table-cell",
    width: "60px",
    height: "45px",
    verticalAlign: "middle",
    textAlign: "center",
  `,
  messageExchangeContentGrid: css`
    width: "1000px",
    height: "648.5px",
    border: "solid #808080",
    borderWidth: "5px 0px 0px 0px",
    overflowY: "scroll",
  `,
  messageExchangeSendGrid: css`
    display: "table-cell",
    width: "1000px",
    height: "73px",
    border: "solid #808080",
    borderWidth: "5px 0px 0px 0px",
    `,
  messageExchangeLeftGrid: css`
    display: "table-cell",
    width: "100px",
    height: "50px",
    verticalAlign: "middle",
    `,
  messageExchangeCenterGrid: css`
    display: "table-cell",
    width: "800px",
    height: "50px",
    verticalAlign: "middle",
    `,
  messageExchangeRightGrid: css`
    display: "table-cell",
    width: "100px",
    height: "50px",
    verticalAlign: "middle",
    `,
  messageSettingOverlayStyle: css`
    position: "fixed",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    `,
  messageSettingContentStyle: css`
    height: "70%",
    width: "50%",
    padding: "1em",
    backgroundColor: "#fff",
    overflowY: "scroll",
    `,
};

// const bodySpacerStyle = {
//   display: "flex",
//   height: "780px",
//   width: "1473px",
//   border: "solid #808080",
//   borderWidth: "5px 5px 5px 5px",
// };

// const MessageExchangeGrid = styled(Grid)({
//   display: "table-cell",
//   width: "1000px",
//   height: "716.5px",
//   border: "solid #808080",
//   borderWidth: "0px 0px 0px 0px",
// });

// const MessageExchangeHeaderGrid = styled(Grid)({
//   display: "table-cell",
//   width: "1000px",
//   height: "30px",
// });

// const SelectedUserProfPhotoStyle = {
//   display: "flex",
//   width: "45px",
//   height: "45px",
//   borderRadius: "45px",
// };

// const MessageExchangeHeaderUserGrid = styled(Grid)({
//   display: "table-cell",
//   width: "945px",
//   height: "45px",
//   textAlign: "-webkit-center",
//   verticalAlign: "middle",
// });

// const MessageSettingIconGrid = styled(Grid)({
//   display: "table-cell",
//   width: "60px",
//   height: "45px",
//   verticalAlign: "middle",
//   textAlign: "center",
// });

// const MessageExchangeContentGrid = styled(Grid)({
//   width: "1000px",
//   height: "648.5px",
//   border: "solid #808080",
//   borderWidth: "5px 0px 0px 0px",
//   overflowY: "scroll",
// });

// const MessageExchangeSendGrid = styled(Grid)({
//   display: "table-cell",
//   width: "1000px",
//   height: "73px",
//   border: "solid #808080",
//   borderWidth: "5px 0px 0px 0px",
// });

// const MessageExchangeLeftGrid = styled(Grid)({
//   display: "table-cell",
//   width: "100px",
//   height: "50px",
//   verticalAlign: "middle",
// });

// const MessageExchangeCenterGrid = styled(Grid)({
//   display: "table-cell",
//   width: "800px",
//   height: "50px",
//   verticalAlign: "middle",
// });

// const MessageExchangeRightGrid = styled(Grid)({
//   display: "table-cell",
//   width: "100px",
//   height: "50px",
//   verticalAlign: "middle",
// });

// const messageSettingOverlayStyle = {
//   position: "fixed",
//   width: "100%",
//   height: "100%",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// };

// const messageSettingContentStyle = {
//   height: "70%",
//   width: "50%",
//   padding: "1em",
//   backgroundColor: "#fff",
//   overflowY: "scroll",
// };

const SelectMessageUserPC = (props) => {
  // モーダル：メッセージ設定
  const [isModalOpenMessageSetting, setIsModalOpenMessageSetting] = React.useState(false);
  const handleModalOpenMessageSetting = () => setIsModalOpenMessageSetting(true);
  const handleModalCloseMessageSetting = () => setIsModalOpenMessageSetting(false);
  function messageSettingProc() {
    handleModalOpenMessageSetting();
  }

  // メディア追加イベント
  function mediaAddOnProc() {
    document.getElementById("addMediaIcon").style.padding = "8px";
    document.getElementById("addMediaIcon").style.borderRadius = "50%";
    document.getElementById("addMediaIcon").style.backgroundColor = "#f5f5f5";
  }
  function mediaAddOverProc() {
    document.getElementById("addMediaIcon").style.padding = "";
    document.getElementById("addMediaIcon").style.borderRadius = "";
    document.getElementById("addMediaIcon").style.backgroundColor = "";
  }

  // TODO メッセージ送信イベント
  function sendMessageProc() {
    alert("メッセージ送信イベントです。");
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      {/* モーダル：メッセージ設定 */}
      <Modal open={isModalOpenMessageSetting} onClose={handleModalOpenMessageSetting}>
        <Grid>
          <div style={styles.messageSettingOverlayStyle}>
            <div style={styles.messageSettingContentStyle}>
              <IconButton onClick={handleModalCloseMessageSetting}>
                <CloseIcon />
              </IconButton>
              <div>
                <MessageSettingPC isExistIndividual={true} />
              </div>
            </div>
          </div>
        </Grid>
      </Modal>

      <styles.messageExchangeGrid>
        <styles.messageExchangeHeaderGrid>
          <styles.messageExchangeHeaderUserGrid>
            <table>
              <tr>
                <td rowSpan={2}>
                  <img
                    src={props.messageUserDatas[1].profPhoto}
                    style={styles.selectedUserProfPhotoStyle}
                    alt="プロフ写真"
                  />
                </td>
                <td style={{ textAlign: "center" }}>
                  <div style={{ fontWeight: "bold", height: "5px" }}>{props.messageUserDatas[1].nickName}</div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>@{props.messageUserDatas[1].messageUserAccountId}</td>
              </tr>
            </table>
          </styles.messageExchangeHeaderUserGrid>

          <styles.messageSettingIconGrid>
            <label title="メッセージ設定">
              <IconButton onClick={messageSettingProc}>
                <MessageSettingIcon sx={{ fontSize: "2rem" }} style={{ color: "#000000" }} />
              </IconButton>
            </label>
          </styles.messageSettingIconGrid>
        </styles.messageExchangeHeaderGrid>

        <styles.messageExchangeContentGrid></styles.messageExchangeContentGrid>

        <styles.messageExchangeSendGrid>
          <styles.messageExchangeLeftGrid>
            <label for="mediaPhoto" title="メディア">
              <MessageAddMediaIcon
                id="addMediaIcon"
                onMouseOver={mediaAddOnProc}
                onMouseLeave={mediaAddOverProc}
                sx={{ fontSize: "2rem" }}
                style={{ cursor: "pointer", color: "#000000" }}
              />
              <Input
                type="file"
                id="mediaPhoto"
                name="filePhoto"
                style={{ display: "none" }}
                accept="image/*, audio/*, video/*"
                value={""}
              />
            </label>
          </styles.messageExchangeLeftGrid>

          <styles.messageExchangeCenterGrid>
            <TextField
              maxRows="1"
              required
              id="inputMessage"
              margin="dense"
              label="新しいメッセージを入力"
              multiline
              fullWidth
            />
          </styles.messageExchangeCenterGrid>

          <styles.messageExchangeRightGrid>
            <label title="送信">
              <IconButton id="sendMessageBtn" onClick={sendMessageProc}>
                <MessageSendIcon sx={{ fontSize: "2rem" }} style={{ color: "#000000" }} />
              </IconButton>
            </label>
          </styles.messageExchangeRightGrid>
        </styles.messageExchangeSendGrid>
      </styles.messageExchangeGrid>
    </div>
  );
};
export default SelectMessageUserPC;
