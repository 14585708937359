import React, { useEffect, useState, useCallback } from "react";

// 外部パッケージ
import { getDebugger } from "components/Debugger";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

// MUI
import { Avatar, Rating } from "@mui/material";

// AWS
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";

// その他
import { getUserForPublic } from "graphql/customQueries";
import { UserStatus } from "models";
import { useS3 } from "hooks/useS3Hooks";
import unsubscribedIcon from "../img/unsubscribed_user_icon.png";
import { User } from "models/API";

const styles = {
  root: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,

  textLink: css`
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  `,

  iconLink: css`
    :hover {
      cursor: pointer;
    }
  `,
};

var debug = getDebugger(false);

type UserIconPropsType = {
  userID: string;
  size: number;
  options?: UserIconPropsOptionsType;
};

type UserIconPropsOptionsType = {
  labeled?: boolean;
  labeledSize?: number;
  rated?: boolean;
  enableLink?: boolean;
};

/**
 * ユーザアイコンの生成
 * ユーザIDからユーザアイコン画像のキーとニックネームを取得する
 * S3から画像キーを使って画像を取得し、アイコンに設定する
 * プロフィール画像が登録されていない場合、デフォルトアイコンを作成する
 */
export const UserIcon: React.FC<UserIconPropsType> = (props) => {
  const { userID, size } = props;
  const labeled = props.options?.labeled || false;
  const labeledSize = props.options?.labeledSize; // labeledSizeはundefinedでデフォルトのフォントサイズとなる
  const rated = props.options?.rated || false;
  const enableLink = props.options?.enableLink || false;

  const navigate = useNavigate();
  const { getFileFromS3 } = useS3();

  // ユーザのプロフィール情報
  const [userInfo, setUserInfo] = useState({
    iconURL: "",
    nickname: "",
    // TODO Rating機能実装後、ユーザテーブルに定義されているRatingを設定する
    rating: 0,
    status: "",
  });

  const toProfile = useCallback(() => {
    return enableLink ? navigate(`/profile/${userID}`) : null;
  }, [enableLink, navigate, userID]);

  /**
   * ユーザIDからユーザアイコン画像のS3のキーとニックネームを取得する
   * @param {string} userID - ユーザID
   */
  const getUserInfoFromUser = useCallback(async (userID: string) => {
    try {
      const result = (await API.graphql(graphqlOperation(getUserForPublic, { id: userID }))) as GraphQLResult<{
        getUser: User;
      }>;
      debug(result);
      if (!result || !result.data) throw new Error("ユーザアイコン画像の取得に失敗しました。(Key)");
      return Promise.resolve({
        key: result.data.getUser.userIconKey,
        nickname: result.data.getUser.nickname,
        rating: result.data.getUser.rating,
        status: result.data.getUser.status,
      });
    } catch (error) {
      debug(error);
      return Promise.reject("ユーザアイコン画像の取得に失敗しました。(Key)");
    }
  }, []);

  /**
   * アイテムのキーを元にS3からユーザーアイコンをダウンロードする
   * getUserInfoFromUserから受け取ったニックネームもそのまま次のfunctionへ渡す
   * （setValuesを複数回実行すると、回数分レンダリングが実行されるため）
   * @param {Object} userInfo - ユーザテーブルから取得したユーザ情報
   */
  const getUserIconURLFromS3 = useCallback(
    async (userInfo: {
      key: User["userIconKey"];
      nickname: User["nickname"];
      rating: User["rating"];
      status: User["status"];
    }) => {
      try {
        let iconURL: string = "";
        let nickname: string = "";
        let rating: number = 0;

        if (userInfo.status === UserStatus.DELETED) {
          iconURL = unsubscribedIcon;
          nickname = "退会済みユーザー";
        } else {
          if (userInfo.key) {
            const result = await getFileFromS3(userInfo.key);
            iconURL = result;
          }
          nickname = userInfo.nickname;
          rating = userInfo.rating;
        }

        return Promise.resolve({
          iconURL: iconURL,
          nickname: nickname,
          rating: rating,
          status: userInfo.status,
        });
      } catch (error) {
        debug(error);
        return Promise.reject("ユーザアイコン画像の取得に失敗しました。(S3)");
      }
    },
    [getFileFromS3]
  );

  // アイコン画像読み込み
  useEffect(() => {
    debug("useEffect is loading.");
    debug("userID: ", userID);
    let ignore = false;

    if (ignore) return;
    if (!userID) return;
    getUserInfoFromUser(userID)
      .then((result) => getUserIconURLFromS3(result))
      .then((result) => setUserInfo({ ...result }))
      .catch((error) => {
        debug(error);
        return;
      });

    return () => {
      ignore = true;
    };
  }, [getUserIconURLFromS3, getUserInfoFromUser, userID]);

  // ユーザーネームから背景色を生成
  const nicknameToBGColor = useCallback(() => {
    let hash = 0;
    let i;
    for (i = 0; i < userInfo.nickname.length; i += 1) {
      hash = userInfo.nickname.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }, [userInfo.nickname]);

  // ユーザーネームからアイコンを生成
  const nicknameToIcon = useCallback(() => {
    if (userInfo.status !== UserStatus.DELETED) {
      return {
        sx: {
          bgcolor: nicknameToBGColor(),
          fontSize: size * 0.35,
        },
        children: `${userInfo.nickname.charAt(0)}${userInfo.nickname.charAt(1)}`,
      };
    }
  }, [userInfo.status, userInfo.nickname, nicknameToBGColor, size]);

  return (
    <div css={styles.root} onClick={toProfile}>
      <Avatar
        {...nicknameToIcon()}
        style={{ width: size, height: size }}
        css={enableLink ? styles.iconLink : null}
        src={userInfo.iconURL}
        alt={"profile"}
      />
      <div css={enableLink ? styles.textLink : null}>
        {labeled && <span style={{ marginLeft: 5, fontSize: labeledSize }}>{userInfo.nickname}</span>}
        {rated && (
          <span>
            {/* // HACK もう少しStarを小さくしたい。cssのtransformを使うと良いかも */}
            {/* // HACK 中央揃えにする */}
            <Rating value={userInfo.rating} precision={0.5} readOnly size="small" style={{ marginLeft: 5 }} />
            {userInfo.rating}
          </span>
        )}
      </div>
    </div>
  );
};
