import React from "react";

// MUI
import MemberManageIcon from "@mui/icons-material/SupervisedUserCircle";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// その他
import MessageUserSearchPC from "./MessageUserSearchPC";

// スタイル
const styles = {
  memMngDispNameBase: css`
    textAlign: "center",
    borderBottom: "solid #808080",
    borderWidth: "1px",
    `,
  memMngDispNameStr: css`
    fontSize: "25px",
    fontWeight: "bold",
    display: "inline-block",
    `,
  memMngFuncTitle: css`
    textAlign: "center",
    border: "solid #808080",
    borderWidth: "1px 0px 1px 0px",
    `,
  memMngList: css`
    color: "black",
    textTransform: "none",
    padding: "10px 10px 10px 10px",
    `,
  memMngListProfPhoto: css`
    display: "flex",
    width: "55px",
    height: "55px",
    borderRadius: "55px",
    `,
};

// const MemMngDispNameBase = {
//   textAlign: "center",
//   borderBottom: "solid #808080",
//   borderWidth: "1px",
// };

// const MemMngDispNameStr = {
//   fontSize: "25px",
//   fontWeight: "bold",
//   display: "inline-block",
// };

// const MemMngFuncTitle = {
//   textAlign: "center",
//   border: "solid #808080",
//   borderWidth: "1px 0px 1px 0px",
// };

// const MemMngList = {
//   color: "black",
//   textTransform: "none",
//   padding: "10px 10px 10px 10px",
// };

// const MemMngListProfPhoto = {
//   display: "flex",
//   width: "55px",
//   height: "55px",
//   borderRadius: "55px",
// };

const MemberManagePC = (props) => {
  return (
    <div>
      <div style={styles.memMngDispNameBase}>
        <div style={styles.memMngDispNameStr}>メンバー管理</div>
        <MemberManageIcon sx={{ fontSize: "2rem" }} style={{ color: "#000000" }} />
      </div>
      <br />
      <TextField margin="dense" label="グループ名" fullWidth />
      <br /> <br />
      <div style={styles.memMngFuncTitle}>
        <h2>メンバー一覧</h2>
      </div>
      {props.messageUserDatas.map((messageUserData) => (
        <div style={{ padding: "15px 0px 0px 15px" }}>
          <Table style={styles.memMngList}>
            <tr height={30}>
              <td rowSpan={2} width={50}>
                <img src={messageUserData.profPhoto} style={styles.memMngListProfPhoto} alt="プロフ写真" />
              </td>
              <td align="left">{messageUserData.nickName}</td>
            </tr>
            <tr>
              <td align="left">@{messageUserData.messageUserAccountId}</td>
            </tr>
          </Table>
        </div>
      ))}
      <br />
      <div style={{ textAlign: "center" }}>
        <Button
          type="button"
          onClick={{ MessageUserSearchPC }}
          variant="contained"
          sx={{ mt: 1, mr: 1 }}
          style={{ backgroundColor: "#DB3531" }}
        >
          メンバーを追加
        </Button>
      </div>
      <p style={{ textAlign: "center" }}>
        <h2>＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊</h2>
      </p>
      <div style={{ textAlign: "center" }}>
        <Button type="submit" variant="contained" sx={{ mt: 1, mr: 1 }} style={{ backgroundColor: "#DB3531" }}>
          保存
        </Button>
      </div>
      <br />
    </div>
  );
};
export default MemberManagePC;
