import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const styles = {
  eventHastagSpan: css`
    padding-inline: 0.75em;
    border-radius: 1em;
    box-sizing: border-box;
  `,

  bgGray: css`
    background: #f1f1f1;
  `,

  bgWhite: css`
    background: #ffffff;
  `,

  sizeSmall: css`
    font-size: 12px;
  `,

  sizeMedium: css`
    font-size: 14px;
  `,

  sizeLarge: css`
    font-size: 16px;
  `,

  enabledLink: css`
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  `,
};

type HashtagPropsType = {
  hashtagList: (string | undefined)[];
  options?: HashtagPropsOptionsType;
};

type HashtagPropsOptionsType = {
  background?: "white" | "gray";
  size?: "small" | "medium" | "large";
  enabledLink?: boolean;
};

const Hashtag: React.FC<HashtagPropsType> = React.memo((props) => {
  const { hashtagList } = props;
  const background = props.options?.background || "gray";
  const size = props.options?.size || "medium";
  const enabledLink = props.options?.enabledLink || false;

  const navigate = useNavigate();
  const toSearchResult = (hashtag: string) => {
    if (enabledLink) {
      navigate(`/searchResult?venue=&date=&mainCategory=&hashtag=${hashtag}`);
    }
  };

  const getBackground = (background: HashtagPropsOptionsType["background"]) =>
    background === "white" ? styles.bgWhite : styles.bgGray;

  const getSize = (size: HashtagPropsOptionsType["size"]) => {
    switch (size) {
      case "small":
        return styles.sizeSmall;
      case "medium":
        return styles.sizeMedium;
      case "large":
        return styles.sizeLarge;
    }
  };

  return (
    <>
      {hashtagList
        .filter((hashtag) => hashtag)
        .map((hashtag, index) => (
          <span
            key={`${hashtag}${index}`}
            css={[styles.eventHastagSpan, getBackground(background), getSize(size)]}
            onClick={() => {
              toSearchResult(hashtag!);
            }}
          >
            #{hashtag}
          </span>
        ))}
    </>
  );
});

export default Hashtag;
