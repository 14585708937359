import React, { useState } from "react";

// 外部パッケージ
import { useNavigate, Link, useLocation } from "react-router-dom";
// import { FacebookLoginButton, GoogleLoginButton, TwitterLoginButton } from "react-social-login-buttons";

// MUI
import {
  ThemeProvider,
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// その他
import theme from "components/MuiTheme";
import { useAuth } from "components/auth/AuthCommon";
import LogoImg from "components/img/Eventer_logo_red.png";

// スタイル
const root = {
  backgroundColor: theme.palette.grey[200],
  paddingTop: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: 14,
};

const rootLogIn = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: 20,
  padding: " 0 30px 30px 30px",
  background: "white",
  borderRadius: 5,
};

const upper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const upperLogo = { width: 150, height: 150 };

const loginTitle = {
  width: 264,
  display: "flex",
  justifyContent: "center",
  paddingTop: 20,
  paddingBottom: 20,
};

const loginButton = {
  backgroundColor: "#DB3531",
  marginTop: 20,
};

const forgotPassword = {
  textAlign: "end",
  marginTop: 20,
  paddingBottom: 20,
};

// const snsGoogleLoginButton = {
//   width: 274,
//   height: 36,
//   marginTop: 20,
//   marginBottom: 20,
// };

// const snsLoginButton = {
//   width: 274,
//   height: 36,
//   marginBottom: 20,
// };

// const snsLoginTitle = { fontSize: 14 };

const newLoginTitle = {
  paddingTop: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderTop: "1px solid #d0d0d0",
};

const newLoginButton = {
  backgroundColor: "#0090a8",
  marginTop: 20,
};

// HACK Warning 出ているけど原因未調査
// Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
// at SignUp (http://localhost:3000/static/js/bundle.js:24531:72)

// ここからメインのSignInコンポーネント
function SignIn() {
  const auth = useAuth();
  const navigate = useNavigate();
  const isShowLoginError = useLocation().state?.errorFlg || false;
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const onSignInPressed = async (event) => {
    if (auth.isLoading) return;
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const result = await auth.signIn(data.get("email"), data.get("password"));
    // HACK エラー発生理由を表示させたい。auth.signUpのresultで保持している。
    // HACK stateの更新が非同期のため、divのstyleを切り替えることができない。
    // HACK 失敗時の値を保持する
    if (result.success) {
      navigate("/");
    } else {
      alert("ログインに失敗しました。\n入力内容をご確認ください。");
    }
  };

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  //入力値チェック用 0~9,a~z,A~Zいずれかであるかをチェックする
  const regex = new RegExp(/^[0-9a-zA-Z]*$/);

  const handleCheckPassword = (e) => {
    e.preventDefault();
    if (regex.test(e.target.value)) {
      setPassword(e.target.value);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box style={root}>
        <Box component="form" onSubmit={onSignInPressed} noValidate style={rootLogIn}>
          <div style={upper}>
            <img src={LogoImg} style={upperLogo} alt="logo" />
            <Typography component="h1" variant="h1" style={loginTitle}>
              ログイン
            </Typography>
          </div>
          <div
            style={isShowLoginError ? { display: "block", color: "#DB3531", fontFamily: "bold" } : { display: "none" }}
          >
            ログインに失敗しました
          </div>
          <TextField
            color="secondary"
            margin="normal"
            required
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <FormControl sx={{ paddingTop: 1.65, width: "30ch" }} variant="outlined">
            <InputLabel sx={{ paddingTop: 1.65 }} color="secondary" htmlFor="outlined-adornment-password">
              パスワード
            </InputLabel>
            <OutlinedInput
              value={password}
              onChange={handleCheckPassword}
              aria-label="Password"
              className="form-control"
              color="secondary"
              required
              fullWidth
              name="password"
              label="パスワード"
              id="password"
              inputProps={{ minLength: 8 }}
              autoComplete="current-password"
              type={isShowPassword ? "text" : "password"}
              aria-required="true"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                    {isShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button style={loginButton} type="submit" fullWidth variant="contained">
            {auth.isLoading ? "ログイン中..." : "ログイン"}
          </Button>
          <div style={forgotPassword}>
            <Link to="/forgotPassword">パスワードをお忘れの方</Link>
          </div>
          {/* <GoogleLoginButton
            style={snsGoogleLoginButton}
            onClick={() => {
              console.log("Googleでログイン");
            }}
            align="center"
            iconSize={"20"}
          >
            <div style={snsLoginTitle}>Googleでログイン</div>
          </GoogleLoginButton>
          <TwitterLoginButton
            style={snsLoginButton}
            onClick={() => {
              console.log("Twitterでログイン");
            }}
            align="center"
            iconSize={"20"}
          >
            <div style={snsLoginTitle}>Twitterでログイン</div>
          </TwitterLoginButton>
          <FacebookLoginButton
            style={snsLoginButton}
            onClick={() => {
              console.log("Facebookでログイン");
            }}
            align="center"
            iconSize={"20"}
          >
            <div style={snsLoginTitle}>Facebookでログイン</div>
          </FacebookLoginButton> */}
          <Typography component="h1" variant="h1" style={newLoginTitle}>
            新規ゲスト登録はこちら
          </Typography>
          <Button onClick={() => navigate("/signUp")} style={newLoginButton} fullWidth variant="contained">
            新規登録
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default SignIn;
