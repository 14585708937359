import React from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const divStyle = {
  top: css`
    text-align: left;
    font-size: 1rem;
    padding: 60px;
  `,
};
const spanStyle = {
  span: css`
    color: #db3531;
  `,
};
const bStyle = {
  title: css`
    text-align: left;
    font-size: 1.3rem;
    margin-top: 50px;
  `,
};

const Ebizrule = () => {
  return (
    <div css={divStyle.top}>
      <h1>
        <span css={spanStyle.span}>Eventer</span>特定商取引法に関する表示
      </h1>

      <div>
        株式会社
        Eventer（以下「弊社」といいます。）は、イベント企画者と参加者をマッチングするプラットフォームサービス「Eventer」を提供するにあたり、以下には、参加者およびプランナーについて共通に適用される事項を定めており、個別の事項は各詳細ページをご確認ください。
      </div>

      <div>
        <b css={bStyle.title}>販売事業者の名称・所在地</b>
      </div>

      <div>株式会社 Eventer 〒111-0031 東京都台東区千束2-34-6 宮路ビル201</div>

      <div>
        <b css={bStyle.title}>代表者名</b>
      </div>

      <div>山本浩人</div>

      <div>
        <b css={bStyle.title}>サービスサイト</b>
      </div>

      <div>
        <a href="https://eventer.work/">https://eventer.work</a>
      </div>

      <div>
        <b css={bStyle.title}>お問い合わせ先</b>
      </div>

      <div>営業時間：AM10:00～PM17:00（土日、祝祭日を除く） Eventer20220111@gmail</div>

      <div>
        <b css={bStyle.title}>商品代金</b>
      </div>

      <div>チケット毎に税込み価格で表示しております。</div>

      <div>
        <b css={bStyle.title}>お支払い方法</b>
      </div>

      <div>Paypay払い</div>

      <div>
        <b css={bStyle.title}>お支払い期限</b>
      </div>

      <div>
        請求先情報の入力画面にて、クレジットカード払いにおける必要情報を入力し、
        購入確定時に与信処理および決済を行います。
      </div>

      <div css={bStyle.title}>
        <b css={bStyle.title}>販売商品・提供サービス</b>
      </div>

      <div>・イベントへの参加権利（チケット）</div>

      <div>
        <b css={bStyle.title}>販売者・サービス提供者</b>
      </div>

      <div>【イベントへの参加権利（チケット）】 イベント主催者</div>

      <div>
        <b css={bStyle.title}>商品の引渡しまたはサービス提供の時期</b>
      </div>

      <div>
        【イベントへの参加権利（チケット）】 主催者の方針によって、受付票の発行や通知が行われる場合があります。
        これらの発行・通知は任意であり、本サービスが保証するものではありません。
        本サービスからは、申込完了メールが参加者の登録したメールアドレス宛てに送られます。
      </div>

      <div>
        <b css={bStyle.title}>商品の引渡しまたはサービス提供の時期</b>
      </div>

      <div>
        【イベントへの参加権利（チケット）】 主催者の方針によって、受付票の発行や通知が行われる場合があります。
        これらの発行・通知は任意であり、本サービスが保証するものではありません。
        本サービスからは、申込完了メールが参加者の登録したメールアドレス宛てに送られます。
      </div>

      <div>
        <b css={bStyle.title}>返品・キャンセルに関する特約</b>
      </div>

      <div>
        【イベントへの参加権利（チケット）】
        主催者もしくは参加者の都合により、お支払い済みの参加費用の払い戻しをされたい場合には、
        主催者と参加者の間での返金処理をお願いいたします。 本サービスでは、主催者から正常に販売された商品あるいは
        提供されたサービスについて、払い戻し等のご相談に応じることは出来ません。
      </div>
    </div>
  );
};

export default Ebizrule;
