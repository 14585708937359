import React from "react";

// 外部パッケージ
import { useNavigate } from "react-router-dom";

// MUI
import { ThemeProvider, Button, TextField, Box, Typography } from "@mui/material";

// その他
import LogoImg from "components/img/Eventer_logo_red.png";
import theme from "components/MuiTheme";
import { useAuth } from "components/auth/AuthCommon";

// スタイル
const root = {
  backgroundColor: theme.palette.grey[200],
  paddingTop: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: 14,
};

const rootLogIn = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: 20,
  padding: " 0 30px 30px 30px",
  background: "white",
  borderRadius: 5,
  width: 284,
};

const upper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const upperLogo = { width: 150, height: 150 };

const title = {
  width: 264,
  display: "flex",
  justifyContent: "center",
  paddingTop: 20,
  paddingBottom: 20,
};

const description = {
  textAlign: "left",
};

const loginButton = {
  backgroundColor: "#DB3531",
  marginTop: 20,
};

const buttonStyle = {
  marginTop: 15,
  height: 40,
};

function ForgotPassword() {
  const [loading, setLoading] = React.useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const onForgotPasswordPressed = async (event) => {
    if (auth.isLoading) {
      return;
    }
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get("email");
    if (username === "") {
      alert("メールアドレスが入力されていません");
      setLoading(false);
      return;
    }
    const result = await auth.forgotPassword(username);

    if (result.success) {
      navigate("/forgotPasswordSubmit", { state: { username: username } });
    } else {
      alert(result.message);
    }
    setLoading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box style={root}>
        <Box component="form" onSubmit={onForgotPasswordPressed} noValidate style={rootLogIn}>
          <div style={upper}>
            <img src={LogoImg} style={upperLogo} alt="logo" />
            <Typography component="h1" variant="h1" style={title}>
              認証コードの送信
            </Typography>
            <div style={description}>
              登録されているメールアドレスへ認証コード
              <br />
              を送信します。
            </div>
          </div>
          <TextField
            color="secondary"
            margin="normal"
            required
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button style={loginButton} type="submit" fullWidth variant="contained">
            {loading ? "送信中..." : "送信"}
          </Button>

          <Button style={buttonStyle} fullWidth onClick={() => navigate("/signIn")}>
            戻る
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ForgotPassword;
