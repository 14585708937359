import React from "react";

// 外部パッケージ
import useMedia from "use-media";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// その他
import theme from "components/MuiTheme";
import SpaceThumbnail from "components/FindSpace/SpaceThumbnail";

const spaceRecomendationPcDivStyle = css`
  width: 1020px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3rem;
  padding-right: 10px;
  padding-left: 10px;
`;

const spaceRecomendationPcH1Style = css`
  font-size: 20px;
  width: 100%;
  text-align: left;
`;

const childrenPcDivStyle = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const spaceRecomendationSpDivStyle = css`
  width: 98%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3rem;
`;

const spaceRecomendationSpH1Style = css`
  font-size: 20px;
  width: 100%;
  text-align: left;
  margin-left: 0.5rem;
`;

const childrenSpDivStyle = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
  overflow-x: scroll;
`;

// ベータ版用コード
const spaceRecomendationThumbnails = [
  {
    spaceId: "b2d7d65f-d088-43f1-86aa-0c4416187786",
  },
  {
    spaceId: "b2d7d65f-d088-43f1-86aa-0c4416187786",
  },
  {
    spaceId: "b2d7d65f-d088-43f1-86aa-0c4416187786",
  },
];

const SpaceRecomendation = (props) => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  return (
    <div css={isWide ? spaceRecomendationSpDivStyle : spaceRecomendationPcDivStyle}>
      <h1 css={isWide ? spaceRecomendationSpH1Style : spaceRecomendationPcH1Style}>おすすめのスペース</h1>
      <div css={isWide ? childrenSpDivStyle : childrenPcDivStyle}>
        {/* TODO: イベントidを引数にして各イベントのサムネイルを作成させる */}
        {spaceRecomendationThumbnails.map((spaceThumbnail) => (
          <SpaceThumbnail key={SpaceRecomendation.name + spaceThumbnail.spaceId} spaceId={spaceThumbnail.spaceId} />
        ))}
      </div>
    </div>
  );
};

export default SpaceRecomendation;
