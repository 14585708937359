import { css } from "@emotion/react";

export const styles = {
  homePcDivStyle: css`
    width: 1020px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 1.5rem;
    padding-right: 10px;
    padding-left: 10px;
  `,

  homePcH1Style: css`
    font-size: 20px;
    width: 100%;
    text-align: left;
  `,

  childrenPcDivStyle: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
  `,

  homeSpDivStyle: css`
    width: 98%;
    margin-right: auto;
    margin-left: auto;
  `,

  homeSpH1Style: css`
    font-size: 20px;
    width: 100%;
    text-align: left;
    margin-left: 0.5rem;
  `,

  childrenSpDivStyle: css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    overflow-x: scroll;
  `,
};
