import React, { useEffect, useContext } from "react";

// 外部パッケージ
import { useForm, Controller } from "react-hook-form";
import useMedia from "use-media";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// MUI
import {
  TextField,
  Button,
  Box,
  Switch,
  MenuItem,
  styled,
  Grid,
  OutlinedInput,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";

// その他
import theme from "components/MuiTheme";
import { getCurrentDate } from "components/common/Utils";
import { receptions } from "components/common/SelectValues";
import { UserInputData } from "components/AddSpace/AddSpace";
import { applicationConst } from "utils/constants";
import { getDebugger } from "components/Debugger";

var debug = getDebugger(false);

const styles = {
  span: css`
    display: flex;
    font-size: 14px;
    font-weight: bold;
    opacity: 0.8;
  `,
  div: css`
    display: flex;
  `,
  h1: css`
    display: flex;
    font-size: 14px;
    font-weight: bold;
    opacity: 0.8;
    padding-top: 20px;
  `,
  p: css`
    font-size: 15px;
    padding-top: 15px;
  `,
  h2: css`
    padding-top: 110px;
    font-size: 48px;
    color: #fff;
    text-shadow: 0 0 0.2em rgba(0, 0, 0, 1);
    height: 50%;
  `,
};

// HACK 時間の非表示化
const MyRow = ({ dayIndex, label, control }) => {
  const checkName = dayIndex + "Check";
  debug(checkName);
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item xs={3}>
        <Controller
          name={checkName}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              control={<Android12Switch checked={value} onChange={onChange} />}
              label={label}
              labelPlacement="start"
            />
          )}
        />
      </Grid>
      {control && (
        <Grid item xs={9}>
          <Controller
            control={control}
            name={`${dayIndex}StartTime`}
            render={({ field }) => (
              <TextField
                {...field}
                id="startTime"
                type="time"
                size="small"
                color="secondary"
                variant="outlined"
                InputLabelProps={{ style: { fontSize: 12 } }}
                inputProps={{ style: { fontSize: 12 } }}
              />
            )}
          />
          〜
          <Controller
            control={control}
            name={`${dayIndex}EndTime`}
            render={({ field }) => (
              <TextField
                {...field}
                id="endTime"
                type="time"
                required
                size="small"
                color="secondary"
                variant="outlined"
                InputLabelProps={{ style: { fontSize: 12 } }}
                inputProps={{ style: { fontSize: 12 } }}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const Price = (props) => {
  const { currentState, setCurrentState } = useContext(UserInputData);

  const { control, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      feePerHour: "",
      startDate: getCurrentDate(),
      endDate: getCurrentDate(),
      sunCheck: false,
      monCheck: false,
      tueCheck: false,
      wedCheck: false,
      thuCheck: false,
      friCheck: false,
      satCheck: false,
      holCheck: false,
      sunStartTime: "00:00",
      sunEndTime: "00:00",
      monStartTime: "00:00",
      monEndTime: "00:00",
      tueStartTime: "00:00",
      tueEndTime: "00:00",
      wedStartTime: "00:00",
      wedEndTime: "00:00",
      thuStartTime: "00:00",
      thuEndTime: "00:00",
      friStartTime: "00:00",
      friEndTime: "00:00",
      satStartTime: "00:00",
      satEndTime: "00:00",
      holStartTime: "00:00",
      holEndTime: "00:00",
      reservationPeriod: "",
      reservationApproval: "",
    },
  });

  // 保持している値を表示する
  useEffect(() => {
    if (currentState.Price) {
      setValue("feePerHour", currentState.Price.feePerHour);
      setValue("startDate", currentState.Price.startDate);
      setValue("endDate", currentState.Price.endDate);
      setValue("sunStartTime", currentState.Price.sunStartTime);
      setValue("sunEndTime", currentState.Price.sunEndTime);
      setValue("monStartTime", currentState.Price.monStartTime);
      setValue("monEndTime", currentState.Price.monEndTime);
      setValue("tueStartTime", currentState.Price.tueStartTime);
      setValue("tueEndTime", currentState.Price.tueEndTime);
      setValue("wedStartTime", currentState.Price.wedStartTime);
      setValue("wedEndTime", currentState.Price.wedEndTime);
      setValue("thuStartTime", currentState.Price.thuStartTime);
      setValue("thuEndTime", currentState.Price.thuEndTime);
      setValue("friStartTime", currentState.Price.friStartTime);
      setValue("friEndTime", currentState.Price.friEndTime);
      setValue("satStartTime", currentState.Price.satStartTime);
      setValue("satEndTime", currentState.Price.satEndTime);
      setValue("holStartTime", currentState.Price.holStartTime);
      setValue("holEndTime", currentState.Price.holEndTime);
      setValue("reservationPeriod", currentState.Price.reservationPeriod);
      setValue("reservationApproval", currentState.Price.reservationApproval);
      setValue("sunCheck", currentState.Price.sunCheck);
      setValue("monCheck", currentState.Price.monCheck);
      setValue("tueCheck", currentState.Price.tueCheck);
      setValue("wedCheck", currentState.Price.wedCheck);
      setValue("thuCheck", currentState.Price.thuCheck);
      setValue("friCheck", currentState.Price.friCheck);
      setValue("satCheck", currentState.Price.satCheck);
      setValue("holCheck", currentState.Price.holCheck);
    }
  }, [currentState.Price, setValue]);

  const onSubmit = (action) => {
    if (action === "back") {
      props.handleBack();
    } else {
      props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Price: data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <span css={styles.span}>時間料金</span>
      <div css={styles.div}>
        <Controller
          control={control}
          name="feePerHour"
          render={({ field }) => (
            <OutlinedInput
              {...field}
              autoFocus
              id="inputFeePerHour"
              type="text"
              required
              // variant="standard"
              color="secondary"
              endAdornment={<InputAdornment position="end">￥／h</InputAdornment>}
              inputProps={{ style: { fontSize: 14 }, min: 0, step: 10, pattern: "^[0-9]+$" }}
              InputLabelProps={{ shrink: true, style: { fontSize: 14 } }}
              style={{ width: 150, marginTop: 20 }}
            />
          )}
        />
      </div>

      <h1 css={styles.h1}>掲載期間</h1>
      <div css={styles.div}>
        <Controller
          control={control}
          name="startDate"
          render={({ field }) => (
            <TextField
              {...field}
              id="inputStartDate"
              type="date"
              required
              color="secondary"
              variant="outlined"
              margin="normal"
              label="公開日"
              fullWidth
              InputLabelProps={{ style: { fontSize: 14 } }}
              inputProps={{ style: { fontSize: 14 } }}
              style={{ width: 150, marginRight: 10 }}
            />
          )}
        />
        <p css={styles.p}>〜 </p>
        <Controller
          control={control}
          name="endDate"
          render={({ field }) => (
            <TextField
              {...field}
              id="inputEndDate"
              type="date"
              required
              color="secondary"
              variant="outlined"
              margin="normal"
              label="公開終了日"
              fullWidth
              InputLabelProps={{ style: { fontSize: 14 } }}
              inputProps={{ style: { fontSize: 14 } }}
              style={{ width: 150 }}
            />
          )}
        />
      </div>

      <h1 css={styles.h1}>貸出可能な曜日・時間帯</h1>
      {applicationConst.days.map((day) => (
        <MyRow dayIndex={day.dayIndex} label={day.label} control={control} />
      ))}
      <span css={styles.span}>予約可能期間</span>
      <div css={styles.div}>
        <Controller
          control={control}
          name="reservationPeriod"
          render={({ field }) => (
            <OutlinedInput
              {...field}
              autoFocus
              id="inputReservationPeriod"
              type="text"
              required
              // variant="standard"
              color="secondary"
              endAdornment={<InputAdornment position="end">日前まで</InputAdornment>}
              inputProps={{ style: { fontSize: 14 }, min: 0, pattern: "^[0-9]+$" }}
              InputLabelProps={{ shrink: true, style: { fontSize: 14 } }}
              style={{ width: 150, marginTop: 10 }}
            />
          )}
        />
      </div>
      <h1 css={styles.h1}>予約承認方法</h1>
      <Controller
        control={control}
        name="reservationApproval"
        render={({ field }) => (
          <TextField
            {...field}
            select
            id="inputReservationApproval"
            required
            variant="outlined"
            color="secondary"
            margin="type"
            fullWidth
            type="number"
            InputLabelProps={{ style: { fontSize: 14 } }}
          >
            {receptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <div style={{ fontSize: 14, textAlign: "left" }}>{option.label}</div>
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <Box sx={{ mb: 2 }}>
        <div>
          <Button onClick={() => onSubmit("back")} sx={{ mt: 1, mr: 1 }}>
            戻る
          </Button>
          <Button type="submit" variant="contained" sx={{ mt: 1, mr: 1 }}>
            次へ
          </Button>
        </div>
      </Box>
    </form>
  );
};

export default Price;

const PriceTitle = () => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  return <div>{!isWide && <h1 css={styles.h2}>利用時間と料金の設定</h1>}</div>;
};

export { PriceTitle };
