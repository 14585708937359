import React from "react";

// 外部パッケージ
import { Link, useNavigate } from "react-router-dom";

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

//  MUI
import DehazeIcon from "@mui/icons-material/Dehaze";
import {
  AppBar,
  Drawer,
  Box,
  IconButton,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemButton,
  Typography,
} from "@mui/material";
// import AddBusinessIcon from "@mui/icons-material/AddBusiness";
// import EmailIcon from "@mui/icons-material/Email";
// import Badge from "@mui/material/Badge";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import CreateIcon from "@mui/icons-material/Create";
import SearchIcon from "@mui/icons-material/Search";

// その他
import theme from "components/MuiTheme";
import { UserIcon } from "components/common/UserIcon";
import LogoImg from "./img/eventer_logo_white_e.png";
import headerLinkList from "components/HeaderLinkList";
import { useAuth } from "components/auth/AuthCommon";
import { applicationConst } from "utils/constants";

const styles = {
  appBar: css`
    background-color: ${theme.palette.primary.main};

    & div {
      display: flex;
      align-items: center;
    }

    & div > a > img {
      width: 40px;
      height: 40px;
    }

    & div > button {
      margin: 0rem 0.5rem;
      margin-left: auto;
    }
  `,

  drawerBox: css`
    width: auto;
    background: ${theme.palette.primary.main};
    color: "#000000";
  `,

  drawerListItemButton: css`
    padding: 0px;
    margin: 0px;

    & > a {
      display: block;
      padding: 8px 16px;
    }
  `,

  drawerLink: css`
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 700;
    text-decoration: none;
    margin-right: -3px;
  `,
  userIcon: css`
    margin-left: 14px;
    margin-right: -30px;
  `,
};

const Header = (props) => {
  const navigate = useNavigate();
  const toProfile = () => {
    navigate("/profile");
  };
  // const toMessage = () => {
  //   navigate("/message");
  // };
  const toUsage = () => {
    navigate("/usage");
  };
  const toFindEvent = () => {
    navigate("/");
  };
  const toAddEvent = () => {
    navigate("/addEvent");
  };
  // const toFindSpace = () => {
  //   navigate("/findSpace");
  // };
  // const toAddSpace = () => {
  //   navigate("/addSpace");
  // };

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const [isOpen, setState] = React.useState(false); // drawerの表示状態
  const drawerAnchor = "top"; // topでdrawerが上から降りてくる
  const auth = useAuth();
  const condition = auth.isAuthenticated
    ? applicationConst.displayCondition.authenticated
    : applicationConst.displayCondition.unauthenticated;

  // drawer表示時のリンクのリスト
  // リンクのリストはHeaderLinkList.jsに定義
  const list = (anchor) => (
    <Box sx={styles.drawerBox} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <MenuItem onClick={toFindEvent}>
        <ListItemIcon sx={{ ml: 2, color: "white" }}>
          <SearchIcon />
        </ListItemIcon>
        <Typography color="white"> イベント検索</Typography>
      </MenuItem>
      <Divider />
      <MenuItem onClick={toAddEvent}>
        <ListItemIcon sx={{ ml: 2, color: "white" }}>
          <CreateIcon />
        </ListItemIcon>
        <Typography color="white"> イベント登録</Typography>
      </MenuItem>
      <Divider />
      {/* <MenuItem onClick={toFindSpace}>
        <ListItemIcon sx={{ ml: 2, color: "white" }}>
          <SearchIcon />
        </ListItemIcon>
        <Typography color="white"> スペース検索</Typography>
      </MenuItem>
      <Divider />
      <MenuItem onClick={toAddSpace} >
        <ListItemIcon sx={{ ml: 2, color: "white" }}>
          <AddBusinessIcon />
        </ListItemIcon>
        <Typography color="white">スペース登録</Typography>
      </MenuItem>
      <Divider />
      <MenuItem onClick={toMessage}>
        <ListItemIcon sx={{ ml: 2, color: "white" }}>
          <Badge badgeContent={2} color="error">
            <EmailIcon />
          </Badge>
        </ListItemIcon>
        <Typography color="white"> メッセージ</Typography>
      </MenuItem>
      <Divider /> */}
      <MenuItem onClick={toUsage}>
        <ListItemIcon sx={{ ml: 2, color: "white" }}>
          <AutoStoriesIcon />
        </ListItemIcon>
        <Typography color="white"> 利用ガイド</Typography>
      </MenuItem>
      <Divider />
      {headerLinkList
        .filter(
          (link) =>
            link.displayCondition === applicationConst.displayCondition.always || link.displayCondition === condition
        )
        .map((link) => (
          <MenuItem onClick={toProfile} key={link.key}>
            <ListItemIcon sx={{ ml: 2, color: "white" }}>
              <UserIcon userID={auth.userID} size={25} />{" "}
              <Typography
                color="white"
                style={{
                  paddingLeft: 15,
                }}
              >
                {link.value}
              </Typography>
            </ListItemIcon>
          </MenuItem>
        ))}
    </Box>
  );

  // drawerのon/off切り替え
  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState(open);
  };

  return (
    <div>
      <AppBar css={styles.appBar} position="static">
        <div>
          <Link to="/">
            <img src={LogoImg} alt="ロゴ"></img>
          </Link>
          <IconButton onClick={toggleDrawer(true)}>
            <DehazeIcon sx={{ color: "white" }} fontSize={"large"} />
          </IconButton>
          <Drawer anchor={drawerAnchor} open={isOpen} onClose={toggleDrawer(false)}>
            {list(drawerAnchor)}
          </Drawer>
        </div>
      </AppBar>
    </div>
  );
};

export default Header;
