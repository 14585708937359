import React, { useEffect, useCallback, memo } from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useMedia from "use-media";
import { useNavigate } from "react-router-dom";

// MUI
// import Rating from "@mui/material/Rating";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import PeopleIcon from "@mui/icons-material/People";

// AWS
import { API, graphqlOperation } from "aws-amplify";

// その他
import theme from "components/MuiTheme";
import { locale } from "components/common/SelectValues";
import { convertValueToLabel } from "components/common/Utils";
import { UserIcon } from "components/common/UserIcon";
import { useAuth } from "components/auth/AuthCommon";
import { getDebugger } from "components/Debugger";
import { getEvent } from "graphql/queries";
import { ThumbnailImage } from "components/common/ThumbnailImage";
import Hashtag from "components/common/parts/Hashtag/Hashtag";
import MainCategoryLabel from "components/common/parts/MainCategoryLabel/MainCategoryLabel";

var debug = getDebugger(false);

// ここからスタイル↓
const styles = {
  rootDiv: css`
    display: flex;
    border-radius: 5px;
    background-color: white;
    filter: drop-shadow(0px 0px 3px ${theme.palette.grey[200]});
  `,
  rootDivPC: css`
    width: 850px;
  `,
  rootDivSP: css`
    justify-content: space-between;
    min-width: 355px;
  `,
  eventThumbnail: css`
    position: relative;
  `,
  eventStatusLabel: css`
    position: absolute;
    top: 0;
    color: white;
  `,
  eventStatusLabelColorBlue: css`
    background: rgba(0, 144, 168, 0.7);
  `,
  eventStatusLabelColorRed: css`
    background: rgba(255, 0, 0, 0.7);
  `,
  eventStatusLabelColorYellow: css`
    background: rgba(255, 255, 0, 0.7);
  `,
  eventStatusLabelPC: css`
    width: 200px;
    font-weight: 700;
  `,
  eventStatusLabelSP: css`
    width: 95px;
    font-size: 0.5em;
    font-weight: 500;
  `,
  eventDetail: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  eventDetailPC: css`
    width: 631px;
    padding: 10px;
  `,
  eventDetailSP: css`
    padding-left: 5px;
    width: 90%;
  `,
  eventDetailRow1: css`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    cursor: pointer;
  `,
  eventDetailRow1PC: css`
    font-size: 17px;
  `,
  eventDetailRow1SP: css``,
  eventTitle: css`
    font-weight: bold;
    :hover {
      text-decoration: underline;
    }
  `,
  eventTitlePC: css``,
  eventTitleSP: css`
    font-size: 13px;
  `,

  overviewPC: css`
    text-align: left;
    font-size: 16px;
    height: 80px;
    overflow-y: auto;
  `,
  overviewSP: css`
    display: none;
  `,
  hashtags: css`
    display: flex;
    flex-wrap: wrap;
    gap: 2px 0.5em;
    width: -webkit-fill-available;
  `,

  eventDetailRow3: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    & > div:not(:first-of-type) {
      margin-left: 1em;
    }
  `,
  eventDetailRow3PC: css`
    & > div {
      font-size: 15px;
    }
  `,
  eventDetailRow3SP: css`
    & > div {
      font-size: 11px;
    }

    & .endDatetime {
      display: none;
    }
  `,
  eventDetailRow4: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    & > div:not(:first-of-type) {
      margin-left: 1em;
    }
  `,
  eventDetailRow4PC: css`
    & > div {
      font-size: 15px;
    }
  `,
  eventDetailRow4SP: css`
    & > div {
      font-size: 11px;
    }
  `,
};

// ここから
const ProfileThumbnail = memo((props) => {
  const auth = useAuth();
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  const navigate = useNavigate();

  // 職業、住所のプルダウン
  const [values, setValues] = React.useState({
    name: "",
    thumbnailImageKey: "",
    mainCategory: "",
    overview: "",
    status: "",
    startDateTime: "",
    endDateTime: "",
    postCode1: "",
    postCode2: "",
    prefecture: "",
    city: "",
    address: "",
    // EventEntries: "",
    hashtags: [],
    // Tickets:"",
    userID: "",
  });

  const toEvent = useCallback(() => {
    navigate(`/event/${props.eventID}`, { state: { currentState: values, userID: auth.userID } });
  }, [auth.userID, navigate, props.eventID, values]);

  // 初期処理
  useEffect(() => {
    const runAPI = async () => {
      debug("useEffect is loading.");
      debug("props.eventID", props.eventID);
      if (!props.eventID) return;
      const result = await API.graphql(graphqlOperation(getEvent, { id: props.eventID }));
      debug(result);
      setValues({
        name: result.data.getEvent.name,
        overview: result.data.getEvent.overview,
        status: result.data.getEvent.status,
        mainCategory: result.data.getEvent.mainCategory,
        postCode1: result.data.getEvent.postCode1,
        postCode2: result.data.getEvent.postCode2,
        prefecture: result.data.getEvent.prefecture,
        city: result.data.getEvent.city,
        address: result.data.getEvent.address,
        startDateTime: result.data.getEvent.startDate + " " + result.data.getEvent.startTime,
        endDateTime: result.data.getEvent.endDate + " " + result.data.getEvent.endTime,
        userID: result.data.getEvent.userID,
        hashtags: result.data.getEvent?.hashtags || [],
        thumbnailImageKey: result.data.getEvent.thumbnailImageKey,
      });
    };

    runAPI().catch((error) => {
      debug(error);
    });
  }, [props.eventID]);

  const statusLabel = useCallback((status) => {
    switch (status) {
      case "AVAILABLE":
        return styles.eventStatusLabelColorBlue;
      case "CLOSED":
        return styles.eventStatusLabelColorRed;
      case "CANCELED":
        return styles.eventStatusLabelColorRed;
      case "COMPLETED":
        return styles.eventStatusLabelColorYellow;
      default:
        return styles.eventStatusLabelColorRed;
    }
  }, []);

  const statusText = useCallback((status) => {
    switch (status) {
      case "AVAILABLE":
        return "参加者募集中";
      case "CLOSED":
        return "参加者募集終了";
      case "CANCELED":
        return "開催中止";
      case "COMPLETED":
        return "開催終了";
      default:
        return "実施状況不明";
    }
  }, []);

  return (
    <div css={[styles.rootDiv, isWide ? styles.rootDivSP : styles.rootDivPC]}>
      <div css={styles.eventThumbnail}>
        <ThumbnailImage
          thumbnailImageKey={values.thumbnailImageKey}
          size={isWide ? 95 : 200}
          onClickEvent={toEvent}
          enableZoom
        />
        <div
          css={[
            styles.eventStatusLabel,
            statusLabel(values.status),
            isWide ? styles.eventStatusLabelSP : styles.eventStatusLabelPC,
          ]}
        >
          {statusText(values.status)}
        </div>
      </div>
      <div css={[styles.eventDetail, isWide ? styles.eventDetailSP : styles.eventDetailPC]}>
        <div css={[styles.eventDetailRow1, isWide ? styles.eventDetailRow1SP : styles.eventDetailRow1PC]}>
          <div css={[styles.eventTitle, isWide ? styles.eventTitleSP : styles.eventTitlePC]} onClick={toEvent}>
            {values.name}
          </div>
          {!isWide && <MainCategoryLabel mainCategory={values.mainCategory} />}
        </div>
        <div css={isWide ? styles.overviewSP : styles.overviewPC}>{values.overview}</div>
        <div css={styles.hashtags}>
          <Hashtag hashtagList={values.hashtags} options={{ size: isWide ? "small" : "medium" }} />
        </div>
        <div css={[styles.eventDetailRow3, isWide ? styles.eventDetailRow3SP : styles.eventDetailRow3PC]}>
          <div>
            <LocationOnIcon sx={{ color: "#999999" }} fontSize={"small"} />
            {convertValueToLabel(locale, values.prefecture)} {values.city}
          </div>
          <div>
            <EventIcon sx={{ color: "#999999" }} fontSize={"small"} />
            <span>{values.startDateTime}</span>
            <span className="endDatetime">&nbsp;~&nbsp;{values.endDateTime}</span>
          </div>
        </div>
        <div css={[styles.eventDetailRow4, isWide ? styles.eventDetailRow4SP : styles.eventDetailRow4PC]}>
          {/* // TODO チケット情報を表示する
          <div>
            <CurrencyYenIcon sx={{ color: "#999999" }} fontSize={"small"} />
            {/* // FIXME valuesから取得して設定しましょう 
            2,000円
          </div>
          <div>
            <PeopleIcon sx={{ color: "#999999" }} fontSize={"small"} />
            {/* // TODO [DB]参加ユーザ一覧からユーザを取得して現在の参加人数を集計する。graphQLにCOUNT(*)あるかな？ */}
          {/* // TODO valuesから最大の参加人数を取得して設定する
            10/15
          </div> */}
          <div>
            <UserIcon
              userID={values.userID}
              size={25}
              alt="プロフィール"
              options={{ labeled: true, enableLink: true }}
            />
          </div>
          {/* // FIXME valuesから点数を取得してRatingのvalueと「5.0」の箇所を書き換えましょう。テーブルに項目がなかったら修正するので言ってください！ */}
          {/* (<Rating css={isWide ? styles.none : styles.addressPC} value={5.0} readOnly size="small" /> 5.0) */}
        </div>
      </div>
    </div>
  );
});

export default ProfileThumbnail;
