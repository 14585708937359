import React from "react";

// 外部パッケージ
import useMedia from "use-media";
import { useForm, Controller } from "react-hook-form";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRecoilState } from "recoil";
import { userInputDataAtom } from "../common/UserInputDataAtom";

// MUI
import { TextField, Box, Button } from "@mui/material";

// その他
import theme from "components/MuiTheme";

const styles = {
  h1: css`
    padding-top: 110px;
    font-size: 48px;
    color: #fff;
    text-shadow: 0 0 0.2em rgba(0, 0, 0, 1);
    height: 50%;
  `,
};

const NewOverview = (props) => {
  const [userInputData, setUserInputData] = useRecoilState(userInputDataAtom);

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      ...userInputData.NewOverview,
    },
  });

  const onSubmit = (action) => {
    if (action === "back") {
      props.handleBack();
    } else {
      props.createPreview();
    }
    const data = getValues();

    setUserInputData({ ...userInputData, NewOverview: data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="overview"
        render={({ field }) => (
          <TextField
            {...field}
            multiline
            rows={6}
            required
            fullWidth
            id="overview"
            label="概要文"
            color="secondary"
            margin="normal"
            inputProps={{ maxLength: 1000, style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 14 } }}
          />
        )}
      />
      <Box sx={{ mb: 2 }}>
        <div>
          <Button onClick={() => onSubmit("back")} sx={{ mt: 1, mr: 1 }}>
            戻る
          </Button>
          <Button type="submit" variant="contained" sx={{ mt: 1, mr: 1 }}>
            プレビューを表示する
          </Button>
        </div>
      </Box>
    </form>
  );
};

export default NewOverview;

const NewOverviewTitle = () => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  return (
    <div>
      {!isWide && (
        <h1 css={styles.h1}>
          イベント概要を書く
          <br />
          <br />
          最後に、イベントの魅力を
          <br />
          存分に伝わる文章を作成しよう
        </h1>
      )}
    </div>
  );
};

export { NewOverviewTitle };
