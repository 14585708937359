import React from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useMedia from "use-media";
import { useNavigate } from "react-router-dom";

// MUI
import Button from "@mui/material/Button";

// その他
import theme from "../MuiTheme";
import guest1 from "../img/usage/Guest1.png";
import guest2 from "../img/usage/Guest2.png";
import guest3 from "../img/usage/Guest3.png";
import guest4 from "../img/usage/Guest4.png";
import guest5 from "../img/usage/Guest5.png";

// ここからスタイル↓
const styles = {
  mainPC: css`
    width: 85vw;
    display: flex;
  `,
  mainSP: css`
    width: 85vw;
  `,
  imgPC: css`
    width: 30%;
    height: 30%;
    padding: 30px;
  `,
  imgSP: css`
    width: 85vw;
  `,
  sub: css`
    padding-top: 2vw;
  `,
  none: css`
    opacity: 1;
  `,
  h3PC: css`
    font-size: 23px;
    text-align: left;
  `,
  h3SP: css`
    font-size: 15px;
    text-align: left;
  `,
  pPC: css`
    font-size: 15px;
    text-align: left;
  `,
  pSP: css`
    font-size: 14px;
    text-align: left;
  `,
  buttonPC: css`
    margin-top: 20px;
    margin-bottom: 30px;
    background-color: #db3531;
    width: 200px;
    height: 40px;
    font-weight: bold;
    float: left;
  `,
  buttonSP: css`
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #db3531;
    width: 200px;
    height: 40px;
    font-weight: bold;
  `,
};

function UsageGuest() {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  //画面遷移
  const navigate = useNavigate();
  const toSignIn = () => {
    navigate("/signIn");
  };
  const reservedEvent = () => {
    navigate("/profile");
  };
  // const message = () => {
  //   navigate("/message");
  // };
  const toTop = () => {
    navigate("/");
  };
  // const toRateReview = () => {
  //   navigate("/rateReview");
  // };
  return (
    <div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={guest1} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>1.ゲスト会員登録</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>登録料・利用料は無料です。</p>
          <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={toSignIn}>
            ゲスト登録
          </Button>
        </div>
      </div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={guest2} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>2.イベント検索</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>
            あなたの身近にある魅力的なイベントを探しに行こう。 <br />
            直感的なイベント検索バーにより、コミュニティ探しを楽しみましょう。
          </p>
          <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={toTop}>
            イベント検索
          </Button>
        </div>
      </div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={guest3} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>3.イベントに予約する</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>
            魅力的なイベントが見つかったら、そのイベントに予約してみましょう。
            <br />
            プランナーさんもあなたの事を待っています。
          </p>
          <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={reservedEvent}>
            イベント予約ページ
          </Button>
        </div>
      </div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={guest4} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>4.メッセージでやりとりする</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>
            プランナーにイベントの疑問点を聞いてみましょう。
            <br />
            当日までにご不明点を解消しておきましょう。
          </p>
          {/* <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={message}>
            メッセージ
          </Button> */}
        </div>
      </div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={guest5} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>5.イベントレビュー</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>
            イベントへの評価は十人十色です。
            <br />
            あなたの素直な感想をプランナーさんは待ってます。
            <br />
            (イベント参加後のレビューは任意となりますが、
            <br />
            サービス向上のためにご協力をお願いします。)
          </p>
          {/* <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={toRateReview}>
            レビュー
          </Button> */}
        </div>
      </div>
    </div>
  );
}

export default UsageGuest;
