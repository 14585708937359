import React from "react";

// MUI
import { Grid, TextField, IconButton, Modal, Table, Button } from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";
import MemberManageIcon from "@mui/icons-material/SupervisedUserCircle";
import MessageSettingIcon from "@mui/icons-material/Settings";
import MessageUserSearchIcon from "@mui/icons-material/ContactMail";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// その他
import MessageUserSearchPC from "./MessageUserSearchPC";
import SelectMessageUserPC from "./SelectMessageUserPC";
import MemberManagePC from "./MemberManagePC";
import MessageSettingPC from "./MessageSettingPC";

// β版用データ
import profPhoto1 from "../../components/img/message/S__247865346.jpg";
import profPhoto2 from "../../components/img/message/eventer_logo.png";
import profPhoto3 from "../../components/img/message/S__247865348.jpg";
import profPhoto4 from "../../components/img/message/S__247865349.jpg";
import profPhoto5 from "../../components/img/message/S__247865351.jpg";
import profPhoto6 from "../../components/img/message/S__247865353.jpg";
import profPhoto7 from "../../components/img/message/S__247865354.jpg";
import profPhoto8 from "../../components/img/message/S__247865352.jpg";
import profPhoto9 from "../../components/img/message/S__247865350.jpg";
import profPhoto10 from "../../components/img/message/S__247865355.jpg";

const styles = {
  bodySpacerStyle: css`
    display: "flex",
    height: "780px",
    width: "1473px",
    border: "solid #808080",
    borderWidth: "5px 5px 5px 5px",
  `,
  messageUserGrid: css`
    display: "table-cell",
    width: "500px",
    height: "716.5px",
  `,
  messageStringGrid: css`
    padding-top: 110px;
    font-size: 48px;
    color: #fff;
    text-shadow: 0 0 0.2em rgba(0, 0, 0, 1);
    height: 50%;
  `,
  messageNewUserIconGrid: css`
    display: "table-cell",
    width: "70px",
    height: "56px",
    textAlign: "center",
    verticalAlign: "middle",
    border: "solid #808080",
    borderWidth: "0px 5px 0px 0px",
  `,
  messageExistUserSearchGrid: css`
    border: "solid #808080",
    borderWidth: "0px 5px 5px 0px",
    padding: "0px 5px 0px 5px",
  `,
  messageUserListGrid: css`
    height: "651px",
    border: "solid #808080",
    borderWidth: "0px 5px 0px 0px",
    overflowY: "scroll",
  `,
  messageUserDataGrid: css`
    display: "table-cell",
    backgroundColor: "transparent",
    width: "500px",
    height: "60px",
  `,
  messageExchangeGrid: css`
    display: "table-cell",
    width: "1000px",
    height: "716.5px",
    border: "solid #808080",
    borderWidth: "0px 0px 0px 0px",
    `,
  messageExchangeForInitialDispGrid: css`
    display: "table-cell",
    width: "1000px",
    height: "716.5px",
    border: "solid #808080",
    borderWidth: "0px 0px 0px 0px",
    `,
  messageExchangeForMemberMngGrid: css`
    display: "table-cell",
    width: "1000px",
    height: "780px",
    border: "solid #808080",
    borderWidth: "0px 0px 0px 0px",
    overflowY: "scroll",
    `,
  messageExchangeHeaderGrid: css`
    display: "table-cell",
    width: "1000px",
    height: "30px",
    `,
  userListProfPhotoStyle: css`
    display: "flex",
    width: "55px",
    height: "55px",
    borderRadius: "55px",
    `,
  selectedUserProfPhotoStyle: css`
    display: "flex",
    width: "45px",
    height: "45px",
    borderRadius: "45px",
    `,
  messageExchangeHeaderUserGrid: css`
    display: "table-cell",
    width: "945px",
    height: "45px",
    textAlign: "-webkit-center",
    verticalAlign: "middle",
    `,
  messageSettingIconGrid: css`
    display: "table-cell",
    width: "60px",
    height: "45px",
    verticalAlign: "middle",
    textAlign: "center",
    `,
  messageExchangeContentGrid: css`
    width: "1000px",
    height: "648.5px",
    border: "solid #808080",
    borderWidth: "5px 0px 0px 0px",
    overflowY: "scroll",
    `,
  messageExchangeSendGrid: css`
    display: "table-cell",
    width: "1000px",
    height: "73px",
    border: "solid #808080",
    borderWidth: "5px 0px 0px 0px",
    `,
  messageExchangeLeftGrid: css`
    display: "table-cell",
    width: "100px",
    height: "50px",
    verticalAlign: "middle",
    `,
  messageExchangeCenterGrid: css`
    display: "table-cell",
    width: "800px",
    height: "50px",
    verticalAlign: "middle",
    `,
  messageExchangeRightGrid: css`
    display: "table-cell",
    width: "100px",
    height: "50px",
    verticalAlign: "middle",
    `,
  messageModalOverlayStyle: css`
    position: "fixed",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    `,
  messageModalContentStyle: css`
    height: "70%",
    width: "50%",
    padding: "1em",
    backgroundColor: "#fff",
    overflowY: "scroll",
    `,
};

// β版用データ
const messageUserDatas = [
  {
    messageRoomId: 0,
    messageUserAccountId: "yamamoto_hiroto",
    nickName: "山本浩人",
    lastMessageDate: "10秒",
    messageContent: "あなたが♡で反応しました",
    profPhoto: profPhoto1,
  },
  {
    messageRoomId: 1,
    messageUserAccountId: "Eventer_inc",
    nickName: "Eventer公式",
    lastMessageDate: "9分",
    messageContent: "Eventer公式です。こちらのイベントに興味がありま...",
    profPhoto: profPhoto2,
  },
  {
    messageRoomId: 2,
    messageUserAccountId: "chika",
    nickName: "ちか",
    lastMessageDate: "47分",
    messageContent: "ちかさんが👍で反応しました",
    profPhoto: profPhoto3,
  },
  {
    messageRoomId: 3,
    messageUserAccountId: "saori",
    nickName: "さおり",
    lastMessageDate: "3時間",
    messageContent: "あなたが♡で反応しました",
    profPhoto: profPhoto4,
  },
  {
    messageRoomId: 4,
    messageUserAccountId: "take1204",
    nickName: "タケ",
    lastMessageDate: "21時間",
    messageContent: "イベント参加希望です。",
    profPhoto: profPhoto5,
  },
  {
    messageRoomId: 5,
    messageUserAccountId: "yuta0928",
    nickName: "ユータ",
    lastMessageDate: "2月3日",
    messageContent: "はじめまして!!イベントに参加したいのですがまだ...",
    profPhoto: profPhoto6,
  },
  {
    messageRoomId: 6,
    messageUserAccountId: "hanako",
    nickName: "花子",
    lastMessageDate: "2021年12月22日",
    messageContent: "この度はビジネス講座イベントにご参加いただきあ...",
    profPhoto: profPhoto7,
  },
  {
    messageRoomId: 7,
    messageUserAccountId: "7nana7mi3",
    nickName: "ななみ",
    lastMessageDate: "2021年9月3日",
    messageContent: "本日は参加いただきありがとうございました。こ...",
    profPhoto: profPhoto8,
  },
  {
    messageRoomId: 8,
    messageUserAccountId: "12YukiMaru34",
    nickName: "ゆきまる",
    lastMessageDate: "2021年2月21日",
    messageContent: "お世話になっております。次のイベント日時につ...",
    profPhoto: profPhoto9,
  },
  {
    messageRoomId: 9,
    messageUserAccountId: "ko-dai_0123",
    nickName: "こーだい",
    lastMessageDate: "2019年12月15日",
    messageContent: "こーだいさんが👍で反応しました",
    profPhoto: profPhoto10,
  },
];

const MessagePC = () => {
  // モーダル：新たなメッセージ相手を探す
  const [isModalOpenNewMessUser, setIsModalOpenNewMessUser] = React.useState(false);
  const handleModalOpenNewMessUser = () => setIsModalOpenNewMessUser(true);
  const handleModalCloseNewMessUser = () => setIsModalOpenNewMessUser(false);
  function findNewMessUsersProc() {
    handleModalOpenNewMessUser();
  }

  // モーダル：メンバー管理
  const [isModalOpenMemberManage, setIsModalOpenMemberManage] = React.useState(false);
  const handleModalOpenMemberManage = () => setIsModalOpenMemberManage(true);
  const handleModalCloseMemberManage = () => setIsModalOpenMemberManage(false);
  function memberManageProc() {
    handleModalOpenMemberManage();
  }

  // モーダル：メッセージ設定
  const [isModalOpenMessageSetting, setIsModalOpenMessageSetting] = React.useState(false);
  const handleModalOpenMessageSetting = () => setIsModalOpenMessageSetting(true);
  const handleModalCloseMessageSetting = () => setIsModalOpenMessageSetting(false);
  function messageSettingProc() {
    handleModalOpenMessageSetting();
  }

  // メディア追加
  // function mediaAddOnProc() {
  //   document.getElementById("addMediaIcon").style.padding = "8px";
  //   document.getElementById("addMediaIcon").style.borderRadius = "50%";
  //   document.getElementById("addMediaIcon").style.backgroundColor = "#f5f5f5";
  // }
  // function mediaAddOverProc() {
  //   document.getElementById("addMediaIcon").style.padding = "";
  //   document.getElementById("addMediaIcon").style.borderRadius = "";
  //   document.getElementById("addMediaIcon").style.backgroundColor = "";
  // }

  // TODO メッセージ送信
  // function sendMessageProc() {
  //   alert("メッセージ送信イベントです。");
  // }

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      {/* モーダル：新たなメッセージ相手を探す */}
      <Modal open={isModalOpenNewMessUser} onClose={handleModalOpenNewMessUser}>
        <Grid>
          <div style={styles.messageModalOverlayStyle}>
            <div style={styles.messageModalContentStyle}>
              <IconButton onClick={handleModalCloseNewMessUser}>
                <CloseIcon />
              </IconButton>
              <MessageUserSearchPC messageUserDatas={messageUserDatas} />
            </div>
          </div>
        </Grid>
      </Modal>

      {/* モーダル：メンバー管理 */}
      <Modal open={isModalOpenMemberManage} onClose={handleModalOpenMemberManage}>
        <Grid>
          <div style={styles.messageModalOverlayStyle}>
            <div style={styles.messageModalContentStyle}>
              <IconButton onClick={handleModalCloseMemberManage}>
                <CloseIcon />
              </IconButton>
              <MemberManagePC messageUserDatas={messageUserDatas} />
            </div>
          </div>
        </Grid>
      </Modal>

      {/* モーダル：メッセージ設定 */}
      <Modal open={isModalOpenMessageSetting} onClose={handleModalOpenMessageSetting}>
        <Grid>
          <div style={styles.messageModalOverlayStyle}>
            <div style={styles.messageModalContentStyle}>
              <IconButton onClick={handleModalCloseMessageSetting}>
                <CloseIcon />
              </IconButton>
              <div>
                <MessageSettingPC isExistIndividual={false} />
              </div>
            </div>
          </div>
        </Grid>
      </Modal>

      <div style={styles.bodySpacerStyle}>
        <styles.messageUserGrid>
          <styles.messageStringGrid>&emsp;&emsp;メッセージ</styles.messageStringGrid>

          <styles.messageNewUserIconGrid>
            <label title="新たなメッセージ相手を探す">
              <IconButton onClick={findNewMessUsersProc}>
                <MessageUserSearchIcon sx={{ fontSize: "2rem" }} style={{ color: "#2F2F2F" }} />
              </IconButton>
            </label>
          </styles.messageNewUserIconGrid>

          <styles.messageExistUserSearchGrid>
            <TextField margin="dense" fullWidth id="searchUserText" name="searchUserText" label="ユーザーを検索" />
          </styles.messageExistUserSearchGrid>

          <styles.messageUserListGrid>
            {messageUserDatas.map((messageUserData) => (
              <div>
                <styles.messageUserDataGrid>
                  <Button src={""}>
                    <Table style={{ color: "black", textTransform: "none" }}>
                      <tr height={30}>
                        <td rowSpan={3}>
                          <img src={messageUserData.profPhoto} style={styles.userListProfPhotoStyle} alt="プロフ写真" />
                        </td>
                        <th width={125} align="left">
                          {messageUserData.nickName}
                        </th>
                        <td width={115} align="left">
                          @{messageUserData.messageUserAccountId}
                        </td>
                        <td width={150} align="right">
                          {messageUserData.lastMessageDate}
                        </td>
                        <td>
                          <label title="メッセージ設定">
                            <IconButton onClick={messageSettingProc}>
                              <MessageSettingIcon sx={{ fontSize: "1.3rem" }} style={{ color: "#000000" }} />
                            </IconButton>
                          </label>
                        </td>
                      </tr>
                      <tr height={40}>
                        <td colSpan={3} align="left" valign="top">
                          {messageUserData.messageContent}
                        </td>
                        <td colSpan={3} align="left" valign="top">
                          <label title="メンバー管理">
                            <IconButton onClick={memberManageProc}>
                              <MemberManageIcon sx={{ fontSize: "1.3rem" }} style={{ color: "#000000" }} />
                            </IconButton>
                          </label>
                        </td>
                      </tr>
                    </Table>
                  </Button>
                </styles.messageUserDataGrid>
              </div>
            ))}
          </styles.messageUserListGrid>
        </styles.messageUserGrid>

        <styles.messageExchangeGrid>
          <SelectMessageUserPC messageUserDatas={messageUserDatas} />
        </styles.messageExchangeGrid>
      </div>
    </div>
  );
};

export default MessagePC;
