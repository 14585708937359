import React from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useMedia from "use-media";

// MUI
import { ThemeProvider } from "@mui/material/styles";
import {
  Grid,
  TextField,
  MenuItem,
  Input,
  Typography,
  Modal,
  Divider,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

// その他
import BackgroundImg from "components/img/top/top004.jpg";
import theme from "components/MuiTheme";
import { locale, spaces } from "components/common/SelectValues";
import { getCurrentDate } from "components/common/Utils";

const styles = {
  top: css`
    display: flex;
    align-items: center;
    flex-direction: column;

    & .searchBarPC {
      margin-top: 1rem;
    }

    & .searchBarSP {
      margin-top: 1rem;
    }

    & .message {
      flex-grow: 1;
    }
  `,

  BackgroundImg: css`
    background-image: url(${BackgroundImg});
    background-size: cover;
  `,

  backgroundImgPC: css`
    width: 100%;
    height: 600px;
  `,

  backgroundImgSP: css`
    height: 40vw;
  `,

  displaySearchBarPC: css`
    & .searchBarPC {
      display: flex;
    }

    & .searchBarSP {
      display: none;
    }
  `,

  displaySearchBarSP: css`
    & .searchBarPC {
      display: none;
    }

    & .searchBarSP {
      display: flex;
    }
  `,

  searchBarGrid: css`
    background: #ffffff;
  `,

  searchBarGridPC: css`
    height: 80px;
    max-height: 80px;
    border-radius: 40px;
    width: 850px;
    padding-left: 40px;
    padding-right: 16px;

    & > div:not(:first-of-type):not(.searchBarIconPC) {
      margin-left: 12px;
    }

    & .searchBarIconPC {
      margin-left: 5px;
    }
  `,

  searchBarGridSP: css`
    height: 50px;
    border-radius: 25px;
    width: 90%;

    & .searchBarInputSP {
      margin-left: 25px;
    }

    & .searchBarIconSP {
      margin-right: 10px;
      margin-left: 10px;
    }
  `,

  searchBarInput: css`
    width: -webkit-fill-available;

    & .MuiInputBase-input {
      font-size: 1.2rem;
      font-weight: bolder;
    }
  `,

  messageGrid: css`
    display: flex;
    align-items: center;
  `,

  messageTypography: css`
    text-align: center;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 0.2em rgba(0, 0, 0, 1);
  `,

  messageTypographyPC: css`
    font-size: 3rem;
  `,

  messageTypographySP: css`
    font-size: 1.5rem;
  `,

  modalDiv: css`
    position: absolute;
    top: 8%;
    left: 4%;
    width: 84%;
    max-height: 90%;
    overflow: scroll;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 4%;

    & .modalTitleTypography {
      font-size: 1.2rem;
      font-weight: 500;
      text-align: left;
    }

    & .modalCloseIconGrid {
      text-align: right;
      position: relative;
      top: -13px;
      left: 13px;
    }
  `,

  modalTextField: css`
    width: -webkit-fill-available;
  `,

  modalButton: css`
    width: -webkit-fill-available;
    margin-top: 16px;
  `,

  textField: css`
    & .MuiInputLabel-root {
      font-size: 1.2rem;
      font-weight: 500;
    }

    & .MuiInputLabel-shrink {
      font-size: 1rem;
      font-weight: 500;
    }

    & .MuiInputBase-input {
      font-size: 1.2rem;
      font-weight: 500;
      padding-bottom: 0px;
    }

    & .MuiFormHelperText-sizeMedium {
      font-size: 0.7rem;
    }
  `,

  inputSearchDateWidth: css`
    width: 160px;
  `,

  inputSearchCategoryWidth: css`
    width: 190px;
  `,
};

// TODO [DB]スペース種類をDBから取得する
const FindSpace = (props) => {
  const [values, setValues] = React.useState({
    venue: "",
    date: getCurrentDate(),
    spaces: "",
    capacity: "",
  });

  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [isModalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const onClickModalSearchButton = () => {
    // デバッグ用
    alert(values.venue + "\n" + values.date + "\n" + values.mainCategory + "\n" + values.hashtag);
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        css={[
          styles.top,
          styles.BackgroundImg,
          isWide
            ? [styles.backgroundImgSP, styles.displaySearchBarSP]
            : [styles.backgroundImgPC, styles.displaySearchBarPC],
        ]}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          columnSpacing={0}
          wrap="nowrap"
          css={[styles.searchBarGrid, styles.searchBarGridPC]}
          className="searchBarPC"
        >
          <Grid item>
            <TextField
              select
              id="inputSearchVenue"
              label="エリアを選択"
              helperText="近くに面白い場所があるかも？"
              onChange={handleChange("venue")}
              value={values.venue}
              variant="standard"
              color="secondary"
              margin="dense"
              css={[styles.textField, styles.inputSearchCategoryWidth]}
            >
              {locale.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              id="inputSearchDate"
              label="イベント日時"
              helperText="いつ開催したい？"
              type="date"
              value={values.date}
              onChange={handleChange("date")}
              color="secondary"
              variant="standard"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              css={[styles.textField, styles.inputSearchDateWidth]}
            />
          </Grid>
          <Grid item>
            <TextField
              select
              id="inputSearchCategory"
              label="スペースの種類"
              helperText="タブから選択"
              onChange={handleChange("spaces")}
              value={values.spaces}
              variant="standard"
              color="secondary"
              margin="dense"
              css={[styles.textField, styles.inputSearchCategoryWidth]}
            >
              {spaces.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              id="inputSearchHashtag"
              label="収容人数"
              helperText="どのくらいの規模のイベント？"
              onChange={handleChange("capacity")}
              value={values.capacity}
              variant="standard"
              color="secondary"
              margin="dense"
              type="number"
              inputProps={{ min: 1 }}
              InputProps={{ startAdornment: <InputAdornment position="start">〜</InputAdornment> }}
              css={styles.textField}
            />
          </Grid>

          <Grid item className="searchBarIconPC">
            <IconButton onClick={onClickModalSearchButton}>
              <SearchIcon sx={{ fontSize: "2rem" }} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid
          css={[styles.searchBarGrid, styles.searchBarGridSP]}
          className="searchBarSP"
          justifyContent="space-between"
          alignItems="center"
          container
        >
          <Grid>
            <Input
              id="searchBarInput"
              placeholder="イベントを検索"
              onClick={handleModalOpen}
              variant="standard"
              color="secondary"
              size="small"
              disableUnderline={true}
              css={styles.searchBarInput}
              className="searchBarInputSP"
            />
          </Grid>

          <Grid className="searchBarIconSP">
            <SearchIcon sx={{ fontSize: "2rem" }} onClick={handleModalOpen} />
          </Grid>
        </Grid>

        <Grid css={styles.messageGrid} className="message">
          <Typography
            css={[styles.messageTypography, isWide ? styles.messageTypographySP : styles.messageTypographyPC]}
          >
            もっと身近にイベントを
          </Typography>
        </Grid>

        <Modal open={isModalOpen} onClose={handleModalClose}>
          <div css={styles.modalDiv}>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item xs>
                <Typography className="modalTitleTypography">イベントを検索</Typography>
              </Grid>
              <Grid item xs className="modalCloseIconGrid">
                <IconButton onClick={handleModalClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <TextField
              id="inputSearchVenue"
              label="開催場所"
              onChange={handleChange("venue")}
              value={values.venue}
              variant="outlined"
              color="secondary"
              margin="normal"
              css={styles.modalTextField}
            />

            <TextField
              id="inputSearchDate"
              label="開催日時"
              type="date"
              value={values.date}
              onChange={handleChange("date")}
              color="secondary"
              variant="outlined"
              margin="normal"
              sx={styles.modalTextField}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              select
              id="inputSearchCategory"
              label="カテゴリー"
              value={values.spaces}
              onChange={handleChange("mainCategory")}
              variant="outlined"
              color="secondary"
              margin="normal"
              sx={styles.modalTextField}
            >
              {spaces.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              id="inputSearchHashtag"
              label="ハッシュタグ"
              onChange={handleChange("hashtag")}
              value={values.hashtag}
              variant="outlined"
              color="secondary"
              margin="normal"
              sx={styles.modalTextField}
            />

            <Button
              size="large"
              variant="contained"
              color="secondary"
              startIcon={<SearchIcon />}
              sx={styles.modalButton}
              onClick={onClickModalSearchButton}
            >
              検索
            </Button>
          </div>
        </Modal>
      </div>
    </ThemeProvider>
  );
};

export default FindSpace;
