import React, { useState } from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useMedia from "use-media";
import PropTypes from "prop-types";

// MUI
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { ThemeProvider } from "@mui/system";

// その他
import sikumi from "../img/usage/usage.png";
import UsageGuest from "./UsageGuest";
import UsagePlanner from "./UsagePlanner";
import UsageOwner from "./UsageOwner";
import theme from "../MuiTheme";

// ここからスタイル↓
const styles = {
  main: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  subPC: css`
    width: 85%;
    margin-bottom: 5px;
  `,
  subSP: css`
    width: 90%;
  `,
  h1: css`
    font-size: 25px;
    text-align: center;
    margin: 30px 0;
  `,
  pPC: css`
    font-size: 15px;
    text-align: center;
    margin: 5px 30px;
  `,
  pSP: css`
    font-size: 15px;
    text-align: left;
    margin: 5px 0;
  `,
  h2PC: css`
    font-size: 25px;
    text-align: center;
    margin-top: 80px;
  `,
  h2SP: css`
    font-size: 23px;
    text-align: center;
    margin-top: 40px;
  `,
  mainImgPC: css`
    width: 45%;
    height: 45%;
    padding: 30px;
  `,
  mainImgSP: css`
    width: 87vw;
  `,
  h3PC: css`
    font-size: 15px;
    text-align: left;
    padding-top: 15px;
  `,
  h3SP: css`
    font-size: 15px;
    text-align: left;
    padding-top: 25px;
    padding-bottom: 0;
  `,
  p1: css`
    font-size: 15px;
    text-align: left;
  `,
  tabPC: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 65%;
  `,
  tabSP: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  `,
  tabh2PC: css`
    font-size: 25px;
    margin: 40px 0;
  `,
  tabh2SP: css`
    font-size: 20px;
    margin: 10px 0;
  `,
  usageList: css`
    width: 50%;
    height: 20%;
  `,
  none: css`
    opacity: 1;
  `,
  main2: css`
    display: flex;
  `,
  list: css`
    width: 45%;
    position: right;
  `,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const tab = { fontWeight: "bold", fontSize: 18 };

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UsagePC = () => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div css={styles.main}>
      <div css={isWide ? styles.subSP : styles.subPC}>
        <div>
          <h1 css={styles.h1}>Eventerご利用ガイド</h1>
          <p css={isWide ? styles.pSP : styles.pPC}>
            Eventerは"もっと身近にイベント"で溢れる社会にするべく、
            <br />
            リアルイベントの成功を応援する”企画と人と場所”を繋ぐプラットフォームです。
          </p>
          <h2 css={isWide ? styles.h2SP : styles.h2PC}>Eventerの仕組み</h2>
        </div>

        <div css={isWide ? styles.none : styles.main2}>
          <img css={isWide ? styles.mainImgSP : styles.mainImgPC} src={sikumi} alt={"profile"} />
          <div css={isWide ? styles.none : styles.list}>
            <h3 css={isWide ? styles.h3SP : styles.h3PC}>コミュニティ形成をサポート</h3>
            <p css={styles.p1}>
              参加者としてコミュニティに触れて頂くと、ご自分でイベントを企画したくなってくるものです。
            </p>
            <h3 css={isWide ? styles.h3SP : styles.h3PC}>コミュニティがあなたを待ってる</h3>
            <p css={styles.p1}>
              会社と自宅の往復の毎日。
              <br />
              そんな日々の中で、ストレスを感じては無いでしょうか。
              <br />
              イベントコミュニティに触れて、色んな人と話してみてください。
            </p>
            <h3 css={isWide ? styles.h3SP : styles.h3PC}>空き場所をイベントで盛り上げる</h3>
            <p css={styles.p1}>
              もし、現時点で空きスペースを所有されてる方はチャンスです。
              <br />
              そこでイベント活動をして頂ければ、お店の集客や運用問題に効果があります。
            </p>
          </div>
        </div>
      </div>

      <div css={isWide ? styles.tabSP : styles.tabPC}>
        <h2 css={isWide ? styles.tabh2SP : styles.tabh2PC}>Eventerご利用の流れ</h2>
      </div>
      <div css={isWide ? styles.none : styles.usageList}>
        <ThemeProvider theme={theme}>
          <Box sx={{ borderBottom: 1, borderColor: "#fff" }}>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab style={tab} label="ゲスト" {...a11yProps(0)} />
              <Tab style={tab} label="プランナー" {...a11yProps(1)} />
              <Tab style={tab} label="オーナー" {...a11yProps(2)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <UsageGuest />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UsagePlanner />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <UsageOwner />
          </TabPanel>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default UsagePC;
