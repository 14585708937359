import React from "react";

// 外部パッケージ
import { useNavigate } from "react-router-dom";

// MUI
import { ThemeProvider, Box, Button, TextField, Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

// その他
import theme from "components/MuiTheme";
import ProfileThumbnail from "components/myPage/ProfileThumbnail";

// スタイル
const labels = {
  0: "レビューしてください",
};

const root = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: theme.palette.grey[200],
};

const rootRating = {
  marginTop: 20,
  marginBottom: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: " 0 30px 30px 30px",
  background: "white",
  borderRadius: 5,
};

const iconStyle = {
  margin: "15px 0",
};

const buttonStyle = {
  marginTop: 15,
  height: 40,
};

// ここからメインのRatingコンポーネント
export default function RateReview() {
  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Box style={root}>
        <Box component="form" noValidate style={rootRating}>
          <div style={iconStyle}>
            <h3 style={{ paddingBottom: 30 }}>レビュー画面</h3>
            <ProfileThumbnail eventID="4a1a7434-993f-493c-a952-49110489b3a4" />
          </div>
          <Box
            sx={{
              width: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Rating
              name="rating"
              value={value}
              size="large"
              precision={1}
              marginRight="auto"
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
            {value !== null && <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>}
          </Box>

          <TextField
            color="secondary"
            margin="normal"
            multiline
            rows={5}
            fullWidth
            id="inputReview"
            label="レビュー本文"
            name="review"
          />
          <Button style={buttonStyle} variant="contained" fullWidth onClick={() => navigate("/profile")}>
            投稿する
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
