import React, { useEffect, useState, useCallback } from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// その他
import { getDebugger } from "components/Debugger";
import defaultThumbnail from "components/img/eventer_logo.png";
import { useS3 } from "hooks/useS3Hooks";
import { Event } from "API";

var debug = getDebugger(false);

const styles = {
  img: css`
    border-radius: 1%;
    object-fit: cover;
  `,
  zoom: css`
    cursor: pointer;
    transition-duration: 0.5s;
    :hover {
      transform: scale(1.1);
    }
  `,
};

type ThumbnailImageProps = {
  thumbnailImageFile?: File;
  thumbnailImageKey?: Event["thumbnailImageKey"];
  size: number;
  onClickEvent?: () => void;
  enableZoom?: boolean;
};

/**
 * Generates a thumbnail image.
 * If the thumbnailImageFile prop is provided, it will be used as the image source.
 * Otherwise, retrieves an image using the S3 key and creates a thumbnail.
 * If no event image is registered, a default image is set.
 * @param {File} [thumbnailImageFile] - Optional image source to be used instead of S3.
 * @param {string} [thumbnailImageKey] - The S3 key.
 * @param {number} size - The icon size.
 * @param {() => void} [onClickEvent] - The event when clicked.
 * @param {boolean} [enableZoom] - Whether to zoom the image on mouse hover.
 */
export const ThumbnailImage: React.FC<ThumbnailImageProps> = ({
  thumbnailImageFile,
  thumbnailImageKey,
  size,
  onClickEvent = () => {},
  enableZoom = false,
}) => {
  const [url, setUrl] = useState(defaultThumbnail);
  const { getFileFromS3 } = useS3();

  const getThumbnailURLFromS3 = useCallback(
    async (key: string) => {
      try {
        if (!key) return;
        const getResult = await getFileFromS3(key);
        return Promise.resolve(getResult);
      } catch (error) {
        debug(error);
        return Promise.reject("サムネイル画像の取得に失敗しました。(S3)");
      }
    },
    [getFileFromS3]
  );

  useEffect(() => {
    let ignore = false;

    const fetchThumbnail = async () => {
      if (ignore) return;
      if (thumbnailImageFile) {
        setUrl(URL.createObjectURL(thumbnailImageFile));
      } else if (thumbnailImageKey) {
        try {
          const result = await getThumbnailURLFromS3(thumbnailImageKey);
          setUrl(result);
        } catch (error) {
          debug(error);
        }
      }
    };

    fetchThumbnail();

    return () => {
      ignore = true;
    };
  }, [getThumbnailURLFromS3, thumbnailImageKey, thumbnailImageFile]);

  return (
    <div style={{ maxWidth: size, maxHeight: size, overflow: "hidden" }}>
      <img
        css={[styles.img, enableZoom && styles.zoom]}
        style={{ width: size, height: size }}
        src={url}
        onClick={onClickEvent}
        alt={"event"}
        onError={(e) => ((e.target as HTMLImageElement).src = defaultThumbnail)}
      />
    </div>
  );
};
