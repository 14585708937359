const FooterLinkList = [
  {
    key: "footerLink1",
    value: "利用ガイド",
    url: "usage",
  },
  {
    key: "footerLink2",
    value: "会社概要&お問合せ",
    externalUrl: "https://eventer.work/",
  },
  {
    key: "footerLink3",
    value: "利用規約",
    url: "term",
  },
  {
    key: "footerLink4",
    value: "特定商取引法に基づく表示",
    url: "ebizrule",
  },
  {
    key: "footerLink5",
    value: "プライバシーポリシー",
    url: "policy",
  },
];

export default FooterLinkList;
