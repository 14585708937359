import img from "components/img/icon/tiktok.png";
import people from "components/img/icon/イベント.png";
/**
 * ユーザアイコンの生成
 * プロフィール画像が登録されていない場合、デフォルトアイコンを作成する
 * @param {Number} size - アイコンのサイズ
 */
export const TikTokIcon = ({ size }) => {
  return (
    <div>
      <img
        width={size}
        height={size}
        src={img}
        className="logo"
        //   css={styles.icon}
        alt={"tiktok"}
      />
    </div>
  );
};

export const PeopleIcon = ({ size }) => {
  return (
    <div>
      <img
        height={size}
        src={people}
        className="logo"
        //   css={styles.icon}
        alt={"people"}
      />
    </div>
  );
};
