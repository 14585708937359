import React, { useState, useContext, createContext, useEffect } from "react";

// AWS
import { Auth } from "aws-amplify";

// その他
import { getDebugger } from "components/Debugger";

const authContext = createContext();

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

var debug = getDebugger(false);
const useProvideAuth = () => {
  debug("useProvideAuth is loading");
  const [isLoading, setIsLoading] = useState(true);
  const [userID, setUserID] = useState("");
  const isAuthenticated = userID !== "";

  /** 初期表示の実行 */
  useEffect(() => {
    debug("useEffect is loading.");
    getCurrentAuthenticatedUser()
      .then((userid) => {
        debug("userid 1:", userid);
        setCurrentAuthneticatedUser(userid);
      })
      .catch((error) => {
        debug("catch");
        setCurrentAuthneticatedUser(""); // エラー時はuseridが空文字で呼び出される
      });
  }, []);

  /**
   * 認証状態取得
   */
  const getCurrentAuthenticatedUser = async () => {
    debug("getCurrentAuthenticatedUser is loading.");
    try {
      const user = await Auth.currentAuthenticatedUser();
      debug("getCurrentAuthenticatedUser returns user.username:", user.username);
      return Promise.resolve(user.username);
    } catch (error) {
      // 未ログインの状態ではエラーが発生するため、エラーが発生した際に未ログインと判断する
      debug("Error message: ", error);
      debug("The error has occurred in getCurrentAuthenticatedUser.");
    }
  };

  /**
   * 認証状態設定
   * @param {Stringl} userid - ユーザID
   */
  const setCurrentAuthneticatedUser = (userid) => {
    debug("setCurrentAuthneticatedUser is loading.");
    debug("userid", userid);
    if (userid === "" || userid === undefined) {
      setUserID("");
    } else {
      setUserID(userid);
    }
    setIsLoading(false);
  };

  /**
   * 新規登録
   * @param {String} email - ユーザ名（メールアドレス）
   * @param {String} password - パスワード
   * @param {String} nickname - ニックネーム
   * @param {String} gender - 性別
   * @param {String} birthdate - 生年月日
   */
  const signUp = async (email, password, nickname, gender, birthdate) => {
    setIsLoading(true);
    try {
      const param = {
        username: email,
        password: password,
        attributes: {
          email: email,
          nickname: nickname,
          gender: gender,
          birthdate: birthdate,
        },
      };
      const result = await Auth.signUp(param);
      if (result) {
        return {
          success: true,
          message: "新規登録に成功しました。",
        };
      }
    } catch (error) {
      debug(error);
      debug("The error has occurred in signIn.");
    } finally {
      setIsLoading(false);
    }
    // 失敗時はここに到達する
    return {
      success: false,
      message: "新規登録に失敗しました。",
    };
  };

  /**
   * ログイン
   * @param {String} email - メールアドレス
   * @param {String} password - パスワード
   */
  const signIn = async (email, password) => {
    setIsLoading(true);
    try {
      await Auth.signIn(email, password)
        .then(() => getCurrentAuthenticatedUser())
        .then((userid) => setCurrentAuthneticatedUser(userid));
      return {
        success: true,
        message: "ログインに成功しました。",
      };
    } catch (error) {
      debug(error);
      debug("The error has occurred in signIn.");
      return {
        success: false,
        message: "ログインに失敗しました。",
      };
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * ログアウト
   */
  const signOut = async () => {
    Auth.signOut()
      .then(() => getCurrentAuthenticatedUser())
      .then((userid) => setCurrentAuthneticatedUser(userid))
      .catch((error) => {
        debug(error);
        debug("The error has occurred in signOut.");
        alert("ログアウトに失敗しました。");
      });
  };

  /**
   * パスワード変更
   * @param {String} oldPassword - 古いパスワード
   * @param {String} newPassword - 新しいパスワード
   */
  const changePassword = async (oldPassword, newPassword) => {
    try {
      await Auth.currentAuthenticatedUser().then((user) => Auth.changePassword(user, oldPassword, newPassword));
      return {
        success: true,
        message: "",
      };
    } catch (error) {
      debug(error);
      debug("The error has occurred in changePassword.");
      return {
        success: false,
        message: "パスワード変更に失敗しました。",
      };
    }
  };

  /**
   * パスワードを忘れた際の認証コード送信
   * @param {String} username - ユーザ名
   */
  const forgotPassword = async (username) => {
    try {
      await Auth.forgotPassword(username);
      return {
        success: true,
        message: "",
      };
    } catch (error) {
      debug(error);
      debug("The error has occurred in forgotPassword.");
      return {
        success: false,
        message: "認証コードの送信に失敗しました。",
      };
    }
  };

  /**
   * パスワード再設定
   * @param {String} username - ユーザ名
   * @param {String} code - 認証コード
   * @param {String} password - パスワード
   */
  const forgotPasswordSubmit = async (username, code, password) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      return {
        success: true,
        message: "",
      };
    } catch (error) {
      debug(error);
      debug("The error has occurred in forgotPasswordSubmit.");
      return {
        success: false,
        message: "パスワードの再設定に失敗しました。",
      };
    }
  };

  /**
   * 退会
   */
  const deleteUser = async () => {
    setIsLoading(true);
    await Auth.deleteUser();
    getCurrentAuthenticatedUser()
      .then((userid) => setCurrentAuthneticatedUser(userid))
      .then(() => {
        return Promise.resolve({
          success: true,
          message: "退会手続きが完了しました。",
        });
      })
      .catch((error) => {
        debug(error);
        debug("The error has occurred in deleteUser.");
        return Promise.reject({
          success: false,
          message: "退会に失敗しました。",
        });
      })
      .finally(setIsLoading(false));
  };

  return {
    isLoading,
    isAuthenticated,
    // userInfo,
    userID,
    signUp,
    signIn,
    signOut,
    changePassword,
    forgotPassword,
    forgotPasswordSubmit,
    // getUserInfo,
    // updateUserInfo,
    deleteUser,
  };
};
