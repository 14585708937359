import React from "react";

// 外部パッケージ
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useMedia from "use-media";

// MUI
import Button from "@mui/material/Button";

// その他
import theme from "components/MuiTheme";

// ここからスタイル↓
const styles = {
  rootPC: css`
    width: 85vw;
    display: flex;
  `,
  rootSP: css`
    width: 85vw;
  `,
  div: css`
    padding-top: 2vw;
  `,
  h3PC: css`
    font-size: 23px;
    text-align: left;
  `,
  h3SP: css`
    font-size: 15px;
    text-align: left;
  `,
  pPC: css`
    font-size: 15px;
    text-align: left;
  `,
  pSP: css`
    font-size: 14px;
    text-align: left;
  `,
  button: css`
    margin-top: 20px;
    margin-bottom: 30px;
    background-color: #db3531;
    width: 200px;
    height: 40px;
    font-weight: bold;
    float: left;
  `,
};

function Method() {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  //画面遷移
  const navigate = useNavigate();
  const toTop = () => {
    navigate("/");
  };

  return (
    <div css={isWide ? styles.rootSP : styles.rootPC}>
      <div css={styles.div}>
        <h3 css={isWide ? styles.h3SP : styles.h3PC}>支払い方法</h3>
        <p css={isWide ? styles.pSP : styles.pPC}>
          Eventerの安全な支払いシステムからお支払い方法を追加し、
          <br />
          イベントの参加をはじめましょう。
        </p>
        <Button css={styles.button} variant="contained" onClick={toTop}>
          <a href="https://buy.stripe.com/6oEg0v2oGaDoe5yfYY">支払い方法を追加</a>
        </Button>
      </div>
    </div>
  );
}

export default Method;
