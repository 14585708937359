import { useState, useCallback, useMemo, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getDebugger } from "components/Debugger";
import { CustomListEvents } from "graphql/customQueries";
import { useSearchParams } from "react-router-dom";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { ListEventsQuery } from "models/API";

const debug = getDebugger(false);

export const useSearchResult = () => {
  const [searchParams] = useSearchParams();

  const [searchResultEventIds, setSearchResultEventIds] = useState<string[]>([]);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);

  // ページネーション
  // HACK 1ページあたりの表示件数を変えるボタン導入
  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const currentEventIds = useMemo(() => {
    const endOffset = itemOffset + itemsPerPage;
    const currentEventIds = searchResultEventIds.slice(itemOffset, endOffset);
    return currentEventIds;
  }, [itemOffset, searchResultEventIds]);

  const handleSubmit = useCallback(async () => {
    setItemOffset(0);
    try {
      setIsSearchLoading(true);
      const prefecture = searchParams.get("prefecture");
      const date = searchParams.get("date");
      const mainCategory = searchParams.get("mainCategory");
      const hashtag = searchParams.get("hashtag");

      const filter = {
        status: { eq: "AVAILABLE" },
        ...(prefecture ? { prefecture: { eq: prefecture } } : {}),
        ...(date ? { startDate: { eq: date } } : {}),
        ...(mainCategory ? { mainCategory: { eq: mainCategory } } : {}),
        ...(hashtag ? { hashtags: { contains: hashtag } } : {}),
      };

      const sort = { field: "createdAt", direction: "desc" };

      const result = (await API.graphql(
        graphqlOperation(CustomListEvents, {
          filter,
          sort,
        })
      )) as GraphQLResult<ListEventsQuery>;

      debug(result);

      setSearchResultEventIds(() => {
        if (!result) throw new Error("イベント情報の取得に失敗しました。(DB)");
        return result?.data?.listEvents?.items.filter((item) => item).map((item) => item!.id) || [];
      });

      return { success: true };
    } catch (error) {
      alert("イベントの検索に失敗しました。");
      debug(error);
      return { success: false };
    } finally {
      setIsSearchLoading(false);
    }
  }, [searchParams]);

  useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  return {
    searchResultEventIds,
    isSearchLoading,
    itemsPerPage,
    setItemOffset,
    currentEventIds,
    handleSubmit,
  };
};
