import { React, useEffect, useContext } from "react";

// 外部パッケージ
import useMedia from "use-media";
import { useForm, Controller } from "react-hook-form";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// MUI
import { TextField, MenuItem, Box, Button, InputAdornment, OutlinedInput } from "@mui/material";

// その他
import { spaces } from "components/common/SelectValues";
import { UserInputData } from "components/AddSpace/AddSpace";
import theme from "components/MuiTheme";

const styles = {
  h1: css`
    padding-top: 20px;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    opacity: 0.8;
  `,
  div: css`
    display: flex;
  `,
  h2: css`
    padding-top: 110px;
    font-size: 48px;
    color: #fff;
    text-shadow: 0 0 0.2em rgba(0, 0, 0, 1);
    height: 50%;
  `,
};

function Type(props) {
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      spaceName: "",
      overview: "",
      spaceCategory: "",
      roomSize: "",
      roomCapacity: "",
    },
  });

  const { currentState, setCurrentState } = useContext(UserInputData);

  // 保持している値を表示する
  useEffect(() => {
    if (currentState.Type) {
      setValue("spaceName", currentState.Type.spaceName);
      setValue("overview", currentState.Type.overview);
      setValue("spaceCategory", currentState.Type.spaceCategory);
      setValue("roomSize", currentState.Type.roomSize);
      setValue("roomCapacity", currentState.Type.roomCapacity);
    }
  }, [currentState.Type, setValue]);

  const onSubmit = (data) => {
    props.handleNext();
    setCurrentState({ ...currentState, Type: data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h1 css={styles.h1}>施設・場所の名前（20文字以内）</h1>
        <Controller
          control={control}
          name="spaceName"
          render={({ field }) => (
            <TextField
              {...field}
              autoFocus
              id="inputSpaceName"
              color="secondary"
              margin="normal"
              required
              fullWidth
              label="例）富久の湯"
              InputLabelProps={{ style: { fontSize: 14 } }}
              inputProps={{ style: { fontSize: 14 }, maxLength: 20 }}
            />
          )}
        />
        <h1 css={styles.h1}>概要・魅力の説明</h1>
        <Controller
          control={control}
          name="overview"
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              rows={6}
              required
              fullWidth
              id="inputOverview"
              label="例）東京都台東区にある銭湯です。"
              color="secondary"
              margin="normal"
              inputProps={{ maxLength: 1000, style: { fontSize: 14 } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />
          )}
        />
        <h1 css={styles.h1}>種類・用途の設定をしてください。</h1>
        <Controller
          control={control}
          name="spaceCategory"
          render={({ field }) => (
            <TextField
              {...field}
              select
              id="inputSpaceCategory"
              label="スペースの種類"
              required
              variant="outlined"
              color="secondary"
              margin="type"
              fullWidth
              InputLabelProps={{ style: { fontSize: 14 } }}
            >
              {spaces.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <div style={{ fontSize: 14, textAlign: "left" }}>{option.label}</div>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <h1 css={styles.h1}>サイズ（㎡）設定</h1>
        <div css={styles.div}>
          <Controller
            control={control}
            name="roomSize"
            render={({ field }) => (
              <OutlinedInput
                {...field}
                autoFocus
                id="inputRoomSize"
                required
                // variant="standard"
                color="secondary"
                type="text"
                endAdornment={<InputAdornment position="end">㎡</InputAdornment>}
                inputProps={{ style: { fontSize: 14 }, min: 1, pattern: "^[0-9]+$" }}
                InputLabelProps={{ shrink: true, style: { fontSize: 14 } }}
                style={{ width: 100, marginRight: 10 }}
                input
              />
            )}
          />
        </div>
        <h1 css={styles.h1}>収容人数設定</h1>
        <div css={styles.div}>
          <Controller
            control={control}
            name="roomCapacity"
            render={({ field }) => (
              <OutlinedInput
                {...field}
                id="inputRoomCapacity"
                label="収容人数"
                required
                // variant="standard"
                color="secondary"
                type="text"
                endAdornment={<InputAdornment position="end">人</InputAdornment>}
                inputProps={{ style: { fontSize: 14 }, min: 1, pattern: "^[0-9]+$" }}
                InputLabelProps={{ shrink: true, style: { fontSize: 14 } }}
                style={{ width: 100, marginRight: 10 }}
              />
            )}
          />
        </div>
        <Box sx={{ mb: 2 }}>
          <div>
            <Button type="submit" variant="contained" sx={{ mt: 1, mr: 1 }}>
              次へ
            </Button>
          </div>
        </Box>
      </div>
    </form>
  );
}

export default Type;

const TypeTitle = () => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  return (
    <div>
      {!isWide && (
        <h1 css={styles.h2}>
          掲載スペース
          <br />
          基本情報設定
        </h1>
      )}
    </div>
  );
};

export { TypeTitle };
