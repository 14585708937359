import { useCallback, useEffect } from "react";
import { getDebugger } from "components/Debugger";
import { Location, useNavigate } from "react-router-dom";
import { useEventDB } from "./EventDBHooks";
import { useEventS3 } from "./EventS3Hooks";
import { useEventState } from "./EventStateHooks";

var debug = getDebugger(false);

/**
 * Hook to manage event registration and updates.
 *
 * @param {string} [eventID] - The event ID.
 * @param {Location["state"]} [location] - The location object.
 * @returns {object} - The hook's state and callback functions.
 */
export const useEvent = (eventID?: string, location?: Location["state"]) => {
  const navigate = useNavigate();
  const { eventValues, setEventValues, setEventValuesFromPreview } = useEventState();
  const { getEventInfo, createEventInFront, updateEventInFront, isDBLoading } = useEventDB();
  const { uploadThumbnailImageToS3, removeThumbnailImageFromS3, isS3Loading } = useEventS3();

  /**
   * Fetches event values when navigating from the AddEvent page.
   *
   * @param {string} eventID - The event ID.
   * @returns {Promise<eventValuesType>} - The fetched event values.
   */
  const getEventValuesFromAddEvent = useCallback(
    async (eventID: string) => {
      try {
        const getEventInfoResult = await getEventInfo(eventID);
        return { ...getEventInfoResult };
      } catch (error) {
        throw error;
      }
    },
    [getEventInfo]
  );

  useEffect(() => {
    debug("eventID: ", eventID);
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      if (signal.aborted) {
        return;
      } else if (location && eventID === "preview") {
        // From Preview
        debug(location);
        setEventValuesFromPreview(location);
      } else if (eventID) {
        // From AddEvent
        try {
          const result = await getEventValuesFromAddEvent(eventID);
          debug(result);
          if (!signal.aborted) {
            setEventValues({ ...eventValues, ...result });
          }
        } catch (error) {
          if (!signal.aborted) {
            debug(error);
            alert("イベント情報の取得に失敗しました。");
          }
        }
      }
    };

    fetchData();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // イベント作成ボタン
  const onClickCreateEvent = useCallback(async () => {
    if (isDBLoading) return;
    try {
      let uploadResult = null;
      if (location.currentState.thumbnailImageFile) {
        uploadResult = await uploadThumbnailImageToS3(location.currentState.thumbnailImageFile);
      }
      const thumbnailImageKey = uploadResult?.key || "";
      const result = await createEventInFront(location, thumbnailImageKey);

      if (result.success) {
        alert(result.message);
        navigate("/profile", { replace: true });
      } else {
        alert(result.message);
        debug(result);
      }
    } catch (error) {
      alert("イベント登録に失敗しました。");
      debug(error);
    }
  }, [isDBLoading, location, navigate, uploadThumbnailImageToS3, createEventInFront]);

  // イベント更新ボタン
  const onClickUpdateEvent = useCallback(async () => {
    if (isDBLoading) return;
    try {
      let result = null;
      if (location.currentState.requiresUploadThumbnail) {
        debug("Uploads a thumbnail");
        result = await uploadThumbnailImageToS3(location.currentState.thumbnailImageFile);
      }

      if (location.currentState.requiresRemoveOldThumbnail) {
        debug("Removes an old thumbnail");
        // thumbnailImageKey is an old thumbnail. it will be updated to new one after updateEventInFront.
        await removeThumbnailImageFromS3(location.currentState.thumbnailImageKey);
      }

      const thumbnailImageKey = result?.key || eventValues.thumbnailImageKey || "";
      const updateResult = await updateEventInFront(location, thumbnailImageKey);

      if (updateResult.success) {
        alert(updateResult.message);
        navigate("/profile");
      } else {
        alert(updateResult.message);
        debug(updateResult);
      }
    } catch (error) {
      alert("イベント登録に失敗しました。");
      debug(error);
    }
  }, [
    isDBLoading,
    location,
    eventValues.thumbnailImageKey,
    updateEventInFront,
    removeThumbnailImageFromS3,
    uploadThumbnailImageToS3,
    navigate,
  ]);

  return { eventValues, isDBLoading, isS3Loading, onClickCreateEvent, onClickUpdateEvent };
};
