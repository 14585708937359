import React from "react";

// MUI
import MessageUserSearchIcon from "@mui/icons-material/ContactMail";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MessageSendIcon from "@mui/icons-material/Send";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// スタイル
const styles = {
  mesUserSrhOutline: css`
    border: "solid #808080",
    borderWidth: "1px",
  `,
  mesUserSrhDispNameBase: css`
    borderBottom: "solid #808080",
    borderWidth: "1px",
    padding: "0px 100px 0px 200px",
  `,
  mesUserSrhSend: css`
    display: "table-cell",
    width: "1000px",
    height: "30px",
  `,
  mesUserSrhDispNameStr: css`
    fontSize: "25px",
    fontWeight: "bold",
    display: "inline-block",
  `,
  mesUserSrhMemList: css`
    color: "black",
    textTransform: "none",
    padding: "10px 10px 10px 10px",
  `,
  mesUserSrhMemListProfPhoto: css`
    display: "flex",
    width: "55px",
    height: "55px",
    borderRadius: "55px",
  `,
};

// スタイル
// const MesUserSrhOutline = {
//   border: "solid #808080",
//   borderWidth: "1px",
// };

// const MesUserSrhDispNameBase = {
//   borderBottom: "solid #808080",
//   borderWidth: "1px",
//   padding: "0px 100px 0px 200px",
// };

// const MesUserSrhSend = {
//   borderBottom: "solid #808080",
//   borderWidth: "1px",
//   padding: "0px 25px 0px 25px",
// };

// const MesUserSrhDispNameStr = {
//   fontSize: "25px",
//   fontWeight: "bold",
//   display: "inline-block",
// };

// const MesUserSrhMemList = {
//   color: "black",
//   textTransform: "none",
//   padding: "10px 10px 10px 10px",
// };

// const MesUserSrhMemListProfPhoto = {
//   display: "flex",
//   width: "55px",
//   height: "55px",
//   borderRadius: "55px",
// };

const MessageUserSearchPC = (props) => {
  // ユーザ選択時のイベント
  function MesUserSelectProc(props) {
    const a = props.messageUserDatas.map((messageUserData) => (
      <Table style={styles.mesUserSrhMemList}>
        <tr height={30}>
          <td rowSpan={2} width={50}>
            <img src={messageUserData.profPhoto} style={styles.mesUserSrhMemListProfPhoto} alt="プロフ写真" />
          </td>
          <td align="left">{messageUserData.nickName}</td>
        </tr>
        <tr>
          <td align="left">@{messageUserData.messageUserAccountId}</td>
        </tr>
      </Table>
    ));

    let inputValue = document.getElementById("aaa").value;
    document.getElementById("dispSelectUser").innerHTML = inputValue;
    return a;
  }

  return (
    <div style={styles.mesUserSrhOutline}>
      <div style={{ display: "flex" }}>
        <div style={styles.mesUserSrhDispNameBase}>
          <div style={styles.mesUserSrhDispNameStr}>メッセージする相手を探す</div>
          <MessageUserSearchIcon sx={{ fontSize: "2rem" }} style={{ color: "#000000" }} />
        </div>
        <div style={styles.mesUserSrhSend}>
          <label title="選択したユーザーでメッセージ">
            <IconButton id="sendMessageBtn" onClick={"#"}>
              <MessageSendIcon sx={{ fontSize: "2rem" }} style={{ color: "#000000" }} />
            </IconButton>
          </label>
        </div>
      </div>
      <br />
      <TextField margin="dense" label="ユーザーを検索" fullWidth />
      <br /> <br />
      {/* // TODO 選択したユーザーのオブジェクトを表示する */}
      <TextField id="dispSelectUser" margin="dense" label="選択したユーザー" fullWidth multiline />
      <br /> <br />
      <TextField id="aaa" margin="dense" label="グループ名を入力" fullWidth />
      <br /> <br />
      {props.messageUserDatas.map((messageUserData) => (
        <Button type="submit" onClick={MesUserSelectProc}>
          <Table style={styles.mesUserSrhMemList}>
            <tr height={30}>
              <td rowSpan={2} width={50}>
                <img src={messageUserData.profPhoto} style={styles.mesUserSrhMemListProfPhoto} alt="プロフ写真" />
              </td>
              <td align="left">{messageUserData.nickName}</td>
            </tr>
            <tr>
              <td align="left">@{messageUserData.messageUserAccountId}</td>
            </tr>
          </Table>
        </Button>
      ))}
      <br />
    </div>
  );
};
export default MessageUserSearchPC;
