import { memo } from "react";

import { ThemeProvider, CircularProgress } from "@mui/material";
import theme from "components/MuiTheme";

// その他
import { getDebugger } from "components/Debugger";
import ProfileThumbnail from "../myPage/ProfileThumbnail";
import Pagination from "../common/Pagination";
import { useSearchResult } from "components/SearchResult/SearchResultHooks";
import SearchBar from "../SearchBar/SearchBar";
/** @jsxImportSource @emotion/react */
import { styles } from "./SearchResultStyles";

var debug = getDebugger(false);

const SearchResult = memo((props) => {
  debug("SearchResult is rendering");

  const { searchResultEventIds, isSearchLoading, itemsPerPage, setItemOffset, currentEventIds } = useSearchResult();

  return (
    <ThemeProvider theme={theme}>
      <div css={styles.root}>
        <SearchBar />
        <p css={styles.searchResultNumber}>検索結果:&nbsp;{searchResultEventIds.length}&nbsp;件</p>
        {isSearchLoading ? (
          <p>
            <CircularProgress />
          </p>
        ) : searchResultEventIds.length === 0 ? (
          <p>イベントが見つかりませんでした。</p>
        ) : (
          <ul css={styles.searchResultList}>
            {currentEventIds.map((id) => (
              <li key={id}>
                <ProfileThumbnail eventID={id} />
              </li>
            ))}
          </ul>
        )}

        <Pagination itemsPerPage={itemsPerPage} setItemOffset={setItemOffset} itemCount={searchResultEventIds.length} />
      </div>
    </ThemeProvider>
  );
});

export default SearchResult;
