import React from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const divStyle = {
  top: css`
    text-align: left;
    font-size: 1rem;
    padding: 60px;
  `,
};
const spanStyle = {
  span: css`
    color: #db3531;
  `,
};
const bStyle = {
  title: css`
    text-align: left;
    font-size: 1.3rem;
    margin-top: 50px;
  `,
};

const Policy = () => {
  return (
    <div css={divStyle.top}>
      <h1>
        <span css={spanStyle.span}>Eventer</span>プライバシーポリシー
      </h1>
      <div>
        株式会社Eventer（以下「当社」という）は、当社の提供するサービスの利用者（以下「ユーザー」という）に関する個人情報を含んだ情報
        （以下「ユーザー情報」という）の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」という）を定めます。
      </div>

      <div>
        本ポリシーは、当社が提供する各種サービスごとに定めるプライバシーポリシーおよび利用規約、
        その他関連する規程とともに、当社が提供する全てのサービス（以下「本サービス」という）に適用されるものとします。
      </div>

      <div>
        <b css={bStyle.title}>第1条（個人情報）</b>
      </div>

      <div>
        本ポリシーにおいて，「個人情報」とは、当社が本サービスに関連して利用者から取得する利用者情報のうち、氏名、電子メールアドレス、職業、所在地など、特定の個人を識別することができる情報（当該情報だけでは特定の個人を識別できなくても、他の情報と容易に照合することができ、それにより特定の個人を識別できるものを含みます。）を言います。
      </div>

      <div>
        <b css={bStyle.title}>第2条（当社が本サービスにおいて取得する情報）</b>
      </div>

      <div>
        当社は，本サービスにおいて，利用者から以下の利用者情報を適正かつ公正な手段によって取得することがあります。
      </div>

      <div>
        1.利用者による本サービスの登録，登録内容の変更，利用者からのお問い合わせまたは連絡を通じて当社が直接取得する利用者情報
      </div>

      <div>
        氏名，住所,生年月日，年齢，性別,電子メールアドレス,ログインパスワード,職業,お問い合わせ窓口からの情報,評価または苦情など利用者からのフィードバック情報,その他主催者がアンケート項目として設定した情報
      </div>

      <div>2.利用者による本サービスの利用を通じて自動的に取得する情報</div>

      <div>本サービスの利用状況に関する情報</div>

      <div>本サービスへのアクセス日時</div>

      <div>
        本サービスを閲覧する際に用いたデバイスのアプリ及びブラウザの種類並びに閲覧したページ（利用者が本サービスを利用する前に利用した第三者のウェブサイトを含む）
      </div>

      <div>
        本サービスにアクセスするために使用された機器の情報，ハードウェアのモデル，機器のＩＰアドレス，ＭＡＣアドレスその他の一意の識別子，ＯＳとそのバージョン，選択言語，広告識別子，シリアル番号，機器の動作環境，モバイルネットワーク情報
      </div>

      <div>3.第三者の運営するサービスを通じて取得する情報</div>

      <div>
        利用者が第三者の運営するサービスを通じて当社による利用者の利用者情報の取得を許可した場合，当社は当該利用者の利用者情報を取得することがあります。その場合，本サービスの向上や利用者の関心にあった広告を提供するために，当社が独自にお客様から取得する情報と，第三者から得た情報を適合させることがあります。
      </div>

      <div>
        <b css={bStyle.title}>第3条（利用目的）</b>
      </div>

      <div>当社は、利用者が本サービスを利用することで取得した個人情報を次の目的のために利用させて頂きます。</div>

      <div>本サービスに関する登録の受付，本人確認，利用料金の計算等本サービスの提供，維持，保護及び改善のため</div>

      <div>本サービスに関するご案内，お問い合わせ等への対応のため</div>

      <div>本サービスに関する当社の規約，本ポリシー等（以下，「規約等」と言います。）に違反する行為に対応するため</div>

      <div>本サービスに関する規約等の変更などを通知するため</div>

      <div>当社または第三者の広告の配信または表示のため</div>

      <div>当社の本サービスに関連して，個人を識別できない形式に加工した統計データを作成するため</div>

      <div>当社が本サービスの申込者から取得した個人情報を主催者に提供するため</div>

      <div>
        <b css={bStyle.title}>第4条（第三者提供）</b>
      </div>

      <div>
        1.当社は、利用者が本サービスを利用することに伴い当社が取得した個人情報について、以下に掲げる場合を除き、第三者に対して開示いたしません。
      </div>

      <div>利用者本人の同意がある場合</div>

      <div>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</div>

      <div>合併その他の事由による事業の承継に伴って個人情報が提供される場合</div>

      <div>その他個人情報保護法その他の法令で認められる場合</div>

      <div>
        2.当社は，本サービスの利用に伴い当社が取得した申込者の個人情報を，本サービスの利用に必要な範囲で，当該申込者が申し込んだイベント等の主催者に提供することがあります。申込者は，本サービスを利用することにより，かかる個人情報の第三者提供について同意したものとみなします。
      </div>

      <div>
        3.当社は，有料サービスの利用者が本サービスを利用することに伴い当社が取得した個人情報について，当該利用者に利用料金を請求する目的で、弊社の業務委託先である決済システム会社、クレジット会社および銀行に当該利用者の個人情報を提供することがあります。利用者は，本サービスを利用することにより，かかる個人情報の第三者提供について同意したものとみなします。
      </div>

      <div>
        <b css={bStyle.title}>第5条（個人情報の保護）</b>
      </div>

      <div>
        当社は，利用者情報の紛失，盗用，悪用，不正アクセス，開示，改善及び破壊を防ぐための，合理的な技術的及び物理的措置を講じております。
      </div>

      <div>
        <b css={bStyle.title}>第6条（管理の委託）</b>
      </div>

      <div>
        当社は、取得した個人情報の取扱いを第三者に委託する場合があります。その場合、委託先である当該第三者と必要な契約を締結し、当該第三者が個人情報を適切に管理するよう監督します。
      </div>

      <div>
        <b css={bStyle.title}>第7条（個人情報の開示）</b>
      </div>

      <div>
        当社は、利用者から個人情報保護法の定めに基づき個人情報の開示を求められたときは，以下の場合を除き，利用者本人からの請求であることを確認のうえ，利用者に対し，遅滞なく開示を行います。開示を求められた当該個人情報が存在しないときは，その旨を当該開示請求者に通知いたします。なお，個人情報の開示につきましては，手数料（１件あたり１０００円）を頂戴いたしますので，ご了承ください。
      </div>

      <div>個人情報保護法その他の法令により，当社が開示の義務を負わない場合</div>

      <div>利用者本人又は第三者の生命・身体・財産その他の権利を害するおそれがある場合</div>

      <div>その他当該個人情報の開示が不適当であると当社が判断した場合</div>

      <div>
        <b css={bStyle.title}>第8条（個人情報の訂正等）</b>
      </div>

      <div>
        当社は，利用者から，⑴個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合，及び⑵あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により，個人情報保護法の定めに基づきその利用の停止を求められた場合には，利用者本人からの請求であることを確認のうえ，遅滞なく必要な調査を行い，その結果に基づき，個人情報の内容の訂正または利用停止を行い，その旨を利用者に通知します。なお，当社が合理的な理由に基づいて訂正または利用停止を行わない旨の決定をしたときは，利用者に対してその旨を通知いたします。
      </div>

      <div>
        <b css={bStyle.title}>第9条（クッキーの利用について）</b>
      </div>

      <div>
        当社は、サービスの提供、分析、統計データの取得およびお客様に適切な広告を配信するためにCookie（これに類似する技術を含むものとします。）を使用することがあります。ただし，このCookieには，利用者を特定あるいは識別する情報は一切含まれておりません。
      </div>

      <div>
        利用者がCookieを利用した情報の取得を望まれない場合は、インターネット閲覧ソフト（ブラウザ）の設定により、Cookieの受取りを拒否することができます。ブラウザの設定方法については各ソフト製造元へお問い合わせください。なお、Cookieの受取りを拒否された場合、ログイン保持機能等、当社が提供する本サービスの一部がご利用できなくなることがありますのでご了承ください。
      </div>

      <div>
        当社は，第三者が提供する広告配信サービス及びFacebookログイン機能並びにグーグルアナリティクスを利用しているため，各サービスの提供者がCookie等によって利用者の当社Webサイトへの訪問履歴情報等を取得・利用する場合があります。その場合，当該サービス提供者によって取得されたCookieは，当該サービス提供者のプライバシーポリシーに従って取り扱われることになります。
      </div>

      <div>
        利用者は，オプトアウトの手続を行うことで，Cookieを利用した広告配信サービス等を無効化することが可能になります。
      </div>

      <div>
        <b css={bStyle.title}>第10条（免責）</b>
      </div>

      <div>次に掲げる場合には、第三者による利用者の個人情報の取得に関し、当社は一切の責任を負いません。</div>

      <div>利用者ご本人が本サービスの機能またはその他別の手段を用いて第三者に利用者の個人情報を提供した場合</div>

      <div>利用者が本サービスの利用に伴い利用者の判断で入力した情報の組み合わせ等により、個人が識別できた場合</div>

      <div>個人情報を含むコンテンツをアップロードした場合</div>

      <div>
        <b css={bStyle.title}>第11条（プライバシーポリシーの改定について）</b>
      </div>

      <div>
        1
        弊社は、会員情報の取扱いに関する運用状況を適宜見直して、継続的な改善に努めるものとします。そのため、必要に応じて、随時本ポリシーを変更することがあります。
      </div>

      <div>
        2
        変更後の本ポリシーについては、会員等に対し、本サイトでの掲示その他分かりやすい方法により告知します。ただし、法令上会員等の同意が必要となるような内容の変更を行うときは、別途弊社が定める方法により、会員等の同意を取得します。
      </div>

      <div>
        <b css={bStyle.title}>第12条（お問い合わせ窓口）</b>
      </div>

      <div>
        本ページ記載事項は、当社、利用者との間において効力を持つものであり、本ページのリンク先を含む第三者に適用されるものではありません。本ページに関するお問い合わせは下記までお願いいたします。
      </div>

      <div>
        株式会社Eventer Email: Eventer20220111@gmail.com （なお、受付時間は、平日9時から18時までとさせていただきます 。
        ）
      </div>

      <div>・2022年9月7日制定</div>
    </div>
  );
};

export default Policy;
