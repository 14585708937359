import { useCallback, useState } from "react";
import { EventStatus } from "models";
import { Event } from "API";
import { Location } from "react-router-dom";

/**
 * イベント操作に必要な値の型
 * // HACK Union型で必要な要素を結合したい
 */
export type eventValuesType = {
  thumbnailImageFile?: File;
  thumbnailImageKey?: Event["thumbnailImageKey"];
  mainCategory: string;
  name: string;
  hashtags?: string[] | null;
  userID: string;
  prefecture: string;
  city: string;
  ticketPrice: number | string;
  participant: number;
  // そのうち不要になる
  participantIcons: Record<string, string>[];
  overview?: string | null;
  eventID?: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  postCode1: string;
  postCode2: string;
  address: string;
  ticketName?: string;
  ticketSalesNumber?: number;
  status: string;
};

/**
 * イベントのStateに関するのHooks
 */
export const useEventState = () => {
  /** イベント登録に必要な値 */
  const [eventValues, setEventValues] = useState<eventValuesType>({
    thumbnailImageFile: undefined,
    thumbnailImageKey: "",
    mainCategory: "",
    name: "",
    hashtags: [],
    userID: "",
    prefecture: "",
    city: "",
    ticketPrice: 0,
    participant: 0,
    participantIcons: [],
    overview: "",
    eventID: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    postCode1: "",
    postCode2: "",
    address: "",
    ticketName: "",
    ticketSalesNumber: 0,
    status: "",
  });

  /** プレビュー画面から遷移した時の初期値設定 */
  const setEventValuesFromPreview = useCallback(
    (location: Location["state"]) => {
      setEventValues({
        thumbnailImageFile: location.currentState.thumbnailImageFile,
        thumbnailImageKey: location.currentState.thumbnailImageKey,
        mainCategory: location.currentState.NewCategoryTarget.mainCategory,
        name: location.currentState.NewDetails.eventName,
        hashtags: [
          location.currentState.NewCategoryTarget.hashtag1,
          location.currentState.NewCategoryTarget.hashtag2,
          location.currentState.NewCategoryTarget.hashtag3,
          location.currentState.NewCategoryTarget.hashtag4,
        ],
        userID: location.userID,
        startDate: location.currentState.NewDetails.startDate,
        startTime: location.currentState.NewDetails.startTime,
        endDate: location.currentState.NewDetails.endDate,
        endTime: location.currentState.NewDetails.endTime,
        prefecture: location.currentState.NewDetails.prefectures,
        city: location.currentState.NewDetails.cities,
        address: location.currentState.NewDetails.address,
        postCode1: location.currentState.NewDetails.postCode1,
        postCode2: location.currentState.NewDetails.postCode2,
        ticketPrice: location.currentState.NewTicketSupport.ticketPrice,
        participant: location.currentState.NewTicketSupport.ticketSalesNumber,
        participantIcons: [],
        overview: location.currentState.NewOverview.overview,
        status: EventStatus.AVAILABLE,
      });
    },
    [setEventValues]
  );

  return {
    eventValues,
    setEventValues,
    setEventValuesFromPreview,
  };
};
