import React from "react";

// 外部パッケージ
import useMedia from "use-media";

// その他
import theme from "components/MuiTheme";
import HeaderSP from "components/HeaderSP";
import HeaderPC from "components/HeaderPC";

const Header = (props) => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  return <div style={{ backgroundColor: theme.palette.primary.main }}>{isWide ? <HeaderSP /> : <HeaderPC />}</div>;
};

export default Header;
