import React, { useCallback, useMemo } from "react";

// 外部パッケージ
import { useForm, Controller } from "react-hook-form";
import useMedia from "use-media";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRecoilState } from "recoil";
import { userInputDataAtom } from "../common/UserInputDataAtom";

// MUI
import { TextField, MenuItem, Box, Button, InputAdornment } from "@mui/material";

// その他
import theme from "components/MuiTheme";
import {
  mainCategories,
  // gender,
  // ages,
  // jobs
} from "components/common/SelectValues";
import { applicationConst } from "utils/constants";

const styles = {
  span: css`
    display: flex;
    margin: 8px 0 0 13px;
    font-size: 14px;
    font-weight: bold;
    opacity: 0.8;
  `,
  div: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 13px;
  `,
  h1: css`
    padding-top: 110px;
    font-size: 48px;
    color: #fff;
    text-shadow: 0 0 0.2em rgba(0, 0, 0, 1);
    height: 50%;
  `,
};

const NewCategoryTarget = (props) => {
  const [userInputData, setUserInputData] = useRecoilState(userInputDataAtom);

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      ...userInputData.NewCategoryTarget,
      // HACK この時点で配列(hashtags)にしたい
      // gender: "",
      // age: "",
      // job: "",
    },
  });

  const onSubmit = (action) => {
    if (action === "back") {
      props.handleBack();
    } else {
      props.changeBtnStatus();
      props.handleNext();
    }
    const data = getValues();
    setUserInputData({ ...userInputData, NewCategoryTarget: data });
  };

  const inputHashtagView = useCallback(
    (index) => {
      return (
        <div css={styles.div} key={`inputHashtag_${index}`}>
          <Controller
            control={control}
            name={`hashtag${index}`}
            render={({ field }) => (
              <TextField
                {...field}
                id={`hashtag${index}`}
                size="small"
                color="secondary"
                margin="dense"
                InputProps={{
                  style: { fontSize: 13 },
                  startAdornment: (
                    <div style={{ width: 11 }}>
                      <InputAdornment position="start">
                        <div style={{ fontSize: 14 }}>#</div>
                      </InputAdornment>
                    </div>
                  ),
                }}
                InputLabelProps={{ style: { fontSize: 13 } }}
              />
            )}
          />
        </div>
      );
    },
    [control]
  );

  const inputHashtagsView = useMemo(() => {
    const hashtags = [];
    for (let i = 1; i <= applicationConst.maximumHashtags; i++) {
      hashtags.push(inputHashtagView(i));
    }
    return <>{hashtags}</>;
  }, [inputHashtagView]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="mainCategory"
        render={({ field }) => (
          <TextField
            {...field}
            select
            id="mainCategory"
            label="カテゴリー"
            // HACK 次へボタンを押下時にハッシュタグが一つも入っていなければエラーとする
            // required
            variant="outlined"
            color="secondary"
            margin="normal"
            fullWidth
            InputLabelProps={{ style: { fontSize: 14 } }}
          >
            {mainCategories.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <div style={{ fontSize: 14, textAlign: "left" }}>{option.label}</div>
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <span css={styles.span}>#ハッシュタグ(4つまで)</span>
      {inputHashtagsView}
      {/* <span
        style={{
          display: "flex",
          margin: "8px 0 0 13px",
          fontSize: 14,
          fontWeight: "bold",
          opacity: 0.8,
        }}
      >
        ターゲット
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 13px",
        }}
      >
        <Controller
          control={control}
          name="gender"
          render={({ field }) => (
            <TextField
              {...field}
              select
              id="gender"
              label="性別"
              variant="standard"
              color="secondary"
              margin="normal"
              InputLabelProps={{ shrink: true }}
              style={{ width: 55 }}
            >
              {gender.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <div style={{ fontSize: 14 }}>{option.label}</div>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          control={control}
          name="age"
          render={({ field }) => (
            <TextField
              {...field}
              select
              id="age"
              label="年齢"
              variant="standard"
              color="secondary"
              margin="normal"
              InputLabelProps={{ shrink: true }}
              style={{ width: 55 }}
            >
              {ages.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <div style={{ fontSize: 14 }}>{option.label}</div>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          control={control}
          name="job"
          render={({ field }) => (
            <TextField
              {...field}
              select
              id="job"
              label="職業"
              variant="standard"
              color="secondary"
              margin="normal"
              InputLabelProps={{ shrink: true }}
              style={{ width: 150 }}
            >
              {jobs.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <div style={{ fontSize: 14 }}>{option.label}</div>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </div> */}
      <Box sx={{ mb: 2 }}>
        <div>
          <Button onClick={() => onSubmit("back")} sx={{ mt: 1, mr: 1 }}>
            戻る
          </Button>
          <Button type="submit" disabled={props.status} variant="contained" sx={{ mt: 1, mr: 1 }}>
            次へ
          </Button>
        </div>
      </Box>
    </form>
  );
};

export default NewCategoryTarget;

const NewCategoryTargetTitle = () => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });

  return (
    <div>
      {!isWide && (
        <h1 css={styles.h1}>
          カテゴリー/ターゲットを絞る
          <br />
          <br />
          イベントの情報を届けたい人
          <br />
          を設定しましょう
        </h1>
      )}
    </div>
  );
};

export { NewCategoryTargetTitle };
