import React from "react";

// MUI
import { Button, Table } from "@mui/material";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// β版用データ
import profPhoto1 from "../../components/img/message/S__247865346.jpg";
import profPhoto2 from "../../components/img/message/eventer_logo.png";
import profPhoto3 from "../../components/img/message/S__247865348.jpg";
import profPhoto4 from "../../components/img/message/S__247865349.jpg";
import profPhoto5 from "../../components/img/message/S__247865351.jpg";
import profPhoto6 from "../../components/img/message/S__247865353.jpg";
import profPhoto7 from "../../components/img/message/S__247865354.jpg";
import profPhoto8 from "../../components/img/message/S__247865352.jpg";
import profPhoto9 from "../../components/img/message/S__247865350.jpg";
import profPhoto10 from "../../components/img/message/S__247865355.jpg";
import profPhoto11 from "../../components/img/message/人フリー素材.png";
import profPhoto12 from "../../components/img/message/Eventerユーザー1.png";

const styles = {
  messageUserDataGrid: css`
    display: "table-cell",
    backgroundColor: "transparent",
    height: "40px",
  `,
  profPhotoStyle: css`
    display: "flex",
    width: "40px",
    height: "40px",
    borderRadius: "40px",
    verticalAlign: "middle",
`,
};

// const MessageUserDataGrid = styled(Grid)({
//   display: "table-cell",
//   backgroundColor: "transparent",
//   // width: '394px',
//   height: "40px",
// });

// const profPhotoStyle = {
//   display: "flex",
//   width: "40px",
//   height: "40px",
//   borderRadius: "40px",
//   verticalAlign: "middle",
// };

// β版用データ
const getProfPhoto = (messageUserAccountId) => {
  let url = "";
  if (messageUserAccountId === "yamamoto_hiroto") url = profPhoto1;
  if (messageUserAccountId === "Eventer_inc") url = profPhoto2;
  if (messageUserAccountId === "chika") url = profPhoto3;
  if (messageUserAccountId === "saori") url = profPhoto4;
  if (messageUserAccountId === "take1204") url = profPhoto5;
  if (messageUserAccountId === "yuta0928") url = profPhoto6;
  if (messageUserAccountId === "hanako") url = profPhoto7;
  if (messageUserAccountId === "7nana7mi3") url = profPhoto8;
  if (messageUserAccountId === "12YukiMaru34") url = profPhoto9;
  if (messageUserAccountId === "ko-dai_0123") url = profPhoto10;
  if (messageUserAccountId === "SaT0Sh123") url = profPhoto11;
  if (messageUserAccountId === "SuzukiTaro_AB...") url = profPhoto12;

  return <img src={url} style={styles.profPhotoStyle} alt="プロフ写真" />;
};

const MessageUserDataSP = (props) => {
  return (
    <styles.messageUserDataGrid>
      <Button>
        <Table id={props.messageRoomId} style={{ color: "black", textTransform: "none" }}>
          <tr height={20}>
            <td rowSpan={3}>{getProfPhoto(props.messageUserAccountId)}</td>
            <th width={125} align="left">
              {props.nickName}
            </th>
            <td width={115} align="left">
              @{props.messageUserAccountId}
            </td>
            <td width={150} align="right">
              {props.lastMessageDate}
            </td>
          </tr>
          <tr height={20}>
            <td colSpan={3} align="left">
              {props.messageContent}
            </td>
          </tr>
        </Table>
      </Button>
    </styles.messageUserDataGrid>
  );
};

export default MessageUserDataSP;
