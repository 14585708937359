// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const TicketStatus = {
  "BEFORE_SALE": "BEFORE_SALE",
  "ON_SALE": "ON_SALE",
  "SOLD_OUT": "SOLD_OUT",
  "END_OF_SALE": "END_OF_SALE"
};

const TicketPurchaseStatus = {
  "AWATING_PAYMENT": "AWATING_PAYMENT",
  "VERIFIED": "VERIFIED",
  "CANCELED": "CANCELED"
};

const TicketPurchaseHistoryOperation = {
  "PURCHASE": "PURCHASE",
  "PAY": "PAY",
  "CANCEL": "CANCEL"
};

const EventEntryStatus = {
  "AWATING_PAYMENT": "AWATING_PAYMENT",
  "VERIFIED": "VERIFIED",
  "CLOSED": "CLOSED",
  "EVENT_CANCELED": "EVENT_CANCELED",
  "DECLINED": "DECLINED"
};

const EventStatus = {
  "AVAILABLE": "AVAILABLE",
  "CLOSED": "CLOSED",
  "COMPLETED": "COMPLETED",
  "CANCELED": "CANCELED"
};

const UserStatus = {
  "VERIFIED": "VERIFIED",
  "DELETED": "DELETED"
};

const { Message, MessageMember, MessageGroup, Ticket, TicketPurchase, Event, EventEntry, User, Space, TicketPurchaseHistory, Venue, AvailableFacility, SpaceCategory, SpacePicture } = initSchema(schema);

export {
  Message,
  MessageMember,
  MessageGroup,
  Ticket,
  TicketPurchase,
  Event,
  EventEntry,
  User,
  Space,
  TicketStatus,
  TicketPurchaseStatus,
  TicketPurchaseHistoryOperation,
  EventEntryStatus,
  EventStatus,
  UserStatus,
  TicketPurchaseHistory,
  Venue,
  AvailableFacility,
  SpaceCategory,
  SpacePicture
};