import React, { createContext, useContext, useState } from "react";
// import LoggerService, { LogLevel } from './LoggerService';

enum LogLevel {
  DEBUG,
  INFO,
  WARN,
  ERROR,
}

class LoggerService {
  logLevel: LogLevel;

  constructor(logLevel: LogLevel) {
    this.logLevel = logLevel;
  }

  debug(message: any, ...optionalParams: any[]) {
    if (this.logLevel <= LogLevel.DEBUG) {
      console.debug(message, ...optionalParams);
    }
  }

  info(message: any, ...optionalParams: any[]) {
    if (this.logLevel <= LogLevel.INFO) {
      console.info(message, ...optionalParams);
    }
  }

  warn(message: any, ...optionalParams: any[]) {
    if (this.logLevel <= LogLevel.WARN) {
      console.warn(message, ...optionalParams);
    }
  }

  error(message: any, ...optionalParams: any[]) {
    if (this.logLevel <= LogLevel.ERROR) {
      console.error(message, ...optionalParams);
    }
  }
}

const LoggerContext = createContext<LoggerService | undefined>(undefined);

type ProvideLoggerProps = {
  children: React.ReactNode;
};

export const ProvideLogger: React.FC<ProvideLoggerProps> = ({ children }) => {
  const [logger] = useState(new LoggerService(LogLevel.INFO)); // Set initial log level here

  return <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>;
};

export const useLogger = () => {
  const context = useContext(LoggerContext);
  if (context === undefined) {
    throw new Error("useLogger must be used within a ProvideLogger");
  }
  return context;
};
