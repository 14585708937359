import React, { useState } from "react";

// 外部パッケージ
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

//  MUI
import { AppBar, Avatar, Divider, ListItemIcon, MenuItem, Menu, IconButton } from "@mui/material";
import List from "@mui/material/List";
import Logout from "@mui/icons-material/Logout";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import CreateIcon from "@mui/icons-material/Create";
import SearchIcon from "@mui/icons-material/Search";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
// import RateReviewIcon from "@mui/icons-material/RateReview";
// import EmailIcon from "@mui/icons-material/Email";
// import StoreIcon from "@mui/icons-material/Store";
// import Badge from "@mui/material/Badge";

// その他
import theme from "components/MuiTheme";
import headerLinkList from "components/HeaderLinkList";
import { applicationConst } from "utils/constants";
import { useAuth } from "components/auth/AuthCommon";
import { UserIcon } from "components/common/UserIcon";
import { PeopleIcon } from "components/common/Icons";
import LogoImg from "./img/eventer_logo_white_e.png";

const logoWidth = 57;
const iconWidth = 45;

const styles = {
  appBar: css`
    background: ${theme.palette.primary.main};
    height: 55px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    color: #000000;
    align-self: center;
    align-items: center;
    box-shadow: 0px 0px 0px 0px;
    padding-right: 0px;

    & > ul > a {
      padding-left: 20px;
      padding-right: 20px;
    }

    & .logo {
      width: ${logoWidth}px;
      height: ${logoWidth}px;
    }

    & .icon {
      width: ${iconWidth}px;
      height: ${iconWidth}px;
    }

    & > :first-of-type {
      padding-left: 20px;
      padding-right: 20px;
    }

    & > :last-of-type {
      padding-left: 20px;
      padding-right: 20px;
    }
  `,

  link: css`
    font-weight: 300;
    color: white;
  `,
};

const Header = (props) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const linkStyle = {
    fontSize: 20,
    fontWeight: 200,
    marginRight: -9,
    color: "white",
    textDecoration: isHover ? "underline white" : "underline #DB3531",
  };
  const navigate = useNavigate();
  const toProfile = () => {
    navigate("/profile");
  };
  // const toMessage = () => {
  //   navigate("/message");
  // };
  const toUsage = () => {
    navigate("/usage");
  };
  const toFindEvent = () => {
    navigate("/");
  };
  const toAddEvent = () => {
    navigate("/addEvent");
  };
  const toFindSpace = () => {
    navigate("/findSpace");
  };
  const toAddSpace = () => {
    navigate("/addSpace");
  };
  // const toRate = () => {
  //   navigate("/rateReview");
  // };
  const onSignOutPressed = async () => {
    try {
      await auth.signOut();
      navigate("/");
    } catch (e) {
      // 認証コンポーネント側でエラーメッセージ表示
    }
  };
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  // const handleClick3 = (event) => {
  //   setAnchorEl3(event.currentTarget);
  // };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const renderEvent = (
    <Menu
      anchorEl={anchorEl2}
      id="account-menu2"
      open={open2}
      onClose={handleClose2}
      onClick={handleClose2}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 45,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={toFindEvent}>
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        イベント検索
      </MenuItem>
      <Divider />
      <MenuItem onClick={toAddEvent}>
        <ListItemIcon>
          <CreateIcon />
        </ListItemIcon>
        イベント登録
      </MenuItem>
    </Menu>
  );

  const renderSpace = (
    <Menu
      anchorEl={anchorEl3}
      id="account-menu3"
      open={open3}
      onClose={handleClose3}
      onClick={handleClose3}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 38,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={toFindSpace}>
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        スペース検索
      </MenuItem>
      {/* DividerとMenuのmarginを0にして、MenuItemのMarginで幅を調整するとマウスホバー時のアニメーションが綺麗になるはず。 */}
      <Divider />
      <MenuItem onClick={toAddSpace}>
        <ListItemIcon>
          <AddBusinessIcon />
        </ListItemIcon>
        スペース登録
      </MenuItem>
    </Menu>
  );

  // HACK [DB]メッセージの新着バッチ対応
  const renderMenu = (
    <Menu
      anchorEl={anchorEl1}
      id="account-menu1"
      open={open1}
      onClose={handleClose1}
      onClick={handleClose1}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 25,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={toProfile}>
        <ListItemIcon>
          <Avatar />
        </ListItemIcon>
        アカウント
      </MenuItem>
      <Divider />
      {/* <MenuItem onClick={toMessage}>
        <ListItemIcon>
          <Badge badgeContent={2} color="error" size="small">
            <EmailIcon  />
          </Badge>
        </ListItemIcon>
        メッセージ
      </MenuItem>
      <Divider /> */}
      <MenuItem onClick={toUsage}>
        <ListItemIcon>
          <AutoStoriesIcon />
        </ListItemIcon>
        利用ガイド
      </MenuItem>
      <Divider />
      {/* <MenuItem onClick={toRate}>
        <ListItemIcon>
          <RateReviewIcon />
        </ListItemIcon>
        レビュー
      </MenuItem>
      <Divider /> */}
      <MenuItem onClick={onSignOutPressed}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        ログアウト
      </MenuItem>
    </Menu>
  );

  const auth = useAuth();
  const condition = auth.isAuthenticated
    ? applicationConst.displayCondition.authenticated
    : applicationConst.displayCondition.unauthenticated;

  return (
    <AppBar css={styles.appBar} position="static">
      <Link to="/">
        <img src={LogoImg} className="logo" alt="ロゴ"></img>
      </Link>
      <IconButton
        onClick={handleClick2}
        size="small"
        sx={{ ml: 2, color: "#FFFFFF" }}
        aria-controls={open2 ? "account-menu2" : undefined}
        aria-haspopup="true"
        aria-expanded={open2 ? "true" : undefined}
      >
        <PeopleIcon className="icon" />
      </IconButton>
      {/* <IconButton
        onClick={handleClick3}
        size="small"
        sx={{ ml: 2, color: "#FFFFFF" }}
        aria-controls={open3 ? "account-menu3" : undefined}
        aria-haspopup="true"
        aria-expanded={open3 ? "true" : undefined}
      >
        <StoreIcon className="icon" />
      </IconButton> */}

      <List>
        {headerLinkList
          .filter(
            (link) =>
              link.displayCondition === applicationConst.displayCondition.always || link.displayCondition === condition
          )
          .map((link) =>
            link.key === "profile" ? (
              <IconButton
                onClick={handleClick1}
                sx={{ ml: 2 }}
                size="55"
                aria-controls={open1 ? "account-menu1" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                key={link.key}
              >
                <UserIcon userID={auth.userID} size={45} />
              </IconButton>
            ) : (
              <Link
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter}
                style={linkStyle}
                to={link.url}
                key={link.key}
              >
                {link.value}
              </Link>
            )
          )}
      </List>
      {renderEvent}
      {renderSpace}
      {renderMenu}
    </AppBar>
  );
};
export default Header;
