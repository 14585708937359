import React, { useCallback, useEffect, useMemo } from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

// MUI
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// import PeopleIcon from "@mui/icons-material/People";
// import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
// import Checkbox from "@mui/material/Checkbox";
// import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
// import Favorite from "@mui/icons-material/Favorite";
// import { pink } from "@mui/material/colors";
// import Rating from "@mui/material/Rating";

// AWS
import { API, graphqlOperation } from "aws-amplify";

// その他
import theme from "components/MuiTheme";
import { getEvent } from "graphql/queries";
import { UserIcon } from "components/common/UserIcon";
import { useAuth } from "components/auth/AuthCommon";
import { locale } from "components/common/SelectValues";
import { convertValueToLabel } from "components/common/Utils";
import { ThumbnailImage } from "components/common/ThumbnailImage";
import { getDebugger } from "./Debugger";
import Hashtag from "./common/parts/Hashtag/Hashtag";
import MainCategoryLabel from "./common/parts/MainCategoryLabel/MainCategoryLabel";

// const label = { inputProps: { "aria-label": "Checkbox demo" } };

// ここからスタイル↓
const styles = {
  thumbnailDiv: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 330px;
    height: 450px;
    background-color: #f1f1f1;
    text-align: left;
    margin: 5px;
    border-radius: 4px;
    position: relative;
    filter: drop-shadow(0px 0px 3px ${theme.palette.grey[200]});
  `,

  zoomBox: css`
    cursor: pointer;
    overflow: hidden;
    width: 330px;
  `,

  thumbnailImg: css`
    width: 330px;
    height: 330px;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
    display: block;
    transition-duration: 0.5s;
    :hover {
      transform: scale(1.1);
    }
  `,

  eventRow1: css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-inline: 10px;
    box-sizing: border-box;
    min-height: 30px;
    padding-top: 5px;
  `,

  eventName: css`
    font-weight: bold;
    display: block;
    width: 210px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  `,

  eventRow2: css`
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
    align-items: center;
    padding-inline: 10px;
    margin-right: auto;
  `,

  eventRow3: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-inline: 10px;
    margin-bottom: 5px;
  `,

  eventRow3Col2: css`
    display: flex;
    flex-direction: column;
  `,

  eventRow3Col2Row1: css`
    display: flex;

    div {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
    }

    & :not(:last-child) {
      margin-right: 0.5rem;
    }
  `,

  eventRow3Col2Row2: css`
    display: flex;
    flex-direction: row;

    div {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
    }

    & div:not(:last-of-type) {
      margin-right: 0.5rem;
    }
  `,

  plannerEvaluation: css`
    display: flex;
    align-items: center;
  `,
};

// const favariteIcon = {
//   display: "block",
//   paddingTop: "0",
//   defaultColor: "default",
//   color: pink[600],
//   // HACK Warningがでている。適用されていないみたいなので削除でOK？
//   // Unsupported style property &.Mui-checked. Did you mean &.MuiChecked?
//   "&.Mui-checked": {
//     color: pink[600],
//     paddingRight: "auto",
//   },
//   position: "absolute",
//   top: "2%",
//   right: "2%",
// };

var debug = getDebugger(false);

const Thumbnail = (props) => {
  const auth = useAuth();
  const navigate = useNavigate();

  // 職業、住所のプルダウン
  const [values, setValues] = React.useState({
    name: "",
    thumbnailImageKey: "",
    mainCategory: "",
    overview: "",
    // status:"",
    startDateTime: "",
    endDateTime: "",
    // EventEntries: "",
    hashtags: [],
    // Tickets:"",
    userID: "",
    postCode1: "",
    postCode2: "",
    prefecture: "",
    city: "",
    address: "",
  });

  const toEvent = useCallback(() => {
    navigate(`/event/${props.eventID}`, { state: { currentState: values, userID: auth.userID } });
  }, [auth.userID, navigate, props.eventID, values]);

  // サムネイルを作成
  const ThumbnailView = useMemo(() => {
    return <ThumbnailImage thumbnailImageKey={values.thumbnailImageKey} size={330} onClickEvent={toEvent} enableZoom />;
  }, [toEvent, values.thumbnailImageKey]);

  // 初期処理
  useEffect(() => {
    const runAPI = async () => {
      if (props.eventID === "") return;
      const result = await API.graphql(graphqlOperation(getEvent, { id: props.eventID }));
      debug(result);
      setValues({
        name: result.data.getEvent.name,
        thumbnailImageKey: result.data.getEvent.thumbnailImageKey,
        overview: result.data.getEvent.overview,
        mainCategory: result.data.getEvent.mainCategory,
        postCode1: result.data.getEvent.postCode1,
        postCode2: result.data.getEvent.postCode2,
        prefecture: result.data.getEvent.prefecture,
        city: result.data.getEvent.city,
        address: result.data.getEvent.address,
        startDateTime: result.data.getEvent.startDate + " " + result.data.getEvent.startTime,
        endDateTime: result.data.getEvent.endDate + " " + result.data.getEvent.endTime,
        userID: result.data.getEvent.userID,
        hashtags: result.data.getEvent.hashtags,
      });
    };
    runAPI().catch((error) => {
      debug("イベント情報の取得に失敗しました");
    });
  }, [props.eventID]);

  return (
    <div css={styles.thumbnailDiv}>
      {ThumbnailView}
      {/* <Checkbox style={favariteIcon} {...label} icon={<FavoriteBorder />} checkedIcon={<Favorite />} /> */}
      <div css={styles.eventRow1}>
        <div title={values.name} onClick={toEvent} css={styles.eventName}>
          {values.name}
        </div>
        <MainCategoryLabel mainCategory={values.mainCategory} options={{ enabledLink: true }} />
      </div>
      <div css={styles.eventRow2}>
        <Hashtag hashtagList={values.hashtags} options={{ background: "white", size: "small", enabledLink: true }} />
      </div>
      <div css={styles.eventRow3}>
        <div css={styles.eventRow3Col2}>
          <div css={styles.eventRow3Col2Row1}>
            <div>
              <EventIcon sx={{ color: "#999999" }} fontSize={"small"} />
              {values.startDateTime}
            </div>
            <div>
              <LocationOnIcon sx={{ color: "#999999" }} fontSize={"small"} />
              {convertValueToLabel(locale, values.prefecture)}
              {values.city}
            </div>
            {/* // TODO チケット情報を表示する
             <div>
              <PeopleIcon sx={{ color: "#999999" }} fontSize={"small"} />
              // FIXME valuesから取得して設定しましょう
              10/15
            </div> */}
          </div>
          <div css={styles.eventRow3Col2Row2}>
            {/* // TODO チケット情報を表示する
            <div>
              <CurrencyYenIcon sx={{ color: "#999999" }} fontSize={"small"} />
              FIXME valuesから取得して設定しましょう
              2,000円
            </div> */}
            <div>
              <UserIcon
                userID={values.userID}
                size={25}
                // options={{ labeled: true, enableLink: true, rated: true }}
                options={{ labeled: true, enableLink: true }}
                alt="プロフィール"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thumbnail;
