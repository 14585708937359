import { useCallback } from "react";
import { useS3 } from "hooks/useS3Hooks";
import { S3_PREFIX } from "utils/constants";
import { formatDate } from "components/common/Utils";

/**
 * イベントのS3に関するHooks
 */
export const useEventS3 = () => {
  const { putFileToS3, removeFileFromS3, isS3Loading } = useS3();

  /**
   * Uploads a thumbnail image to S3.
   *
   * This function generates a unique filename based on the current date and time (in UTC) and the
   * provided thumbnail name. It fetches the thumbnail from the provided URL, creates a File object,
   * and uploads it to S3 using the `putFileToS3` function with a specified prefix for thumbnails.
   * @param thumbnailImageFile The data of the thumbnail.
   * @returns {Promise<string>} A Promise that resolves to an empty string if the thumbnail Blob is null,
   *                            not an image, or the result of the `putFileToS3` function.
   */
  const uploadThumbnailImageToS3 = useCallback(
    async (thumbnailImageFile: File) => {
      // Generate a unique file name based on the date and time (if not possible, consider adding the file type)
      const dateTime = formatDate(new Date(), { isUTC: true, hasTime: true }); // YYYYMMDDhhmmss
      const s3FileName = dateTime + thumbnailImageFile.name;

      if (thumbnailImageFile == null || !thumbnailImageFile.type.startsWith("image/")) {
        throw new Error("ファイルの変換に失敗しました");
      }

      return putFileToS3(s3FileName, thumbnailImageFile, { prefix: S3_PREFIX.THUMBNAIL, maxWidthOrHeight: 800 });
    },
    [putFileToS3]
  );

  /**
   * Removes a thumbnail image from S3.
   *
   * @param thumbnailImageKey   The key of the thumbnail. If it has a prefix, need to be contained a prefix.
   *                            In the case of event thumbnail, its prefix has been already contained in the key.
   * @returns {Promise<string>} A Promise object that returns a success message on success, or an error on failure.
   */
  const removeThumbnailImageFromS3 = useCallback(
    async (thumbnailImageKey: string) => {
      return removeFileFromS3(thumbnailImageKey);
    },
    [removeFileFromS3]
  );

  return {
    uploadThumbnailImageToS3,
    removeThumbnailImageFromS3,
    isS3Loading,
  };
};
