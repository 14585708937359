import React from "react";

// 外部パッケージ
import { useNavigate, useLocation } from "react-router-dom";

// MUI
import {
  ThemeProvider,
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// その他
import theme from "components/MuiTheme";
import LogoImg from "components/img/Eventer_logo_red.png";
import { useAuth } from "components/auth/AuthCommon";

const description = {
  textAlign: "left",
};

// ここからメインのSignUpコンポーネント
function ForgotPasswordSubmit() {
  const [loading, setLoading] = React.useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const username = useLocation().state.username;
  const onForgotPasswordSubmit = async (event) => {
    if (auth.isLoading) {
      return;
    }
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const result = await auth.forgotPasswordSubmit(username, data.get("code"), data.get("password"));

    if (result.success) {
      navigate("/forgotPasswordCompleted");
    } else {
      alert(result.message);
    }
    setLoading(false);
  };
  const [values, setValues] = React.useState({});
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowPassword1 = () => {
    setValues({
      ...values,
      showPassword1: !values.showPassword1,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const handleChange1 = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const [state, setState] = React.useState({
    password: "",
    cPassword: "",
  });

  const [passMatch, setPassMatch] = React.useState(true);

  React.useEffect(() => {
    validatePassword();
  });

  const validatePassword = () => {
    state.password === state.cPassword ? setPassMatch(true) : setPassMatch(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: theme.palette.grey[200],
        }}
      >
        <Box
          component="form"
          onSubmit={onForgotPasswordSubmit}
          noValidate
          style={{
            marginTop: 20,
            marginBottom: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: " 0 30px 30px 30px",
            background: "white",
            borderRadius: 5,
            width: 284,
          }}
        >
          <img src={LogoImg} style={{ width: 150, height: 150 }} alt="logo" />
          <Typography
            component="h1"
            variant="h1"
            style={{
              width: 300,
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            パスワードの再設定
          </Typography>
          <div style={description}>
            {username} 宛に認証コードを送信しました。
            <br />
            メールに記載されている認証コードと
            <br />
            新しいパスワードを入力してください。
          </div>
          <TextField
            autoFocus
            color="secondary"
            margin="normal"
            autoComplete="code"
            name="code"
            required
            fullWidth
            id="code"
            label="認証コード"
          />
          <FormControl sx={{ paddingTop: 1.65, width: "30ch" }} variant="outlined">
            <InputLabel sx={{ paddingTop: 1.65 }} color="secondary" htmlFor="outlined-adornment-password">
              パスワード
            </InputLabel>
            <OutlinedInput
              aria-label="Password"
              className="form-control"
              color="secondary"
              required
              fullWidth
              name="password"
              label="パスワード"
              id="password"
              pattern="^[0-9a-zA-Z]+$"
              inputProps={{ minLength: 8 }}
              autoComplete="new-password"
              type={values.showPassword ? "text" : "password"}
              onChange={handleChange1}
              aria-required="true"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl sx={{ paddingTop: 3.3, width: "30ch" }} variant="outlined">
            <InputLabel
              sx={{ paddingTop: 3.3 }}
              color={passMatch ? "secondary" : "error"}
              htmlFor="outlined-adornment-password"
            >
              {passMatch ? "パスワード（確認用）" : "パスワードが一致しません"}
            </InputLabel>
            <OutlinedInput
              aria-label="Confirm Password"
              aria-required="true"
              aria-invalid={passMatch ? true : false}
              color={passMatch ? "secondary" : "error"}
              required
              fullWidth
              name="password"
              label={passMatch ? "パスワード（確認用）" : "パスワードが一致しません"}
              id="cPassword"
              pattern="^[0-9a-zA-Z]+$"
              inputProps={{ minLength: 8 }}
              autoComplete="new-password"
              type={values.showPassword1 ? "text" : "password"}
              onChange={handleChange1}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword1}
                    edge="end"
                  >
                    {values.showPassword1 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <ThemeProvider theme={theme}>
            <Button
              style={{
                marginTop: 15,
              }}
              type="submit"
              fullWidth
              variant="contained"
            >
              {loading ? "更新中..." : "パスワードの更新"}
            </Button>
          </ThemeProvider>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ForgotPasswordSubmit;
