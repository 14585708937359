import { getDebugger } from "components/Debugger";
import { memo, useCallback } from "react";
import ReactPaginate from "react-paginate";

const debug = getDebugger(false);

const Pagination = memo(({ itemsPerPage, setItemOffset, itemCount }) => {
  debug("Pagination is rendering");

  const pageCount = Math.ceil(itemCount / itemsPerPage);
  const handlePageClick = useCallback(
    (event) => {
      const newOffset = (event.selected * itemsPerPage) % itemCount;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setItemOffset(newOffset);
    },
    [itemsPerPage, setItemOffset, itemCount]
  );

  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
        marginPagesDisplayed={2} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
        pageCount={pageCount}
        previousLabel="<"
        containerClassName={"pagination"}
        activeClassName={"pagination__active"}
        disabledClassName={"pagination__disabled"}
      />
    </div>
  );
});

export default Pagination;
