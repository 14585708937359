import React, { useEffect, useState } from "react";

/** @jsxImportSource @emotion/react */
import useMedia from "use-media";

// AWS
import { API, graphqlOperation } from "aws-amplify";
import { listEventsByStatusAndCreatedAt } from "graphql/queries";

// その他
import { getDebugger } from "components/Debugger";
import theme from "components/MuiTheme";
import Thumbnail from "./Thumbnail";
import { styles } from "./HomeStyles";
import { Event } from "models";

const debug = getDebugger(false);

interface HomeProps {}

/**
 * Home component displays a list of available events.
 * @param {HomeProps} props - The properties for the Home component.
 * @returns {JSX.Element} The rendered Home component.
 */
const Home: React.FC<HomeProps> = (props) => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  debug(`Home is loading.`);
  const [homeEventIds, setHomeEventIds] = useState<string[]>([]);

  // 初期処理
  useEffect(() => {
    const runAPI = async () => {
      try {
        const result = (await API.graphql(
          // HACK 1MBでページングが発生するため、全ページで検索できるようにする
          // HACK サムネイルの表示に必要なものは取得できているはずなので、Thumbnail.jsでのgetEventを不要にする
          graphqlOperation(listEventsByStatusAndCreatedAt, {
            status: "AVAILABLE",
            sortDirection: "DESC",
            limit: 12,
          })
        )) as { data: { listEventsByStatusAndCreatedAt: { items: Array<Event> } } };
        const eventLists: string[] = [];
        result.data.listEventsByStatusAndCreatedAt.items.forEach((event: Event) => {
          eventLists.push(event.id);
        });
        setHomeEventIds(eventLists);
      } catch (error) {
        debug(error);
      }
    };
    runAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={[isWide ? styles.homeSpDivStyle : styles.homePcDivStyle]}>
      <h1 css={isWide ? styles.homeSpH1Style : styles.homePcH1Style}>ホーム</h1>
      <div css={isWide ? styles.childrenSpDivStyle : styles.childrenPcDivStyle}>
        {homeEventIds.map((id) => (
          <Thumbnail key={Home.name + id} eventID={id} />
        ))}
      </div>
    </div>
  );
};

export default Home;
