import { applicationConst } from "utils/constants";

const HeaderLinkList = [
  {
    key: "signIn",
    value: "Login",
    url: "signIn",
    displayCondition: applicationConst.displayCondition.unauthenticated,
  },
  {
    key: "profile",
    value: "マイページ",
    url: "profile",
    displayCondition: applicationConst.displayCondition.authenticated,
  },
];

export default HeaderLinkList;
