import { css } from "@emotion/react";
import theme from "components/MuiTheme";

export const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${theme.palette.grey[200]};
  `,

  rootEventPC: css`
    padding: 16px 100px 0 100px;
    margin: 16px;
    border-radius: 4px;
    max-width: 1000px;
    background: white;
  `,

  rootEventSP: css`
    padding: 16px 16px 0 16px;
    margin: 16px;
    border-radius: 4px;
    max-width: 340px;
    background: white;
  `,

  thumbnailPC: css`
    width: 600px;
    border-radius: 4px;
    margin-bottom: 16px;
  `,

  thumbnailSP: css`
    width: 340px;
    border-radius: 4px;
    margin-bottom: 16px;
  `,

  titleCategoryPC: css`
    display: flex;
    flex-direction: column;

    & span:first-of-type {
      margin-top: 1em;
    }

    & h1 {
      display: flex;
      font-size: 29px;
    }
  `,

  titleCategorySP: css`
    display: flex;
    flex-direction: column;

    & span:first-of-type {
      margin-top: 1em;
    }

    & h1 {
      display: flex;
      font-size: 16.5px;
    }
  `,

  hashtagsPC: css`
    display: flex;
    margin: 0 0 20px 10px;
    column-gap: 0.5em;
    row-gap: 0.5em;
    flex-wrap: wrap;
  `,

  hashtagsSP: css`
    display: flex;
    margin-bottom: 10px;
    column-gap: 0.5em;
    row-gap: 0.5em;
    flex-wrap: wrap;
  `,

  detailsPC: css`
    display: flex;
    flex-direction: column;
    margin: 0 10px 20px 10px;

    & div {
      display: flex;
    }
  `,

  detailsSP: css`
    display: flex;
    flex-direction: column;
    margin: 0 0px 10px 0px;
  `,

  detailsListPC: css`
    width: 120px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  `,

  detailsListSP: css`
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: bolder;
    margin-bottom: 10px;
  `,

  lastDetailsListPC: css`
    width: 120px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: auto;
  `,

  muiIconPC: css`
    margin-right: 14px;
    color: #606060;
  `,

  muiIconSP: css`
    margin-right: 14px;
    color: #606060;
    font-size: 19px;
  `,

  organizerPC: css`
    display: flex;
    align-items: center;
    margin: 0 0 10px 0px;
  `,

  organizerSP: css`
    display: flex;
    align-items: center;
    margin: 0 0 10px 30px;
  `,

  informationSP: css`
    display: flex;
    font-size: 14.5px;
    margin: 0 0 15px 30px;
    text-align: left;
  `,

  participant: css`
    width: 20px;
    height: 20px;
  `,

  overview: css`
    max-width: 475px;
    text-align: left;
  `,

  submitButton: css`
    margin-bottom: 20px;
    width: 95%;
  `,
};
