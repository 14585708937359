import React, { useState } from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useMedia from "use-media";
import { useNavigate } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

// MUI
import { Box, ThemeProvider, Typography, Button, Stepper, Step, StepLabel, StepContent, Paper } from "@mui/material";

// その他
import Type, { TypeTitle } from "components/AddSpace/Type";
import NewThumbnail, { NewThumbnailTitle } from "components/AddSpace/NewThumbnail";
import Address, { AddressTitle } from "components/AddSpace/Address";
import Facility, { FacilityTitle } from "components/AddSpace/Facility";
import Price, { PriceTitle } from "components/AddSpace/Price";
import theme from "components/MuiTheme";
import top003 from "components/img/top/top003.jpg";
import { getDebugger } from "components/Debugger";
import { useAuth } from "components/auth/AuthCommon";
import { createSpace } from "graphql/mutations";

var debug = getDebugger(false);

const styles = {
  rootPC: css`
    display: flex;
  `,

  rootSP: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  `,

  decorationPC: css`
    background-image: url(${top003});
    background-size: cover;
    background-position: left;
    height: 100vh;
    width: 50vw;
  `,

  decorationSP: css`
    height: 0px;
    width: 0px;
  `,

  rootAddSpacePC: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
    height: 100vh;
    overflow: scroll;
  `,

  rootAddSpaceSP: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 375px;
  `,

  stepper: css`
    margin: 20px 0;
  `,

  stepperLabel: css`
    font-size: 19px;
    font-weight: bold;
  `,

  stepperContent: css`
    width: 300px;
  `,
};

export const UserInputData = React.createContext();

// ここからメインのRegisterSpaceコンポーネント
function AddSpace() {
  const auth = useAuth();
  const [currentState, setCurrentState] = React.useState({});
  const value = {
    currentState,
    setCurrentState,
  };
  debug(currentState);

  // ステッパー
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });

  const steps = [
    {
      label: "掲載スペース基本情報の設定",
      description: [<Type handleNext={handleNext} />],
      title: [<TypeTitle />],
    },
    {
      label: "場所の所在地はどちらですか？",
      description: [<Address handleNext={handleNext} handleBack={handleBack} />],
      title: [<AddressTitle />],
    },
    {
      label: "提供できる設備をお選びください",
      description: [<Facility handleNext={handleNext} handleBack={handleBack} />],
      title: [<FacilityTitle />],
    },
    {
      label: "外観・内観写真(6枚以下)をアップしてください",
      description: [<NewThumbnail handleNext={handleNext} handleBack={handleBack} />],
      title: [<NewThumbnailTitle />],
    },
    {
      label: "利用時間と料金の設定",
      description: [<Price handleNext={handleNext} handleBack={handleBack} />],
      title: [<PriceTitle />],
    },
  ];

  const navigate = useNavigate();

  const createPreview = () => {
    debug(currentState);
    navigate("/space", { state: currentState });
  };

  return (
    <ThemeProvider theme={theme}>
      <main css={isWide ? styles.rootSP : styles.rootPC}>
        <div css={isWide ? styles.decorationSP : styles.decorationPC}>
          {activeStep === steps.length ? (
            <div>
              {!isWide && (
                <h1
                  style={{
                    paddingTop: 110,
                    fontSize: 48,
                    color: "#FFF",
                    textShadow: "0 0 0.2em rgba(0,0,0,1)",
                    height: "50%",
                  }}
                >
                  プレビューを確認する
                </h1>
              )}
            </div>
          ) : (
            steps[activeStep].title
          )}
        </div>
        <Box noValidate css={isWide ? styles.rootAddSpaceSP : styles.rootAddSpacePC}>
          <Stepper activeStep={activeStep} orientation="vertical" css={styles.stepper}>
            {steps.map((step, index, label) => (
              <Step key={step.label}>
                <StepLabel optional={<Typography variant="caption">{step.optional}</Typography>}>
                  <div css={styles.stepperLabel}>{step.label}</div>
                </StepLabel>
                <UserInputData.Provider value={value}>
                  <StepContent css={styles.stepperContent}>{step.description}</StepContent>
                </UserInputData.Provider>
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length && (
            <Paper square elevation={0}>
              <div>
                <Button onClick={handleBack}>戻る</Button>
                <Button variant="contained" onClick={createPreview}>
                  プレビューを表示する
                </Button>
              </div>
            </Paper>
          )}
        </Box>
      </main>
    </ThemeProvider>
  );
}

export default AddSpace;
