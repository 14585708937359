import React from "react";

// 外部パッケージ
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useMedia from "use-media";

// MUI
import Button from "@mui/material/Button";

// その他
import theme from "components/MuiTheme";

const styles = {
  divPC: css`
    padding-top: 2vw;
  `,
  divSP: css`
    width: 85vw;
  `,
  h3PC: css`
    font-size: 23px;
    text-align: left;
  `,
  h3SP: css`
    font-size: 15px;
    text-align: left;
  `,
  pPC: css`
    font-size: 15px;
    text-align: left;
  `,
  pSP: css`
    font-size: 14px;
    text-align: left;
  `,
  button: css`
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #db3531;
    width: 200px;
    height: 40px;
    font-weight: bold;
    float: left;
  `,
};

function Payout() {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  const navigate = useNavigate();
  const toEditProfile = () => {
    navigate("/profile/edit");
  };

  return (
    <div css={isWide ? styles.divSP : styles.divPC}>
      <h3 css={isWide ? styles.h3SP : styles.h3PC}>支払い受取方法</h3>
      <p css={isWide ? styles.pSP : styles.pPC}>
        送金先を指定できるよう、支払い受取方法を <br />
        1つ以上追加してください。
      </p>
      <Button css={styles.button} variant="contained" onClick={toEditProfile}>
        受取金を設定する
      </Button>
    </div>
  );
}

export default Payout;
