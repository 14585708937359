export type MasterType = ReadonlyArray<{
  value: string;
  label: string;
}>;

const mainCategories = [
  {
    value: "1",
    label: "エンタメ",
  },
  {
    value: "2",
    label: "スポーツ",
  },
  {
    value: "3",
    label: "アウトドア",
  },
  {
    value: "4",
    label: "ファミリー",
  },
  {
    value: "5",
    label: "飲食",
  },
  {
    value: "6",
    label: "教育",
  },
  {
    value: "7",
    label: "観光",
  },
  {
    value: "8",
    label: "芸術・文化",
  },
] as const;

export type MainCategoriesType = (typeof mainCategories)[number]["value"] | undefined;
export { mainCategories };

const gender = [
  {
    value: "male",
    label: "男性",
  },
  {
    value: "female",
    label: "女性",
  },
  {
    value: "other",
    label: "その他",
  },
  {
    value: "notSelect",
    label: "-",
  },
] as const;

export type GenderType = (typeof gender)[number]["value"] | undefined;
export { gender };

const jobs = [
  {
    value: "1",
    label: "公務員",
  },
  {
    value: "2",
    label: "経営者・役員",
  },
  {
    value: "3",
    label: "会社員",
  },
  {
    value: "4",
    label: "自営業",
  },
  {
    value: "5",
    label: "自由業",
  },
  {
    value: "6",
    label: "専業主婦(夫)",
  },
  {
    value: "7",
    label: "パート・アルバイト",
  },
  {
    value: "8",
    label: "学生",
  },
  {
    value: "9",
    label: "その他",
  },
] as const;

export type JobsType = (typeof jobs)[number]["value"] | undefined;
export { jobs };

const locale = [
  {
    value: "1",
    label: "北海道",
  },
  {
    value: "2",
    label: "青森県",
  },
  {
    value: "3",
    label: "岩手県",
  },
  {
    value: "4",
    label: "宮城県",
  },
  {
    value: "5",
    label: "秋田県",
  },
  {
    value: "6",
    label: "山形県",
  },
  {
    value: "7",
    label: "福島県",
  },
  {
    value: "8",
    label: "茨城県",
  },
  {
    value: "9",
    label: "栃木県",
  },
  {
    value: "10",
    label: "群馬県",
  },
  {
    value: "11",
    label: "埼玉県",
  },
  {
    value: "12",
    label: "千葉県",
  },
  {
    value: "13",
    label: "東京都",
  },
  {
    value: "14",
    label: "神奈川県",
  },
  {
    value: "15",
    label: "新潟県",
  },
  {
    value: "16",
    label: "富山県",
  },
  {
    value: "17",
    label: "石川県",
  },
  {
    value: "18",
    label: "福井県",
  },
  {
    value: "19",
    label: "山梨県",
  },
  {
    value: "20",
    label: "長野県",
  },
  {
    value: "21",
    label: "岐阜県",
  },
  {
    value: "22",
    label: "静岡県",
  },
  {
    value: "23",
    label: "愛知県",
  },
  {
    value: "24",
    label: "三重県",
  },
  {
    value: "25",
    label: "滋賀県",
  },
  {
    value: "26",
    label: "京都府",
  },
  {
    value: "27",
    label: "大阪府",
  },
  {
    value: "28",
    label: "兵庫県",
  },
  {
    value: "29",
    label: "奈良県",
  },
  {
    value: "30",
    label: "和歌山県",
  },
  {
    value: "31",
    label: "鳥取県",
  },
  {
    value: "32",
    label: "島根県",
  },
  {
    value: "33",
    label: "岡山県",
  },
  {
    value: "34",
    label: "広島県",
  },
  {
    value: "35",
    label: "山口県",
  },
  {
    value: "36",
    label: "徳島県",
  },
  {
    value: "37",
    label: "香川県",
  },
  {
    value: "38",
    label: "愛媛県",
  },
  {
    value: "39",
    label: "高知県",
  },
  {
    value: "40",
    label: "福岡県",
  },
  {
    value: "41",
    label: "佐賀県",
  },
  {
    value: "42",
    label: "長崎県",
  },
  {
    value: "43",
    label: "熊本県",
  },
  {
    value: "44",
    label: "大分県",
  },
  {
    value: "45",
    label: "宮崎県",
  },
  {
    value: "46",
    label: "鹿児島県",
  },
  {
    value: "47",
    label: "沖縄県",
  },
  {
    value: "48",
    label: "その他",
  },
] as const;

export type LocaleType = (typeof locale)[number]["value"] | undefined;
export { locale };

const venues = [
  {
    value: "1",
    label: "富久の湯",
  },
  {
    value: "2",
    label: "うそのたばこ店",
  },
  {
    value: "3",
    label: "PQ's",
  },
  {
    value: "4",
    label: "rebon快哉湯",
  },
  {
    value: "5",
    label: "share cafe分館",
  },
  {
    value: "6",
    label: "ちゃぶだい",
  },
] as const;

export type VenuesType = (typeof venues)[number]["value"] | undefined;
export { venues };

const spaces = [
  {
    value: "1",
    label: "店舗",
  },
  {
    value: "2",
    label: "会議室",
  },
  {
    value: "3",
    label: "民家",
  },
  {
    value: "4",
    label: "スタジオ",
  },
  {
    value: "5",
    label: "運動施設",
  },
  {
    value: "6",
    label: "屋外施設",
  },
  {
    value: "7",
    label: "その他",
  },
] as const;

export type SpacesType = (typeof spaces)[number]["value"] | undefined;
export { spaces };

const ages = [
  {
    value: "1",
    label: "10代",
  },
  {
    value: "2",
    label: "20代",
  },
  {
    value: "3",
    label: "30代",
  },
  {
    value: "4",
    label: "40代",
  },
  {
    value: "5",
    label: "50代",
  },
  {
    value: "6",
    label: "60代",
  },
  {
    value: "7",
    label: "-",
  },
] as const;

export type AgesType = (typeof ages)[number]["value"] | undefined;
export { ages };

const receptions = [
  {
    value: "1",
    label: "承認無しで予約が完了",
  },
  {
    value: "2",
    label: "承認してから予約完了",
  },
] as const;

export type ReceptionsType = (typeof receptions)[number]["value"] | undefined;
export { receptions };
