import React, { useState, useEffect, useMemo, useCallback } from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useMedia from "use-media";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

//MUI
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ThemeProvider, Box, Tab, Tabs, Modal, IconButton } from "@mui/material";
// import Favorite from "@mui/icons-material/Favorite";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
// import StoreIcon from "@mui/icons-material/Store";
import WorkIcon from "@mui/icons-material/Work";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

// AWS
import { API, graphqlOperation } from "aws-amplify";

// その他
import ProfileThumbnail from "components/myPage/ProfileThumbnail";
// import ProfileSpaceThumbnail from "components/profile/ProfileSpaceThumbnail";
import theme from "components/MuiTheme";
import { UserIcon } from "components/common/UserIcon";
import { CustomListEvents, getUserForPublic } from "graphql/customQueries";
import { useAuth } from "components/auth/AuthCommon";
import { locale, jobs } from "components/common/SelectValues";
import { convertValueToLabel } from "components/common/Utils";
import { UserStatus } from "models";
// その他
import { getDebugger } from "components/Debugger";

var debug = getDebugger(false);

// ここからスタイル↓
const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${theme.palette.grey[200]};
  `,
  rootPC: css`
    padding-top: 25px;
  `,
  rootSP: css`
    padding-top: 1vw;
    font-size: 12px;
  `,
  rootProfile: css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: white;
    border-radius: 5px;
  `,
  rootProfilePC: css`
    padding: 50px;
    width: 800px;
  `,
  rootProfileSP: css`
    padding: 15px;
    width: 90vw;
  `,
  profileLeft: css``,
  profileRight: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: -webkit-fill-available;

    & > div:not(:first-of-type) {
      margin-top: 0.5rem;
    }
  `,
  profileRightPC: css`
    margin-left: 50px;
  `,
  profileRightSP: css`
    margin-left: 15px;
  `,

  profileRightRow1: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  profileName: css`
    font-weight: bold;
  `,
  profileNamePC: css`
    font-size: 26px;
  `,
  profileNameSP: css`
    font-size: 20px;
  `,
  profileRightRow2: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > svg {
      opacity: 0.6;
      margin-right: 0.2rem;
    }
    & > div {
      margin-right: 2rem;
    }
  `,
  introduction: css`
    text-align: left;
    word-break: break-all;
  `,
  introductionPC: css`
    width: 450px;
  `,
  introductionSP: css`
    width: 60vw;
  `,
  profileEventList: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
    border-radius: 5px;
  `,
  profileEventListPC: css`
    margin: 25px 0;
    padding: 15px 25px 0 25px;
  `,
  profileEventListSP: css`
    margin: 6px 0;
    padding: 5px 15px 0 15px;
    width: 90vw;
  `,
  tabs: css`
    border-bottom: 1px solid #d0d0d0;
  `,
  tabsPC: css`
    width: 850px;
  `,
  tabsSP: css`
    width: 90vw;
  `,
  tabPanelPC: css`
    & > div {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  `,
  tabPanelSP: css`
    & > div {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  `,
  settingModal: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  settingModalList: css`
    background: white;
    padding: 0;
    border-radius: 5px;

    & > li:not(:last-of-type) {
      border-bottom: 1px solid #d0d0d0;
    }
  `,
  settingModalItem: css`
    text-align: center;
    padding: 10px 100px;
    font-size: 13px;
    cursor: pointer;
  `,
  eventListEmptyMessage: css`
    color: ${theme.palette.primary.main};
    padding: 2em 0;
    text-align: center;
  `,
  eventListEmptyMessagePC: css`
    font-size: 20px;
  `,
  eventListEmptyMessageSP: css`
    font-size: 15px;
  `,
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const Profile = (props) => {
  const isMyPage = props.isMyPage;
  const auth = useAuth();
  const navigate = useNavigate();
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  const [eventList, setEventList] = useState([]);
  const [userInfo, setUserInfo] = useState({
    nickname: "",
    locale: "",
    job: "",
    profile: "",
  });
  // タブ
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const params = useParams();
  const requestedUserID = isMyPage ? auth.userID : params?.userID || auth.userID;
  debug(`requestedUserID: ${requestedUserID}`);
  debug(`isMyPage: ${isMyPage}`);

  // 設定アイコンのモーダル
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const handleIsSettingModalOpen = useCallback(() => {
    setIsSettingModalOpen(!isSettingModalOpen);
  }, [isSettingModalOpen]);
  const toChangePassword = useCallback(() => {
    navigate("/changePassword");
  }, [navigate]);
  const toEditProfile = useCallback(() => {
    navigate("edit");
  }, [navigate]);
  // const toPayment = useCallback(() => {
  //   navigate("/payment");
  // }, [navigate]);
  const toUnsubscribe = useCallback(() => {
    navigate("/unsubscribe");
  }, [navigate]);

  //ログアウト
  const onSignOutPressed = useCallback(async () => {
    try {
      await auth.signOut();
      navigate("/");
    } catch (e) {
      // 認証コンポーネント側でエラーメッセージ表示している
    }
  }, [auth, navigate]);

  // 初期処理
  useEffect(() => {
    const runGetUser = async () => {
      if (requestedUserID === "") return;
      const result = await API.graphql(graphqlOperation(getUserForPublic, { id: requestedUserID }));
      setUserInfo({
        nickname: result.data.getUser.status !== UserStatus.DELETED ? result.data.getUser.nickname : "退会済みユーザー",
        locale: result.data.getUser.locale,
        job: result.data.getUser.job,
        profile: result.data.getUser.profile,
      });
    };

    runGetUser().catch((error) => {
      debug("ユーザ情報の取得に失敗しました。");
      debug(error);
    });

    const runListEvents = async () => {
      if (requestedUserID === "") return;

      const filter = {
        or: [
          {
            userID: { eq: requestedUserID },
          },
          // TODO スキーマが適正化できたら自分が参加したイベントも表示できるようにする↓これが正しいとは限らない
          // {
          //   EventEntires: { userID: { eq: requestedUserID } },
          // },
        ],
      };

      const sort = { field: "createdAt", direction: "desc" };

      const listEventsResult = await API.graphql(
        graphqlOperation(CustomListEvents, {
          filter,
          sort,
        })
      );

      debug(listEventsResult);
      if (listEventsResult.length === 0) return;

      const eventList = [];
      listEventsResult.data.listEvents.items.forEach((event) => {
        eventList.unshift(event.id);
      });
      setEventList(eventList);
    };

    runListEvents().catch((error) => {
      debug("イベント情報の取得に失敗しました。");
      debug(error);
    });
  }, [requestedUserID]);

  const settingIconView = useMemo(() => {
    return isMyPage ? (
      <IconButton onClick={handleIsSettingModalOpen}>
        <SettingsOutlinedIcon />
      </IconButton>
    ) : null;
  }, [handleIsSettingModalOpen, isMyPage]);

  const settingModalView = useMemo(() => {
    return isMyPage ? (
      <Modal css={styles.settingModal} open={isSettingModalOpen} onClose={handleIsSettingModalOpen}>
        <ul css={styles.settingModalList}>
          <li css={styles.settingModalItem} onClick={toEditProfile}>
            プロフィール編集
          </li>
          <li css={styles.settingModalItem} onClick={toChangePassword}>
            パスワード変更
          </li>
          {/* <li css={styles.settingModalItem} onClick={toPayment}>
              支払いと受取金
            </li> */}
          <li css={styles.settingModalItem} onClick={onSignOutPressed}>
            ログアウト
          </li>
          <li css={styles.settingModalItem} onClick={toUnsubscribe}>
            退会する
          </li>
          <li css={styles.settingModalItem} onClick={handleIsSettingModalOpen}>
            キャンセル
          </li>
        </ul>
      </Modal>
    ) : null;
  }, [
    isMyPage,
    isSettingModalOpen,
    handleIsSettingModalOpen,
    toEditProfile,
    toChangePassword,
    onSignOutPressed,
    toUnsubscribe,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <div css={[styles.root, isWide ? styles.rootSP : styles.rootPC]}>
        <div css={[styles.rootProfile, isWide ? styles.rootProfileSP : styles.rootProfilePC]}>
          <div css={styles.profileLeft}>
            <UserIcon size={isWide ? 120 : 200} userID={requestedUserID} />
          </div>
          <div css={[styles.profileRight, isWide ? styles.profileRightSP : styles.profileRightPC]}>
            <div css={styles.profileRightRow1}>
              <div css={[styles.profileName, isWide ? styles.profileNameSP : styles.profileNamePC]}>
                {userInfo.nickname}
              </div>
              {settingIconView}
            </div>
            <div css={styles.profileRightRow2}>
              <FmdGoodIcon />
              <div>{convertValueToLabel(locale, userInfo.locale)}</div>
              <WorkIcon />
              <div>{convertValueToLabel(jobs, userInfo.job)}</div>
            </div>
            <div css={[styles.introduction, isWide ? styles.introductionSP : styles.introductionPC]}>
              {userInfo.profile}
            </div>
          </div>
        </div>
        <div css={[styles.profileEventList, isWide ? styles.profileEventListSP : styles.profileEventListPC]}>
          <Box>
            <Tabs
              css={[styles.tabs, isWide ? styles.tabsSP : styles.tabsPC]}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
            >
              <Tab icon={<EmojiPeopleIcon />} aria-label="event" {...a11yProps(0)} />
              {/* <Tab
                  icon={<StoreIcon />}
                  aria-label="space"
                  {...a11yProps(1)}
                />
                <Tab
                  icon={<Favorite />}
                  aria-label="favirite"
                  {...a11yProps(2)}
                /> */}
            </Tabs>
            <TabPanel value={value} index={0} css={isWide ? styles.tabPanelSP : styles.tabPanelPC}>
              {!eventList.length ? (
                <div
                  css={[
                    styles.eventListEmptyMessage,
                    isWide ? styles.eventListEmptyMessageSP : styles.eventListEmptyMessagePC,
                  ]}
                >
                  対象のイベントが存在しません
                </div>
              ) : (
                eventList.map((id) => <ProfileThumbnail key={Profile.name + id} eventID={id} />)
              )}
            </TabPanel>
            {/* // TODO スペース版取得 */}
            <TabPanel value={value} index={1} css={styles.tabPanel}>
              {/* <ProfileSpaceThumbnail spaceID="b2d7d65f-d088-43f1-86aa-0c4416187786" /> */}
            </TabPanel>
            {/* // TODO お気に入り取得 */}
            <TabPanel value={value} index={2} css={styles.tabPanel}>
              {/* <ProfileThumbnail eventID="4a1a7434-993f-493c-a952-49110489b3a4" />
              <ProfileSpaceThumbnail spaceID="b2d7d65f-d088-43f1-86aa-0c4416187786" /> */}
            </TabPanel>
          </Box>
        </div>
      </div>
      {settingModalView}
    </ThemeProvider>
  );
};

export default Profile;
