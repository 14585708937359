import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import theme from "components/MuiTheme";
import { mainCategories, MainCategoriesType } from "components/common/SelectValues";

const styles = {
  eventMainCategorySpan: css`
    width: fit-content;
    padding-inline: 0.75em;
    border-radius: 1em;
    box-sizing: border-box;
    color: ${theme.palette.secondary.contrastText};
    background: ${theme.palette.secondary.light};
    white-space: nowrap;
  `,

  sizeSmall: css`
    font-size: 14px;
  `,

  sizeMedium: css`
    font-size: 16px;
  `,

  sizeLarge: css`
    font-size: 18px;
  `,

  enabledLink: css`
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  `,
};

export type MainCategoryLabelPropsType = {
  mainCategory: MainCategoriesType;
  options?: MainCategoryLabelPropsOptionsType;
};

type MainCategoryLabelPropsOptionsType = {
  size?: "small" | "medium" | "large";
  enabledLink?: boolean;
};

const MainCategoryLabel: React.FC<MainCategoryLabelPropsType> = React.memo((props) => {
  const { mainCategory } = props;
  const size = props.options?.size || "medium";
  const enabledLink = props.options?.enabledLink || false;

  const navigate = useNavigate();
  const toSearchResult = (mainCategory: MainCategoryLabelPropsType["mainCategory"]) => {
    if (enabledLink) {
      navigate(`/searchResult?venue=&date=&mainCategory=${mainCategory}&hashtag=`);
    }
  };

  const getSize = (size: MainCategoryLabelPropsOptionsType["size"]) => {
    switch (size) {
      case "small":
        return styles.sizeSmall;
      case "medium":
        return styles.sizeMedium;
      case "large":
        return styles.sizeLarge;
    }
  };

  return (
    <span
      css={[styles.eventMainCategorySpan, getSize(size), enabledLink && styles.enabledLink]}
      onClick={() => {
        toSearchResult(mainCategory);
      }}
    >
      {mainCategory && mainCategories[Number(mainCategory) - 1].label}
    </span>
  );
});

export default MainCategoryLabel;
