export const getUserForPublic = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      nickname
      gender
      birthdate
      job
      locale
      profile
      userIconKey
      twitterID
      instagramID
      status
      rating
      createdAt
      updatedAt
    }
  }
`;

export const updateUserForEditProfile = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      nickname
      job
      locale
      profile
      userIconKey
    }
  }
`;

export const CustomListEvents = /* GraphQL */ `
  query listEvents($filter: ModelEventFilterInput) {
    listEvents(filter: $filter) {
      items {
        id
        # 以下は現在不要ですが、今後ProfileThumbnailで一つ一つgetするのを廃止する際には必要になりそうです
        # mainCategory
        # startDate
        # prefecture
        # hashtags
      }
    }
  }
`;
