import React, { useState } from "react";

// 外部パッケージ
import PropTypes from "prop-types";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useMedia from "use-media";

// MUI
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { ThemeProvider } from "@mui/system";

// その他
import Payout from "components/Payment/Payout";
import Method from "components/Payment/Method";
import theme from "components/MuiTheme";

const styles = {
  tab: css`
    font-weight: bold;
    font-size: 18px;
  `,
  div: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  h2PC: css`
    font-size: 25px;
    margin: 40px 0;
  `,
  h2SP: css`
    font-size: 20px;
    margin: 10px 0;
  `,
  box: css`
    border-bottom: 1;
    border-color: "#fff";
  `,
  list: css`
    width: 50%;
    height: 20%;
  `,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// タブのための関数（意味は全然わからない）
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Payment = () => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div css={styles.div}>
      <h2 css={isWide ? styles.h2SP : styles.h2PC}>支払いと受け取り金</h2>
      <div css={styles.list}>
        <ThemeProvider theme={theme}>
          <Box css={styles.box}>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab css={styles.tab} label="支払金" {...a11yProps(0)} />
              <Tab css={styles.tab} label="受け取り金" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Method />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Payout />
          </TabPanel>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default Payment;
