import { css } from "@emotion/react";

export const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 355px;
    padding-right: 10px;
    padding-left: 10px;
  `,

  searchResultNumber: css`
    display: flex;
    justify-content: start;
    max-width: 830px;
    width: 90%;
    color: #9e9e9e;
    font-size: 14px;
  `,

  searchResultList: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    row-gap: 16px;
  `,
};