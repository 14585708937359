import React from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useMedia from "use-media";
import { useNavigate } from "react-router-dom";

// MUI
import Button from "@mui/material/Button";

// その他
import theme from "../MuiTheme";
import owner1 from "../img/usage/Owner1.png";
import owner2 from "../img/usage/Owner2.png";
import owner3 from "../img/usage/Owner3.png";
import owner4 from "../img/usage/Owner4.png";
import owner5 from "../img/usage/Owner5.png";

// ここからスタイル↓
const styles = {
  mainPC: css`
    width: 85vw;
    display: flex;
  `,
  mainSP: css`
    width: 85vw;
  `,
  imgPC: css`
    width: 30%;
    height: 30%;
    padding: 30px;
  `,
  imgSP: css`
    width: 85vw;
  `,
  sub: css`
    padding-top: 2vw;
  `,
  none: css`
    opacity: 1;
  `,
  h3PC: css`
    font-size: 23px;
    text-align: left;
  `,
  h3SP: css`
    font-size: 15px;
    text-align: left;
  `,
  pPC: css`
    font-size: 15px;
    text-align: left;
  `,
  pSP: css`
    font-size: 14px;
    text-align: left;
  `,
  buttonPC: css`
    margin-top: 20px;
    margin-bottom: 30px;
    background-color: #db3531;
    width: 200px;
    height: 40px;
    font-weight: bold;
    float: left;
  `,
  buttonSP: css`
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #db3531;
    width: 200px;
    height: 40px;
    font-weight: bold;
  `,
};

function UsageOwner() {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  //画面遷移
  const navigate = useNavigate();
  const toSignIn = () => {
    navigate("/signIn");
  };
  // const addSpace = () => {
  //   navigate("/addSpace");
  // };
  // const message = () => {
  //   navigate("/message");
  // };
  // const toPayment = () => {
  //   navigate("/payment");
  // };
  // const toRateReview = () => {
  //   navigate("/rateReview");
  // };
  return (
    <div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={owner1} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>1.オーナー会員登録</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>登録料・利用料は無料です。</p>
          <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={toSignIn}>
            オーナー登録
          </Button>
        </div>
      </div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={owner2} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>2.スペース登録</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>
            現時点で空きスペースを所有されてる方はチャンスです。
            <br />
            あなたのスペースでイベントをしたい人は、沢山います。
          </p>
          {/* <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={addSpace}>
            スペース登録
          </Button> */}
        </div>
      </div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={owner3} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>3.メッセージでやりとりする</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>
            あなたのスペースを使用するプランナーからの質問に答えましょう。
            <br />
            当日の施設利用に対してのアナウンス等も行い、トラブルを回避しましょう。
          </p>
          {/* <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={message}>
            メッセージ
          </Button> */}
        </div>
      </div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={owner4} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>4.プランナーレビュー</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>
            当日のイベントを含めてレビューしましょう。
            <br />
            あなたの素直な感想をプランナーさんは待ってます。
            <br />
            レビューは任意となりますが、サービス向上のためにご協力をお願いします。
          </p>
          {/* <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={toRateReview}>
            レビュー
          </Button> */}
        </div>
      </div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={owner5} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>5.収益を受け取る</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>
            イベントが開催された時のみ成約手数料をスペース利用料の15%いただきます
            <br />
            (売上の振込手数料、決済手数料はEventerが負担します)
          </p>
          {/* <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={toPayment}>
            受け取り金
          </Button> */}
        </div>
      </div>
    </div>
  );
}

export default UsageOwner;
