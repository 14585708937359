import React, { useContext, useEffect } from "react";

// 外部パッケージ
import { useForm, Controller } from "react-hook-form";
import useMedia from "use-media";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// MUI
import { TextField, Box, Button, MenuItem } from "@mui/material";

// その他
import { UserInputData } from "components/AddSpace/AddSpace";
import { locale } from "components/common/SelectValues";
import theme from "components/MuiTheme";

const styles = {
  div: css`
    display: flex;
  `,
  p: css`
    font-size: 15px;
    padding-top: 15px;
  `,
  p1: css`
    font-size: 15px;
    padding: 10px;
  `,
  h1: css`
    padding-top: 110px;
    font-size: 48px;
    color: #fff;
    text-shadow: 0 0 0.2em rgba(0, 0, 0, 1);
    height: 50%;
  `,
};

function Address(props) {
  const { control, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      postCode1: "",
      postCode2: "",
      prefectures: "",
      cities: "",
      address: "",
    },
  });
  const { currentState, setCurrentState } = useContext(UserInputData);

  // 保持している値を表示する
  useEffect(() => {
    if (currentState.Address) {
      setValue("postCode1", currentState.Address.postCode1);
      setValue("postCode2", currentState.Address.postCode2);
      setValue("prefectures", currentState.Address.prefectures);
      setValue("cities", currentState.Address.cities);
      setValue("address", currentState.Address.address);
    }
  }, [currentState.Address, setValue]);

  const onSubmit = (action) => {
    if (action === "back") {
      props.handleBack();
    } else {
      props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Address: data });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div css={styles.div}>
          <p css={styles.p}>郵便番号：</p>
          <Controller
            control={control}
            name="postCode1"
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                id="inputPostCode1"
                color="secondary"
                margin="normal"
                required
                style={{ width: 75, marginRight: 10 }}
                label="111"
                inputProps={{ style: { fontSize: 14 }, maxLength: 3, minLength: 3, pattern: "^[0-9]+$" }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                type="text"
                pattern="^[0-9]+$"
                step="1"
              />
            )}
          />
          <p css={styles.p1}>ー</p>
          <Controller
            control={control}
            name="postCode2"
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                id="inputPostCode2"
                color="secondary"
                margin="normal"
                required
                style={{ width: 100, marginleft: 500 }}
                label="0031"
                inputProps={{ style: { fontSize: 14 }, maxLength: 4, minLength: 4, pattern: "^[0-9]+$" }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                type="text"
                step="1"
              />
            )}
          />
        </div>
        <div>
          <div css={styles.div}>
            <Controller
              control={control}
              name="prefectures"
              render={({ field }) => (
                <TextField
                  select
                  {...field}
                  autoFocus
                  id="inputPrefectures"
                  color="secondary"
                  margin="normal"
                  required
                  style={{ width: 160, marginRight: 10 }}
                  label="都道府県"
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type="text"
                >
                  {locale.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              control={control}
              name="cities"
              render={({ field }) => (
                <TextField
                  {...field}
                  autoFocus
                  id="inputCities"
                  color="secondary"
                  margin="normal"
                  required
                  style={{ width: 160, marginleft: 500 }}
                  label="市区町村"
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  type="text"
                />
              )}
            />
          </div>
          <Controller
            control={control}
            name="address"
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                id="inputAddress"
                color="secondary"
                margin="normal"
                required
                fullWidth
                label="番地・建物名等"
                inputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                type="text"
              />
            )}
          />
        </div>
        <Box sx={{ mb: 2 }}>
          <div>
            <Button onClick={() => onSubmit("back")} sx={{ mt: 1, mr: 1 }}>
              戻る
            </Button>
            <Button type="submit" variant="contained" sx={{ mt: 1, mr: 1 }}>
              次へ
            </Button>
          </div>
        </Box>
      </div>
    </Box>
  );
}

export default Address;
const AddressTitle = () => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  return <div>{!isWide && <h1 css={styles.h1}>場所の所在地はどちらですか？</h1>}</div>;
};

export { AddressTitle };
