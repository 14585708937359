/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://x4kgp5xvlrdhta4hu4ce5mm5va.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "ap-northeast-1:5bdcb2d6-ed96-4cc8-bf87-aa6294589b81",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_dWTeIpqbb",
    "aws_user_pools_web_client_id": "22osathk0rq6n5o0hak1u2emo6",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "BIRTHDATE",
        "EMAIL",
        "GENDER",
        "NICKNAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "eventer-20230416120224-hostingbucket-production",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "http://eventer-20230416120224-hostingbucket-production.s3-website-ap-northeast-1.amazonaws.com",
    "aws_user_files_s3_bucket": "eventerfc74551bea6f4aadb546aff47dba880e201608-production",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
