import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { ProvideAuth } from "components/auth/AuthCommon";
import { ProvideLogger } from "utils/LoggerService";

Amplify.configure(config);

ReactDOM.render(
  <React.StrictMode>
    <ProvideLogger>
      <ProvideAuth>
        <App />
      </ProvideAuth>
    </ProvideLogger>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
