import { css } from "@emotion/react";
import theme from "components/MuiTheme";

export const styles = {
  root: css`
    min-width: 355px;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
  `,

  rootSearchBar: css`
    display: flex;
    align-items: center;
    flex-direction: column;

    & .searchBarPC {
      margin-top: 1rem;
    }

    & .searchBarSP {
      margin-top: 1rem;
    }
  `,

  displaySearchBarPC: css`
    & .searchBarPC {
      display: flex;
      filter: drop-shadow(0px 0px 3px ${theme.palette.grey[400]});
    }

    & .searchBarSP {
      display: none;
    }
  `,

  displaySearchBarSP: css`
    & .searchBarPC {
      display: none;
    }

    & .searchBarSP {
      display: flex;
      filter: drop-shadow(0px 0px 3px ${theme.palette.grey[400]});
    }
  `,

  searchBarGrid: css`
    background: #ffffff;
  `,

  searchBarGridPC: css`
    height: 80px;
    max-height: 80px;
    border-radius: 40px;
    width: 850px;
    padding-left: 40px;
    padding-right: 16px;

    & > div:not(:first-of-type):not(.searchBarIconPC) {
      margin-left: 12px;
    }

    & .searchBarIconPC {
      margin-left: 5px;
    }
  `,

  searchBarGridSP: css`
    height: 50px;
    border-radius: 25px;
    max-width: 830px;
    width: 90%;

    & .searchBarInputSP {
      margin-left: 25px;
    }

    & .searchBarIconSP {
      margin-right: 10px;
      margin-left: 10px;
    }
  `,

  searchBarInput: css`
    width: -webkit-fill-available;
    & .MuiInputBase-input {
      font-size: 1.2rem;
      font-weight: bolder;
    }
  `,

  modalDiv: css`
    position: absolute;
    top: 8%;
    left: 4%;
    width: 84%;
    max-height: 90%;
    overflow: auto;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 4%;

    & .modalTitleTypography {
      font-size: 1.2rem;
      font-weight: 500;
      text-align: left;
    }

    & .modalCloseIconGrid {
      text-align: right;
      position: relative;
      top: -13px;
      left: 13px;
    }
  `,
  modalTextField: css`
    width: -webkit-fill-available;
  `,
  modalButton: css`
    width: -webkit-fill-available;
    margin-top: 16px;
  `,

  textField: css`
    height: 75px;

    & .MuiFormHelperText-sizeMedium {
      font-size: 0.7rem;
    }
  `,

  inputSearchDateWidth: css`
    width: 160px;
  `,

  inputSearchCategoryWidth: css`
    width: 195px;
  `,
};
