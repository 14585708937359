// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useMedia from "use-media";

//  MUI
import { ThemeProvider } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";

// その他
import BackgroundImg from "./img/top/top002.jpg";
import theme from "components/MuiTheme";
import SearchBar from "./SearchBar/SearchBar";

const styles = {
  top: css`
    display: flex;
    align-items: center;
    flex-direction: column;

    & .message {
      flex-grow: 1;
    }
  `,

  BackgroundImg: css`
    background-image: url(${BackgroundImg});
    background-size: cover;
  `,

  backgroundImgPC: css`
    width: 100%;
    height: 600px;
  `,

  backgroundImgSP: css`
    height: 40vw;
  `,

  messageGrid: css`
    display: flex;
    align-items: center;
  `,

  messageTypography: css`
    text-align: center;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 0.2em rgba(0, 0, 0, 1);
  `,

  messageTypographyPC: css`
    font-size: 3rem;
  `,

  messageTypographySP: css`
    font-size: 1.5rem;
  `,
};

const Top = (props) => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });

  return (
    <ThemeProvider theme={theme}>
      <div css={[styles.top, styles.BackgroundImg, isWide ? styles.backgroundImgSP : styles.backgroundImgPC]}>
        <SearchBar />
        <Grid css={styles.messageGrid} className="message">
          <Typography
            css={[styles.messageTypography, isWide ? styles.messageTypographySP : styles.messageTypographyPC]}
          >
            もっと身近にイベントを
          </Typography>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default Top;
