import React from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const divStyle = {
  top: css`
    text-align: left;
    font-size: 1rem;
    padding: 60px;
  `,
};
const spanStyle = {
  span: css`
    color: #db3531;
  `,
};
const bStyle = {
  title: css`
    text-align: left;
    font-size: 1.3rem;
    margin-top: 50px;
  `,
};

const Term = () => {
  return (
    <div css={divStyle.top}>
      <h1>
        <span css={spanStyle.span}>Eventer</span>利用規約
      </h1>

      <div>
        本利用規約（以下「本規約」と言います。）には、本サービスの提供条件及び当社と登録ユーザーの皆本利用規約（以下，「本規約」と言います。）には，株式会社Eventer（以下，「当社」と言います。）が運営するサービス「Eventer」（以下，「本サービス」と言います。）の提供条件及び当社と利用者（以下，本サービスの利用を申込み利用が認められた登録者及び本サービスを利用してイベントを主催する主催者の双方を含みます。）の皆様との権利義務関係が定められています。本サービスの利用に際しては，本規約の全文をお読み頂いたうえで，本規約に同意いただく必要があります。
        　　　 本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。
      </div>

      <div>
        <b css={bStyle.title}>第1条（適用）</b>
      </div>

      <div>
        1.本規約は、本サービスの提供条件及び本サービスの利用に関する当社と登録ユーザーとの間の権利義務関係を定めることを目的とし、登録ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。
      </div>

      <div>
        2.当社が当社
        <a href="https://eventer.work/">ウェブサイト</a>
        上で掲載する本サービス利用に関するルール は、本規約の一部を構成するものとします。
      </div>

      <div>
        3.本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
      </div>

      <div>
        <b css={bStyle.title}>第2条（定義）</b>
      </div>

      <div>本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。</div>

      <div>
        1.「サービス利用契約」とは、本規約を契約条件として当社と登録ユーザーの間で締結される、本サービスの利用契約を意味します。
      </div>

      <div>
        2.「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。
      </div>

      <div>
        3.「投稿データ」とは、登録ユーザーが本サービスを利用して投稿その他送信するコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。
      </div>

      <div>4.「当社」とは、【株式会社Eventer】を意味します。</div>

      <div>
        5.「当社ウェブサイト」とは、そのドメインが「（
        <a href="https://eventer.work/">https://eventer.work/</a>
        ）」である、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
      </div>

      <div>
        6.「登録ユーザー」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人または法人を意味します。
      </div>

      <div>
        7.「本サービス」とは、当社が提供する【Eventer】という名称のサービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
      </div>

      <div>
        <b css={bStyle.title}>第3条（当社の役割）</b>
      </div>

      <div>
        当社は、第三者である主催者と参加者が取引を成立させ、代金を決済する場を提供します。当社は主催者と参加者との間の実際の取引には関与するものではありません。したがって、取引成立時には、主催者と参加者間に契約が成立することになります。また、会員である主催者と他の会員との間又は会員相互の間で紛争が生じた場合(イベントが中止された場合の返金を含みます)でも、当該紛争については当該会員間で解決するものとし、当社は一切の責任を負わないものとします。
      </div>

      <div>
        <b css={bStyle.title}>第4条（登録）</b>
      </div>

      <div>
        1.本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
      </div>

      <div>
        2.当社は、当社の基準に従って、第１項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者の登録ユーザーとしての登録は、当社が本項の通知を行ったことをもって完了したものとします。
      </div>

      <div>
        3.前項に定める登録の完了時に、サービス利用契約が登録ユーザーと当社の間に成立し、登録ユーザーは本サービスを本規約に従い利用することができるようになります。
      </div>

      <div>
        4.当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
      </div>

      <div>(1)当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合</div>

      <div>
        (2)未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合
      </div>

      <div>
        (3)反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
      </div>

      <div>(4)過去当社との契約に違反した者またはその関係者であると当社が判断した場合</div>

      <div>(5)第11条に定める措置を受けたことがある場合</div>

      <div>(6)その他、登録を適当でないと当社が判断した場合</div>

      <div>
        <b css={bStyle.title}>第5条（登録事項の変更）</b>
      </div>

      <div>
        1.登録ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。
      </div>

      <div>
        2.登録ユーザーが前項の義務を怠ったことにより発生した一切の損害について、弊社は一切の責任を負わないものとします。
      </div>

      <div>
        <b css={bStyle.title}>第6条（パスワード及びユーザーIDの管理）</b>
      </div>

      <div>
        1.登録ユーザーは、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
      </div>

      <div>
        2.パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は登録ユーザーが負うものとします。
      </div>

      <div>
        <b css={bStyle.title}>第7条（禁止事項）</b>
      </div>

      <div>
        登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。
      </div>

      <div>(1)法令に違反する行為または犯罪行為に関連する行為</div>

      <div>(2)当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為</div>

      <div>(3)公序良俗に反する行為やそのおそれのある行為，またはそれを助長する行為</div>

      <div>
        (4)当社、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
      </div>

      <div>
        (5)本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者に送信すること
      </div>

      <div>・過度に暴力的または残虐な表現を含む情報</div>

      <div>・コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報</div>

      <div>・当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報</div>

      <div>・過度にわいせつな表現を含む情報</div>

      <div>・差別を助長する表現を含む情報</div>

      <div>・自殺、自傷行為を助長する表現を含む情報</div>

      <div>・薬物の不適切な利用を助長する表現を含む情報</div>

      <div>・反社会的な表現を含む情報</div>

      <div>・チェーンメール等の第三者への情報の拡散を求める情報</div>

      <div>・他人に不快感を与える表現を含む情報</div>

      <div>(6)本サービスのネットワークまたはシステム等に過度な負荷をかける行為</div>

      <div>(7)当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為</div>

      <div>(8)本サービスの運営を妨害するおそれのある行為</div>

      <div>(9)当社のネットワークまたはシステム等への不正アクセス</div>

      <div>(10)第三者に成りすます行為</div>

      <div>(11)本サービスの他の利用者のIDまたはパスワードを利用する行為</div>

      <div>(12)当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</div>

      <div>(13)本サービスの他の利用者の情報の収集</div>

      <div>(14)当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為</div>

      <div>(15)当社ウェブサイト上で掲載する本サービス利用に関するルールに抵触する行為</div>

      <div>(16)反社会的勢力等への利益供与</div>

      <div>(17)面識のない異性との出会いを目的とした行為</div>

      <div>(18)前各号の行為を直接または間接に惹起し、または容易にする行為</div>

      <div>(19)イベント情報等に虚偽又は不正確な内容を書き込む行為</div>

      <div>(20)本サービスの機能や情報の正確性に関する問い合わせ又はクレームを投稿する行為</div>

      <div>(21)前各号の行為を試みること</div>

      <div>(22)その他、当社が不適切と判断する行為</div>

      <div>
        <b css={bStyle.title}>第8条（本サービスの停止等）</b>
      </div>

      <div>
        1.当社は，本サービスの提供にあたり，安定した運営に最善を尽くすものとしますが，以下のいずれかに該当する場合には，登録ユーザーに事前に通知することなく一時的に本サービスの全部または一部の提供を中断することができるものとします。
      </div>

      <div>(1)本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合</div>

      <div>
        (2)コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合
      </div>

      <div>(3)地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</div>

      <div>(4)法令による規制，司法命令等の適用により本サービスの提供ができなくなった場合</div>

      <div>(5)その他、当社が停止または中断を必要と判断した場合</div>

      <div>
        2.当社は，前項に定める本サービスの全部または一部の中断により利用者に生じた損害について，一切責任を負わないものとします
      </div>

      <div>
        <b css={bStyle.title}>第9条（権利帰属）</b>
      </div>

      <div>
        1.当社ウェブサイト及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
      </div>

      <div>
        2.登録ユーザーは、投稿データについて、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。
      </div>

      <div>
        3.登録ユーザーは、投稿データについて、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。また、他の登録ユーザーに対しても、本サービスを利用して登録ユーザーが投稿その他送信した投稿データの使用、複製、配布、派生著作物を作成、表示及び実行することについての非独占的なライセンスを付与します。
      </div>

      <div>
        4.登録ユーザーは、当社及び当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。
      </div>

      <div>
        <b css={bStyle.title}>第10条（登録抹消等）</b>
      </div>

      <div>
        1.当社は、登録ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿データを削除もしくは非表示にし、当該登録ユーザーについて本サービスの利用を一時的に停止し、または登録ユーザーとしての登録を抹消することができます。これにより会員に何らかの損害が生じたとしても、当社は一切責任を負わないものとします。
      </div>

      <div>(1)法令及び本規約等に違反する行為があった場合</div>

      <div>(2)登録事項に虚偽の事実があることが判明した場合</div>

      <div>
        (3)支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
      </div>

      <div>(4)6ヶ月以上本サービスの利用がない場合</div>

      <div>(5)当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合</div>

      <div>(6)他の登録ユーザーのコンテンツを事前同意なく出版及び放送などに提供した場合</div>

      <div>(7)他の登録ユーザー名及びパスワードを利用した場合</div>

      <div>(8)第4条第4項各号に該当する場合</div>

      <div>(9)その他、当社が本サービスの利用または登録ユーザーとしての登録の継続を適当でないと判断した場合</div>

      <div>
        2.前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
      </div>

      <div>
        <b css={bStyle.title}>第11条（退会）</b>
      </div>

      <div>
        1.登録ユーザーは、当社所定の手続の完了により、本サービスから退会し、自己の登録ユーザーとしての登録を抹消することができます。
      </div>

      <div>
        2.退会にあたり、当社に対して負っている債務が有る場合は、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
      </div>

      <div>3.退会後の利用者情報の取扱いについては、第15条の規定に従うものとします。</div>

      <div>
        <b css={bStyle.title}>第12条（本サービスの内容の変更、終了）</b>
      </div>

      <div>1.当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。</div>

      <div>2.当社が本サービスの提供を終了する場合、当社は登録ユーザーに事前に通知するものとします。</div>

      <div>
        <b css={bStyle.title}>第13条（保証の否認及び免責）</b>
      </div>

      <div>
        1.当社は、本サービスが登録ユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、登録ユーザーによる本サービスの利用が登録ユーザーに適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。
      </div>

      <div>
        2.当社は、本サービスに関して登録ユーザーが被った損害につき、過去6ヶ月間に登録ユーザーが当社に支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。
      </div>

      <div>
        3.本サービスまたは当社ウェブサイトに関連して登録ユーザーと他の登録ユーザーまたは第三者との間において生じた取引、連絡、紛争等については、登録ユーザーが自己の責任によって解決するものとします。
      </div>

      <div>
        4.登録ユーザーが本サービスを利用するにあたって、通信回線やコンピュータなどの障害によるシステムの中断、遅滞、中止、データの消失、もしくはデータへの不正アクセスにより生じた損害その他本サービスに関して会員に生じた損害について、当社は一切責任を負わないものとします。但し、当該損害の発生について、当社に故意又は重過失が存在する場合はこの限りではないものとします。
      </div>

      <div>
        5.当社は、当社のウェブページ、サーバ、ドメインなどから送られるメールコンテンツに、コンピュータウィルスその他の登録ユーザーのコンピュータ、デバイス、端末に対して有害な影響を生じさせるプログラムが含まれていないことを保証するものではなく、当社のメールコンテンツにそのようなプログラムが含まれていたことに起因して会員に損害が生じた場合であっても、当社は一切責任を負わないものとします。
      </div>

      <div>
        6.当社は、登録ユーザーに対し、適宜情報提供やアドバイスを行うことがありますが、それにより責任を負うものではありません。
      </div>

      <div>7.本サイト及び本サービスにて提供されているイベントの内容に関して、当社は一切の責任を負いません。</div>

      <div>
        8.本サービスの利用又は利用不能により生じる一切の損害（直接又は間接を問わず、また金銭的損害か精神的損害かを問いません。）について当社は一切の責任を負わないものとします。但し、当該損害の発生について、当社に故意又は重過失が存在する場合はこの限りでないものとします。
      </div>

      <div>
        <b css={bStyle.title}>第14条（秘密保持）</b>
      </div>

      <div>
        登録ユーザーは、本サービスに関連して当社が登録ユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。
      </div>

      <div>
        <b css={bStyle.title}>第15条（利用者情報の取扱い）</b>
      </div>

      <div>
        1.当社による登録ユーザーの利用者情報の取扱いについては、別途当社
        <a href="policy">プライバシーポリシー</a>
        の定めによるものとし、登録ユーザーはこのプライバシーポリシーに従って当社が登録ユーザーの利用者情報を取扱うことについて同意するものとします。
      </div>

      <div>
        2.当社は、登録ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、登録ユーザーはこれに異議を唱えないものとします。
      </div>

      <div>
        <b css={bStyle.title}>第16条（本規約等の変更）</b>
      </div>

      <div>1.当社は以下の場合に、当社の裁量により、本規約等を変更することができます</div>

      <div>(1)本規約等の変更が、登録ユーザーの一般の利益に適合するとき。</div>

      <div>
        (2)本規約等の変更が、本サイトの利用目的に反せず、かつ、変更の必要性及び変更後の内容の相当性が、変更の内容その他の変更に係る事情に照らして合理的なものであるとき
      </div>

      <div>
        2.当社は前項による利用規約の変更にあたり、変更後の本規約等の効力発生日の原則１か月前までに、本規約等を変更する旨及び変更後の本規約等の内容とその効力発生日を当社ウェブサイトに掲示し、または会員に電子メールで通知します
      </div>

      <div>
        3.変更後の本規約等の効力発生日以降に会員が本サイトを利用したときは、登録ユーザーは、本規約等の変更に同意したものとみなします。
      </div>

      <div>
        <b css={bStyle.title}>第17条（連絡/通知）</b>
      </div>

      <div>
        1.当社から登録ユーザーへの通知は，電子メールによる送信，本サービスサイトへの掲載その他当社が適当と認める方法により行われるものとします。なお，電子メールは，利用者が登録の際に入力したメールアドレス宛てに送信するものとします。
      </div>

      <div>
        2.当社が電子メールを用いて登録ユーザーに対して通知を行う場合には，当該電子メールを当社が登録ユーザーに向けて発信した時点，あるいは本サービスサイトへの掲載による場合は，その内容を本サービスサイト上に掲載した時点をもって，登録ユーザーに通知が到達したものとみなします。
      </div>

      <div>
        3.登録ユーザーが当社に連絡をする場合は，当社が他の方法を指定する場合を除き，本サービスサイト上に設置された問い合わせ専用フォームを利用して行うものとします。当社は，これ以外の方法による連絡はお受けいたしません。
      </div>

      <div>
        <b css={bStyle.title}>第18条（サービス利用契約上の地位の譲渡等）</b>
      </div>

      <div>
        1.登録ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
      </div>

      <div>
        2.当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに登録ユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
      </div>

      <div>
        <b css={bStyle.title}>第19条（分離可能性）</b>
      </div>

      <div>
        本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
      </div>

      <div>
        <b css={bStyle.title}>第20条（準拠法及び管轄裁判所）</b>
      </div>

      <div>1.本規約及びサービス利用契約の準拠法は日本法とします。</div>

      <div>
        2.本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
      </div>

      <div css={bStyle.title}>附則</div>

      <div>本規約は2022年1月から施行します。</div>

      <div>2022年9月7日制定</div>
    </div>
  );
};

export default Term;
