import { memo } from "react";

// 外部パッケージ
import useMedia from "use-media";

import {
  ThemeProvider,
  Button,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import theme from "components/MuiTheme";

// その他
import { getDebugger } from "components/Debugger";
import { locale, mainCategories } from "../common/SelectValues";
import { useSearchBar } from "./SearchBarHooks";
/** @jsxImportSource @emotion/react */
import { styles } from "./SearchBarStyles";

const INPUT_ITEMS = [
  {
    id: "inputSearchVenue",
    helperText: "近くに面白い場所があるかも？",
    value: "prefecture",
    css: [styles.textField, styles.inputSearchCategoryWidth],
    children: locale.map((option) => (
      <MenuItem key={`selectLocale${option.value}`} value={option.value}>
        {option.label}
      </MenuItem>
    )),
    others: {
      label: "開催場所",
      placeholder: "",
      select: true,
    },
  },
  {
    id: "inputSearchDate",
    helperText: "いつ行きたい？",
    value: "date",
    css: [styles.textField, styles.inputSearchDateWidth],
    children: "",
    others: {
      label: "開催日時",
      placeholder: "",
      type: "date",
      inputProps: { min: "2021-01-01", max: "9999-12-31" },
      InputLabelProps: { shrink: true },
    },
  },
  {
    id: "inputSearchCategory",
    helperText: "タブから選択",
    value: "mainCategory",
    css: [styles.textField, styles.inputSearchCategoryWidth],
    children: mainCategories.map((option) => (
      <MenuItem key={`selectMainCategory${option.value}`} value={option.value}>
        {option.label}
      </MenuItem>
    )),
    others: {
      label: "カテゴリー",
      placeholder: "",
      select: true,
    },
  },
  {
    id: "inputSearchHashtag",
    helperText: "ニッチなイベントがあるかも",
    value: "hashtag",
    css: styles.textField,
    children: "",
    others: {
      label: "ハッシュタグ",
      placeholder: "例) フィットネス",
      type: "search",
      InputProps: { startAdornment: <InputAdornment position="start">#</InputAdornment> },
    },
  },
];

var debug = getDebugger(false);

const SearchBar = memo((props) => {
  debug("SearchBar is rendering");

  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });

  const { values, onChangeValues, isModalOpen, handleModalOpen, handleModalClose, toSearchResult } = useSearchBar();

  return (
    <ThemeProvider theme={theme}>
      <div css={styles.root}>
        <form
          onSubmit={toSearchResult}
          css={[styles.rootSearchBar, isWide ? styles.displaySearchBarSP : styles.displaySearchBarPC]}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            columnSpacing={0}
            wrap="nowrap"
            css={[styles.searchBarGrid, styles.searchBarGridPC]}
            className="searchBarPC"
          >
            {INPUT_ITEMS.map((item) => {
              return (
                <Grid item key={`Grid${item.id}`}>
                  <TextField
                    id={item.id}
                    helperText={item.helperText}
                    onChange={onChangeValues(item.value)}
                    value={values[`${item.value}`]}
                    variant="standard"
                    color="secondary"
                    margin="dense"
                    css={item.css}
                    {...item.others}
                  >
                    {item.children ? (
                      <MenuItem key={`notSelect`} value={""}>
                        ---
                      </MenuItem>
                    ) : (
                      ""
                    )}
                    {item.children}
                  </TextField>
                </Grid>
              );
            })}
            <Grid item className="searchBarIconPC">
              <IconButton sx={{ p: 0 }} type="submit">
                <SearchIcon sx={{ fontSize: "2rem" }} />
              </IconButton>
            </Grid>
          </Grid>
          {/* スマホ版 */}
          <Grid
            css={[styles.searchBarGrid, styles.searchBarGridSP]}
            className="searchBarSP"
            justifyContent="space-between"
            alignItems="center"
            container
          >
            <Grid>
              <Input
                id="searchBarInput"
                placeholder="イベントを検索"
                onClick={handleModalOpen}
                variant="standard"
                color="secondary"
                size="small"
                disableUnderline={true}
                css={styles.searchBarInput}
                className="searchBarInputSP"
              />
            </Grid>
            <Grid className="searchBarIconSP">
              <IconButton sx={{ p: 0 }} onClick={handleModalOpen}>
                <SearchIcon sx={{ fontSize: "2rem" }} />
              </IconButton>
            </Grid>
          </Grid>
          <Modal open={isModalOpen} onClose={handleModalClose}>
            <div css={styles.modalDiv}>
              <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography className="modalTitleTypography">イベントを検索</Typography>
                </Grid>
                <Grid item className="modalCloseIconGrid">
                  <IconButton onClick={handleModalClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
              {INPUT_ITEMS.map((item) => {
                return (
                  <TextField
                    key={item.id}
                    onChange={onChangeValues(item.value)}
                    value={values[`${item.value}`]}
                    variant="outlined"
                    color="secondary"
                    margin="normal"
                    sx={styles.modalTextField}
                    {...item.others}
                  >
                    {item.children ? (
                      <MenuItem key={`notSelect`} value={""}>
                        ---
                      </MenuItem>
                    ) : (
                      ""
                    )}
                    {item.children}
                  </TextField>
                );
              })}
              <Button
                onClick={toSearchResult}
                size="large"
                variant="contained"
                color="secondary"
                startIcon={<SearchIcon />}
                sx={styles.modalButton}
              >
                検索
              </Button>
            </div>
          </Modal>
        </form>
      </div>
    </ThemeProvider>
  );
});

export default SearchBar;
