import React, { useState, useMemo, useCallback } from "react";

// 外部パッケージ
import { useLocation, useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// MUI
import { Modal, IconButton, ThemeProvider, FormControlLabel, Checkbox, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// AWS
import { API, graphqlOperation } from "aws-amplify";

// その他
import { updateEvent } from "graphql/mutations";
import theme from "components/MuiTheme";
import LogoImg from "components/img/Eventer_logo_red.png";
import { getDebugger } from "components/Debugger";
import NotFound from "./NotFound";
import { useAuth } from "./auth/AuthCommon";

// スタイル
const root = {
  backgroundColor: theme.palette.grey[200],
  paddingTop: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: 14,
};

const rootLogIn = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: 20,
  padding: " 0 30px 30px 30px",
  background: "white",
  borderRadius: 5,
  width: 284,
};

const upper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const upperLogo = { width: 150, height: 150 };

const title = {
  width: 300,
  display: "flex",
  justifyContent: "center",
  fontSize: 25,
  paddingTop: 20,
  paddingBottom: 20,
};

const cancelButton = {
  marginTop: 10,
};

const buttonStyle = {
  marginTop: 15,
  height: 40,
};

const styles = {
  modalDiv: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 4%;

    & .modalCloseIconDiv {
      position: absolute;
      top: 0%;
      right: 0%;
    }
  `,
};

var debug = getDebugger(false);
// TODO [Lambda][SES]削除時に削除完了メール送信→削除イベントの参加者に対して（参加者の方向けにメール文面作成済みとなります）
function CancelEvent() {
  debug("CancelEvent.js is rendering");

  const auth = useAuth();
  const location = useLocation().state;
  debug(location);
  const navigate = useNavigate();
  const toMyPage = useCallback(() => {
    navigate("/profile");
  }, [navigate]);
  const backToPreviousPage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const [isChecked, setIsChecked] = useState(false);
  const [isDBLoading, setIsDBLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const onCheckboxClicked = () => {
    setIsChecked((prevState) => !prevState);
  };

  const updateEventStatus = useCallback(async () => {
    const variables = {
      input: {
        id: location.eventID,
        status: "CANCELED",
      },
    };

    try {
      setIsDBLoading(true);
      // TODO 主催者以外は更新できないようにする（Eventテーブルの更新権限を主催者のみにする）
      await API.graphql(graphqlOperation(updateEvent, variables));
      return await Promise.resolve({ success: true });
    } catch (error) {
      return await Promise.resolve({ success: false, error });
    } finally {
      setIsDBLoading(false);
    }
  }, [location]);

  const onCancelEventClicked = (event) => {
    if (isDBLoading) return;
    event.preventDefault();
    updateEventStatus().then((result) => {
      // TODO [Lambda]イベント参加テーブルを更新する
      // TODO [Lambda][Stripe]チケットの払い戻し
      if (result.success) handleModalOpen();
      else {
        alert("イベントの中止に失敗しました");
        debug(result.error);
      }
    });
  };

  const checkButtonView = useMemo(() => {
    return (
      <FormControlLabel
        control={<Checkbox onClick={onCheckboxClicked} color="primary" />}
        checked={isChecked}
        label="同意して中止する"
      />
    );
  }, [isChecked]);

  const cancelButtonView = useMemo(() => {
    return (
      <Button disabled={!isChecked} type="submit" fullWidth variant="contained" style={cancelButton}>
        {isDBLoading ? "中止中..." : "中止する"}
      </Button>
    );
  }, [isChecked, isDBLoading]);

  const modalView = useMemo(() => {
    return (
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <div css={styles.modalDiv}>
          <IconButton onClick={handleModalClose} className="modalCloseIconDiv">
            <CloseIcon />
          </IconButton>
          <div>イベントを中止しました</div>
          <Button style={buttonStyle} fullWidth onClick={toMyPage}>
            マイページに戻る
          </Button>
        </div>
      </Modal>
    );
  }, [isModalOpen, toMyPage]);

  if (location && auth && location.userID === auth.userID)
    return (
      <ThemeProvider theme={theme}>
        <Box style={root}>
          <Box component="form" onSubmit={onCancelEventClicked} noValidate style={rootLogIn}>
            <div style={upper}>
              <img src={LogoImg} style={upperLogo} alt="logo" />
              <h1 style={title}>イベントを中止しますか？</h1>
              <p style={{ textAlign: "left" }}>
                イベントの開催を中止します。
                <br /> <br />
                {/* 既に参加申し込みをされている方はキャンセルとなり、購入されたチケットは自動的に払い戻されます。 */}
                既に参加申し込みをされている方は参加キャンセルとなります。
                <br /> <br />
                中止したイベントは元に戻すことができません。
              </p>
            </div>
            {checkButtonView}
            {cancelButtonView}
            <Button style={buttonStyle} fullWidth onClick={backToPreviousPage}>
              戻る
            </Button>
          </Box>
          {modalView}
        </Box>
      </ThemeProvider>
    );
  else {
    return <NotFound />;
  }
}

export default CancelEvent;
