import React, { useEffect, useState } from "react";

// 外部パッケージ
import useMedia from "use-media";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// AWS
import { API, graphqlOperation } from "aws-amplify";
import { listSpaces } from "graphql/queries";

// その他
import SpaceThumbnail from "components/FindSpace/SpaceThumbnail";
import theme from "components/MuiTheme";

const homePcDivStyle = css`
  width: 1020px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1.5rem;
  padding-right: 10px;
  padding-left: 10px;
`;

const homePcH1Style = css`
  font-size: 20px;
  width: 100%;
  text-align: left;
`;

const childrenPcDivStyle = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
`;

const homeSpDivStyle = css`
  width: 98%;
  margin-right: auto;
  margin-left: auto;
`;

const homeSpH1Style = css`
  font-size: 20px;
  width: 100%;
  text-align: left;
  margin-left: 0.5rem;
`;

const childrenSpDivStyle = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
  overflow-x: scroll;
`;

const SpaceHome = (props) => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  const [homeSpaceIds, setHomeSpaceIds] = useState([]);

  // 初期処理
  useEffect(() => {
    const runAPI = async () => {
      const result = await API.graphql(
        graphqlOperation(listSpaces, {
          // TODO 検索条件をスペース用に設定する
          filter: { status: { eq: "AVAILABLE" } },
          limit: 6,
          sort: { field: "createdAt", direction: "desc" },
        })
      );

      const eventLists = [];
      result.data.listEvents.items.forEach((event) => {
        eventLists.unshift(event.id);
      });
      setHomeSpaceIds(eventLists);
    };
    runAPI()
      .then()
      .catch((error) => {
        // アラートを出す必要があるかは様子見
        // alert("ユーザIDの取得に失敗しました。");
      });
  }, []);

  return (
    <div css={isWide ? homeSpDivStyle : homePcDivStyle}>
      <h1 css={isWide ? homeSpH1Style : homePcH1Style}>ホーム</h1>
      <div css={isWide ? childrenSpDivStyle : childrenPcDivStyle}>
        {/* TODO: イベントidを引数にして各イベントのサムネイルを作成させる map使えそう*/}
        {homeSpaceIds.map((spaceThumbnail) => (
          <SpaceThumbnail key={SpaceHome.name + spaceThumbnail.spaceId} spaceID={spaceThumbnail.spaceId} />
        ))}
      </div>
    </div>
  );
};

export default SpaceHome;
