import React from "react";

//外部パッケージ
import { useNavigate } from "react-router-dom";

// MUI
import { ThemeProvider, Box, Button, Typography } from "@mui/material";

// その他
import theme from "components/MuiTheme";
import LogoImg from "components/img/Eventer_logo_red.png";

const description = {
  textAlign: "left",
};

const buttonStyle = {
  marginTop: 15,
  height: 40,
};

// ここからメインのSignUpコンポーネント
function ForgotPasswordCompleted() {
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={theme}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: theme.palette.grey[200],
        }}
      >
        <Box
          component="form"
          noValidate
          style={{
            marginTop: 20,
            marginBottom: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: " 0 30px 30px 30px",
            background: "white",
            borderRadius: 5,
            width: 284,
          }}
        >
          <img src={LogoImg} style={{ width: 150, height: 150 }} alt="logo" />
          <Typography
            component="h1"
            variant="h1"
            style={{
              width: 300,
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            パスワードの再設定完了
          </Typography>
          <div style={description}>パスワードの再設定が完了しました。</div>
          <Button style={buttonStyle} fullWidth onClick={() => navigate("/signIn")}>
            ログイン画面に戻る
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ForgotPasswordCompleted;
