import { useMemo, useState } from "react";

// 外部パッケージ
import { Link, useNavigate } from "react-router-dom";

// MUI
import {
  ThemeProvider,
  Button,
  TextField,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  MenuItem,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// その他
import theme from "components/MuiTheme";
import { gender } from "components/common/SelectValues";
// import { jobs } from "../SelectValues";
import { useAuth } from "components/auth/AuthCommon";
import LogoImg from "components/img/Eventer_logo_red.png";

// ここからメインのSignUpコンポーネント
function SignUp() {
  // チェックボックスをチェックするとボタンが押せるようにする
  const [isChecked, setIsChecked] = useState(false);
  const checkboxPressed = () => {
    setIsChecked((prevState) => !prevState);
  };
  const auth = useAuth();
  const navigate = useNavigate();
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const onSignUpPressed = async (event) => {
    if (auth.isLoading) {
      return;
    }
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const result = await auth.signUp(
      data.get("email"),
      data.get("password"),
      data.get("nickname"),
      data.get("gender"),
      data.get("birthdate")
    );
    // HACK [Lambda]認証リンクのカスタマイズ（少なくとも日本語にしたい）
    if (result.success) {
      alert("認証メールを送信しました。\nメールに記載されているURLをクリックし、登録を完了してください。");
      navigate("/signIn");
    } else {
      // HACK エラー発生理由を表示させたい。auth.signUpのresultで保持している。
      // HACK stateの更新が非同期のため、divのstyleを切り替えることができない。
      // HACK 失敗時の値を保持する
      // setIsSignUpState(true);
      alert("新規登録に失敗しました。\n入力内容をご確認ください。");
    }
  };

  // 性別、生年月日、職業のプルダウン
  const [values, setValues] = useState({
    gender: "",
    birthdate: "2000-01-01",
    // job: "",
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
  });

  // 0~9,a~z,A~Zであるかをチェックする正規表現
  const validationCharacterRegex = useMemo(() => {
    return new RegExp(/^[0-9a-zA-Z]*$/);
  }, []);

  // 0~9,a~z,A~Zが「少なくとも１つずつ含まれてるか」をチェックする正規表現
  const validationPasswordRegex = useMemo(() => {
    return new RegExp(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])/);
  }, []);

  // パスワードのバリデーション結果を設定する
  // 入力されていなければまだチェックしない
  const isPasswordValid = useMemo(() => {
    if (!state.password) return true;
    return validationPasswordRegex.test(state.password);
  }, [state.password, validationPasswordRegex]);

  // パスワードと確認用パスワードの一致状態を設定する
  // 入力されていなければまだチェックしない
  const isPasswordMatch = useMemo(() => {
    if (!state.confirmPassword) return true;
    return state.password === state.confirmPassword;
  }, [state.confirmPassword, state.password]);

  // パスワードの長さチェック
  const isPasswordLengthChecked = useMemo(() => {
    return state.password.length >= 8 && state.confirmPassword.length >= 8;
  }, [state.confirmPassword.length, state.password.length]);

  // パスワード入力時のハンドラ
  // 半角英数字ならばstateの値を更新し、それ以外であれば入力を受け付けない
  const handleCheckPassword = (e) => {
    const { id, value } = e.target;
    e.preventDefault();
    if (validationCharacterRegex.test(e.target.value)) {
      setState((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: theme.palette.grey[200],
        }}
      >
        <Box
          component="form"
          onSubmit={onSignUpPressed}
          noValidate
          style={{
            marginTop: 20,
            marginBottom: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: " 0 30px 30px 30px",
            background: "white",
            borderRadius: 5,
          }}
        >
          <img src={LogoImg} style={{ width: 150, height: 150 }} alt="logo" />
          <Typography
            component="h1"
            variant="h1"
            style={{
              width: 300,
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            新規登録
          </Typography>
          {/* 
          <div
            style={
              isSignUpState.error ? { display: "block", color: "#DB3531", fontFamily: "bold" } : { display: "none" }
            }
          >
            新規登録に失敗しました
          </div> */}
          <TextField
            autoFocus
            color="secondary"
            margin="normal"
            autoComplete="nickname"
            name="nickname"
            required
            fullWidth
            id="nickname"
            label="ニックネーム(10文字以内)"
            inputProps={{ maxLength: 10 }}
          />
          <TextField
            select
            id="gender"
            name="gender"
            label="性別"
            onChange={handleChange("gender")}
            value={values.gender}
            variant="outlined"
            color="secondary"
            margin="normal"
            required
            fullWidth
            style={{ textAlign: "left" }}
          >
            {gender.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="birthdate"
            name="birthdate"
            label="生年月日"
            type="date"
            value={values.birthdate}
            onChange={handleChange("birthdate")}
            color="secondary"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          {/* <TextField
              select
              id="inputJob"
              label="職業"
              onChange={handleChange("job")}
              value={values.job}
              variant="outlined"
              color="secondary"
              margin="normal"
              fullWidth
              style={{ textAlign: "left" }}
            >
              {jobs.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField> */}
          <TextField
            color="secondary"
            margin="normal"
            required
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
          />

          <FormControl sx={{ paddingTop: 1.65, width: "30ch" }} variant="outlined">
            <InputLabel sx={{ paddingTop: 1.65 }} color="secondary" htmlFor="outlined-adornment-password">
              パスワード（8文字以上）
            </InputLabel>
            <OutlinedInput
              value={state.password}
              onChange={handleCheckPassword}
              aria-label="Password"
              className="form-control"
              color="secondary"
              required
              fullWidth
              name="password"
              label="パスワード"
              id="password"
              inputProps={{ minLength: 8 }}
              autoComplete="new-password"
              type={values.showPassword ? "text" : "password"}
              aria-required="true"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Box
            style={
              isPasswordValid
                ? { display: "none" }
                : { display: "block", textAlign: "left", color: "#DB3531", fontFamily: "bold" }
            }
          >
            <FormHelperText sx={{ color: "red", textAlign: "center" }}>
              半角英大文字、半角英小文字、数字を
              <br />
              それぞれ1つ以上含めてください
            </FormHelperText>
          </Box>
          <FormControl sx={{ paddingTop: 3.3, width: "30ch" }} variant="outlined">
            <InputLabel
              sx={{ paddingTop: 3.3 }}
              color={isPasswordMatch ? "secondary" : "error"}
              htmlFor="outlined-adornment-password"
            >
              {isPasswordMatch ? "パスワード（確認用）" : "パスワードが一致しません"}
            </InputLabel>
            <OutlinedInput
              value={state.confirmPassword}
              onChange={handleCheckPassword}
              aria-label="Confirm Password"
              aria-required="true"
              aria-invalid={isPasswordMatch}
              color={isPasswordMatch ? "secondary" : "error"}
              required
              fullWidth
              name="password"
              label={isPasswordMatch ? "パスワード（確認用）" : "パスワードが一致しません"}
              id="confirmPassword"
              inputProps={{ minLength: 8 }}
              autoComplete="new-password"
              type={values.showConfirmPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword1}
                    edge="end"
                  >
                    {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <div style={{ fontSize: 12, margin: "10px 0 5px 0" }}>
            登録には
            <Link to="/term" target="_blank" rel="noopener noreferrer">
              利用規約
            </Link>
            への同意が必要です。
            <br />
            こちらの内容をご確認の上、ご同意頂ける場合のみ
            <br />
            新規登録ボタンをタップして次の画面へお進み下さい。
          </div>
          <FormControlLabel
            control={<Checkbox onClick={checkboxPressed} color="primary" />}
            checked={isChecked}
            label="利用規約に同意して登録する"
          />
          <ThemeProvider theme={theme}>
            <Button
              disabled={!(isChecked && isPasswordMatch && isPasswordValid && isPasswordLengthChecked)}
              style={{
                marginTop: 15,
              }}
              type="submit"
              fullWidth
              variant="contained"
            >
              {/* // HACK 認証用URLに再度アクセスすると英語のエラーメッセージが表示される */}
              {auth.isLoading ? "登録中..." : "新規登録"}
            </Button>
          </ThemeProvider>
        </Box>
        <div style={{ marginBottom: 20 }}>
          既にアカウントをお持ちの方は
          <Link to="/signIn">こちら</Link>
        </div>
      </Box>
    </ThemeProvider>
  );
}

export default SignUp;
