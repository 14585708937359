import React, { useState, useContext } from "react";

// 外部パッケージ
import useMedia from "use-media";
import { useForm } from "react-hook-form";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// AWS
import { Storage } from "aws-amplify";

// MUI
import CancelIcon from "@mui/icons-material/Cancel";
import { Avatar, Box, Button, IconButton, ImageList, ImageListItem } from "@mui/material";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";

// その他
import { UserInputData } from "components/AddSpace/AddSpace";
import theme from "components/MuiTheme";

const styles = {
  h1: css`
    padding-top: 110px;
    font-size: 48px;
    color: #fff;
    text-shadow: 0 0 0.2em rgba(0, 0, 0, 1);
    height: 50%;
  `,
};

const NewThumbnail = (props) => {
  const { handleSubmit } = useForm({
    defaultValues: {
      thumbnails: [],
    },
  });

  // サムネイル変更
  const [thumbnails, setThumbnails] = useState([]);

  const maxImagesUpload = 6; // 画像を最大6枚まで選択・アップロード
  const image = Math.random().toString(32).substring(2);

  const { currentState, setCurrentState } = useContext(UserInputData);

  const onSubmit = (action) => {
    if (action === "back") {
      props.handleBack();
    } else {
      props.handleNext();
    }

    // 配列を１つずつ取り出して、オブジェクト配列化
    const pictures = createArrayObject(thumbnails);

    setCurrentState({ ...currentState, NewThumbnail: pictures });
  };

  const createArrayObject = (thumbnails) => {
    let tmpState = [];

    for (let i = 0; i < thumbnails.length; i++) {
      let objPics = {};
      let arr = thumbnails.slice(i, i + 1);
      arr.forEach((pic) => {
        objPics["url"] = pic;
        tmpState.push(objPics);
      });
    }
    return tmpState;
  };

  const handleOnAddImage = (e) => {
    if (!e.target.files) return;
    if (e.target.files.length + thumbnails.length > maxImagesUpload) {
      alert("外観・内観写真は6枚までです。");
      return;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];
      getImageUrl(file);
    }
  };

  // Get image URL
  const getImageUrl = async (file) => {
    const localImageUrl = await fetchAsText(file); // wait for encode
    setThumbnails((prevState) => [...prevState, localImageUrl]);
  };

  // Encode image to URL
  const fetchAsText = (file) => {
    return new Promise((resolve) => {
      const fr = new FileReader();
      fr.onload = (e) => {
        resolve(e.currentTarget.result);
      };
      fr.readAsDataURL(file);
    });
  };

  const handleOnRemoveImage = (index) => {
    // 選択した画像は削除可能
    const newImages = [...thumbnails];
    newImages.splice(index, 1);
    setThumbnails(newImages);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      {thumbnails.length !== 0 ? (
        <ImageList sx={{ width: 450, height: 450 }} cols={2} rowHeight={200}>
          {thumbnails.map((thumbnail, index) => {
            return (
              <ImageListItem key={index}>
                <IconButton
                  aria-label="dpicte image"
                  style={{
                    position: "absolute",
                    top: 10,
                    left: 10,
                    color: "#aaa",
                  }}
                  onClick={() => handleOnRemoveImage(index)}
                >
                  <CancelIcon />
                </IconButton>
                <img src={thumbnail ? thumbnail : ""} alt="thumbnail" loading="lazy" />
              </ImageListItem>
            );
          })}
        </ImageList>
      ) : (
        <Avatar
          name="thumbnails"
          variant="rounded"
          alt="thumbnails"
          style={{
            width: 330,
            height: 330,
          }}
        >
          <ImageNotSupportedOutlinedIcon style={{ fontSize: 100 }} />
        </Avatar>
      )}
      <label htmlFor={image}>
        <Button
          variant="outlined"
          disabled={thumbnails.length >= maxImagesUpload}
          component="span"
          color="secondary"
          size="small"
          style={{ margin: "15px 0 5px 0 ", fontSize: 14 }}
        >
          画像追加
        </Button>
        <input
          id={image}
          type="file"
          multiple="multiple"
          color="secondary"
          size="small"
          variant="outlined"
          component="span"
          accept="image/*,.png,.jpg,.jpeg,.gif"
          disabled={thumbnails.length >= maxImagesUpload}
          onChange={(e) => handleOnAddImage(e)}
          style={{ display: "none", margin: "15px 0 5px 0 ", fontSize: 14 }}
        />
      </label>
      <Box sx={{ mb: 2 }}>
        <div>
          <Button onClick={() => onSubmit("back")} sx={{ mt: 1, mr: 1 }}>
            戻る
          </Button>
          <Button type="submit" variant="contained" sx={{ mt: 1, mr: 1 }}>
            次へ
          </Button>
        </div>
      </Box>
    </Box>
  );
};

export default NewThumbnail;

const NewThumbnailTitle = () => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  return <div>{!isWide && <h1 css={styles.h1}>場所の外観・内観写真アップロード</h1>}</div>;
};

export { NewThumbnailTitle };
