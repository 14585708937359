import React from "react";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useMedia from "use-media";
import { useNavigate } from "react-router-dom";

// MUI
import Button from "@mui/material/Button";

//その他
import planner1 from "../img/usage/Planner1.png";
import planner2 from "../img/usage/Planner2.png";
import planner3 from "../img/usage/Planner3.png";
import planner4 from "../img/usage/Planner4.png";
import planner5 from "../img/usage/Planner5.png";
import planner6 from "../img/usage/Planner6.png";
import theme from "components/MuiTheme";

// ここからスタイル↓
const styles = {
  mainPC: css`
    width: 85vw;
    display: flex;
  `,
  mainSP: css`
    width: 85vw;
  `,
  imgPC: css`
    width: 30%;
    height: 30%;
    padding: 30px;
  `,
  imgSP: css`
    width: 85vw;
  `,
  sub: css`
    padding-top: 2vw;
  `,
  none: css`
    opacity: 1;
  `,
  h3PC: css`
    font-size: 23px;
    text-align: left;
  `,
  h3SP: css`
    font-size: 15px;
    text-align: left;
  `,
  pPC: css`
    font-size: 15px;
    text-align: left;
  `,
  pSP: css`
    font-size: 14px;
    text-align: left;
  `,
  buttonPC: css`
    margin-top: 20px;
    margin-bottom: 30px;
    background-color: #db3531;
    width: 200px;
    height: 40px;
    font-weight: bold;
    float: left;
  `,
  buttonSP: css`
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: #db3531;
    width: 200px;
    height: 40px;
    font-weight: bold;
  `,
};

function UsagePlanner() {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  //画面遷移
  const navigate = useNavigate();
  const toSignIn = () => {
    navigate("/signIn");
  };
  // const toFindSpace = () => {
  //   navigate("/findSpace");
  // };
  const toAddEvent = () => {
    navigate("/addEvent");
  };
  // const message = () => {
  //   navigate("/message");
  // };
  // const toPayment = () => {
  //   navigate("/payment");
  // };
  // const toRateReview = () => {
  //   navigate("/rateReview");
  // };

  return (
    <div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={planner1} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>1.プランナー会員登録</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>登録料・利用料は無料です。</p>
          <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={toSignIn}>
            プランナー登録
          </Button>
        </div>
      </div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={planner2} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>2.スペース検索</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>
            あなたのイベントを開催して場所を盛り上げて欲しい人は沢山います。
            <br />
            ここでしか探せない魅力的な場所を探しにいきましょう。
          </p>
          {/* <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={toFindSpace}>
            スペース検索
          </Button> */}
        </div>
      </div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={planner3} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>3.イベント登録</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>
            開催場所が決定したら、あなたの魅力的なイベントを登録しましょう。
            <br />
            魅力的なイベントは、魅力的なコミュニティを作ります。
          </p>
          <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={toAddEvent}>
            イベント登録
          </Button>
        </div>
      </div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={planner4} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>4.メッセージでやりとりする</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>
            ゲストからの質問に答えましょう。
            <br />
            また、当日の案内も直接行えます。
          </p>
          {/* <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={message}>
            メッセージ
          </Button> */}
        </div>
      </div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={planner5} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>5.イベントレビュー</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>
            イベントへの評価は十人十色です。
            <br />
            あなたの素直な感想をゲストに届けましょう。
            <br />
            (イベント参加後のレビューは任意となりますが、
            <br />
            サービス向上のためにご協力をお願いします。)
          </p>
          {/* <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={toRateReview}>
            レビュー
          </Button> */}
        </div>
      </div>
      <div css={isWide ? styles.mainSP : styles.mainPC}>
        <img css={isWide ? styles.imgSP : styles.imgPC} src={planner6} alt={"profile"} />
        <div css={isWide ? styles.none : styles.sub}>
          <h3 css={isWide ? styles.h3SP : styles.h3PC}>6.収益を受け取る</h3>
          <p css={isWide ? styles.pSP : styles.pPC}>
            イベントが開催された時のみ成約手数料を参加料の10%いただきます
            <br />
            (売上の振込手数料、決済手数料はEventerが負担します)
          </p>
          {/* <Button css={isWide ? styles.buttonSP : styles.buttonPC} variant="contained" onClick={toPayment}>
            受け取り金
          </Button> */}
        </div>
      </div>
    </div>
  );
}

export default UsagePlanner;
