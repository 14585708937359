// TODO (yas)環境変数に設定する
const isDebug: boolean = true;

export type getDebuggerType = (enabled?: boolean) => {
  (message?: any, ...optionalParams: any[]): void;
};
export const getDebugger: getDebuggerType = (enabled = true) => {
  return isDebug && enabled ? console.log.bind(window.console) : () => {};
};

export type getTracerType = (enabled?: boolean) => {
  (message?: any, ...optionalParams: any[]): void;
};
export const getTracer: getTracerType = (enabled: boolean = true) => {
  return isDebug && enabled ? console.trace.bind(window.console) : () => {};
};
