import React from "react";

// MUI
// import { styled } from "@mui/material/styles";
// Grid, Modal, MenuItem
import { TextField, IconButton } from "@mui/material";
// import MessageNewUserSearchIcon from "@mui/icons-material/PersonSearch";
// import MemberManageIcon from "@mui/icons-material/SupervisedUserCircle";
import MessageSettingIcon from "@mui/icons-material/Settings";
// import MessageAddMediaIcon from "@mui/icons-material/AddPhotoAlternate";
// import MessageSendIcon from "@mui/icons-material/Send";
// import CloseIcon from "@mui/icons-material/Close";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// その他
import MessageUserDataSP from "components/Message/MessageUserDataSP";
import profPhotoHonnin from "../../components/img/message/ユーザー本人.png";

// スタイル
const styles = {
  bodySpacerStyle: css`
    display: "flex",
    height: "770px",
    border: "solid #808080",
    borderWidth: "3px 3px 3px 3px",
  `,
  messageUserGrid: css`
    display: "table-cell";
  `,
  honninPhotoStyle: css`
    display: "table-cell",
   width: "40px",
    height: "40px",
    borderRadius: "40px",
  `,
  messageStringGrid: css`
    display: "table-cell",
    fontSize: "20px",
    fontWeight: "bold",
    width: "354px",
    height: "40px",
    verticalAlign: "middle",
  `,
  messageSettingGrid: css`
    display: "table-cell",
    width: "40px",
    height: "40px",
    textAlign: "center",
    verticalAlign: "middle",
  `,
  messageExistUserSearchGrid: css`
    border: "solid #808080",
    borderWidth: "0px 0px 3px 0px",
    padding: "0px 3px 0px 3px",
  `,
  messageUserListGrid: css`
    height: "650px",
    overflowY: "scroll",
  `,
  messageExchangeGrid: css`
    display: "table-cell",
    width: "1000px",
    height: "716.5px",
    border: "solid #808080",
    borderWidth: "0px 0px 0px 0px",
  `,
  messageExchangeHeaderGrid: css`
    display: "table-cell",
    width: "1000px",
    height: "30px",
  `,
  messageExchangeHeaderUserNmIdGrid: css`
    //  display: 'table-cell',
    //  width: '900px',
    //  height: '45px',
    //  textAlign: '-webkit-center',
    //  verticalAlign: 'middle',
  `,
  messageMemberManageGrid: css`
    display: "table-cell",
    width: "70px",
    height: "45px",
    verticalAlign: "middle",
  `,
  messageExchangeContentGrid: css`
    width: "1000px",
    height: "648.5px",
    border: "solid #808080",
    borderWidth: "5px 0px 0px 0px",
    overflowY: "scroll",
  `,
  messageExchangeSendGrid: css`
    display: "table-cell",
    width: "1000px",
    height: "73px",
    border: "solid #808080",
    borderWidth: "5px 0px 0px 0px",
  `,
  messageExchangeLeftGrid: css`
    display: "table-cell",
    width: "100px",
    height: "50px",
    verticalAlign: "middle",
  `,
  messageExchangeCenterGrid: css`
    display: "table-cell",
    width: "800px",
    height: "50px",
    verticalAlign: "middle",
  `,
  messageExchangeRightGrid: css`
  display: "table-cell",
  width: "100px",
  height: "50px",
  verticalAlign: "middle",
  `,
};

// β版用データ
const messageUserDatas = [
  {
    messageRoomId: 0,
    messageUserAccountId: "yamamoto_hiroto",
    nickName: "山本浩人",
    lastMessageDate: "10秒",
    messageContent: "あなたが♡で反応しました",
  },
  {
    messageRoomId: 1,
    messageUserAccountId: "Eventer_inc",
    nickName: "Eventer公式",
    lastMessageDate: "9分",
    messageContent: "Eventer公式です。こちらのイベントに興味があ...",
  },
  {
    messageRoomId: 2,
    messageUserAccountId: "chika",
    nickName: "ちか",
    lastMessageDate: "47分",
    messageContent: "ちかさんが👍で反応しました",
  },
  {
    messageRoomId: 3,
    messageUserAccountId: "saori",
    nickName: "さおり",
    lastMessageDate: "3時間",
    messageContent: "あなたが♡で反応しました",
  },
  {
    messageRoomId: 4,
    messageUserAccountId: "take1204",
    nickName: "タケ",
    lastMessageDate: "21時間",
    messageContent: "イベント参加希望です。",
  },
  {
    messageRoomId: 5,
    messageUserAccountId: "yuta0928",
    nickName: "ユータ",
    lastMessageDate: "2月3日",
    messageContent: "はじめまして!!イベントに参加したいのですが...",
  },
  {
    messageRoomId: 6,
    messageUserAccountId: "hanako",
    nickName: "花子",
    lastMessageDate: "2021年12月22日",
    messageContent: "この度はビジネス講座イベントにご参加いただ...",
  },
  {
    messageRoomId: 7,
    messageUserAccountId: "7nana7mi3",
    nickName: "ななみ",
    lastMessageDate: "2021年9月3日",
    messageContent: "本日は参加いただきありがとうございました...",
  },
  {
    messageRoomId: 8,
    messageUserAccountId: "12YukiMaru34",
    nickName: "ゆきまる",
    lastMessageDate: "2021年2月21日",
    messageContent: "お世話になっております。次のイベント日時...",
  },
  {
    messageRoomId: 9,
    messageUserAccountId: "ko-dai_0123",
    nickName: "こーだい",
    lastMessageDate: "2019年12月15日",
    messageContent: "こーだいさんが👍で反応しました",
  },
  {
    messageRoomId: 10,
    messageUserAccountId: "SaT0Sh123",
    nickName: "田中聡",
    lastMessageDate: "2019年10月27日",
    messageContent: "あなたが♡で反応しました",
  },
  {
    messageRoomId: 11,
    messageUserAccountId: "SuzukiTaro_AB...",
    nickName: "鈴木太郎@...",
    lastMessageDate: "2017年5月14日",
    messageContent: "はじめまして。(株)ABCワークスイベント事業...",
  },
];

const MessageSP = (props) => {
  // モーダル
  // const [isModalOpen, setModalOpen] = React.useState(false);
  // const handleModalOpen = () => setModalOpen(true);
  // const handleModalClose = () => setModalOpen(false);

  // メッセージ設定イベントです。イベント
  function findNewMessageUser() {
    alert("メッセージ設定イベントです。");
  }

  // メンバー管理イベント
  // function memberManageProc() {
  //   // alert('メンバー管理イベントです。');

  //   <Modal open={isModalOpen} onClose={handleModalClose}>
  //     <Grid>
  //       <CloseIcon />
  //     </Grid>
  //   </Modal>;
  // }

  // メッセージ設定イベント
  // function messageSettingProc() {
  //   alert("メッセージ設定イベントです。");
  // }

  // // メディア追加イベント
  // function mouseover() {
  //   document.getElementById("addMediaIcon").style.padding = "8px";
  //   document.getElementById("addMediaIcon").style.borderRadius = "50%";
  //   document.getElementById("addMediaIcon").style.backgroundColor = "#f5f5f5";
  // }
  // function mouseout() {
  //   document.getElementById("addMediaIcon").style.padding = "";
  //   document.getElementById("addMediaIcon").style.borderRadius = "";
  //   document.getElementById("addMediaIcon").style.backgroundColor = "";
  // }

  // メッセージ送信ベント
  // function sendMessage() {
  //   alert("メッセージ送信イベントです。");
  // }

  return (
    <div style={styles.bodySpacerStyle}>
      <styles.messageUserGrid container>
        <div>
          <table>
            <tr>
              <td rowSpan={1} colSpan={3}>
                <img src={profPhotoHonnin} style={styles.honninPhotoStyle} alt="ユーザ本人写真" />
              </td>
              <td>
                <styles.messageStringGrid>メッセージ</styles.messageStringGrid>
              </td>
              <td>
                <styles.messageSettingGrid>
                  <IconButton onClick={findNewMessageUser}>
                    <MessageSettingIcon sx={{ fontSize: "1.5rem" }} style={{ color: "#000000" }} />
                  </IconButton>
                </styles.messageSettingGrid>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <styles.messageExistUserSearchGrid>
            <TextField margin="dense" fullWidth id="searchUserText" name="searchUserText" label="ユーザーを検索" />
          </styles.messageExistUserSearchGrid>
        </div>
        <div>
          <styles.messageUserListGrid>
            {messageUserDatas.map((messageUserData) => (
              <div>
                <MessageUserDataSP
                  messageRoomId={messageUserData.messageRoomId}
                  messageUserAccountId={messageUserData.messageUserAccountId}
                  nickName={messageUserData.nickName}
                  lastMessageDate={messageUserData.lastMessageDate}
                  messageContent={messageUserData.messageContent}
                />
              </div>
            ))}
          </styles.messageUserListGrid>
        </div>
      </styles.messageUserGrid>

      {/* <MessageExchangeGrid container>
          <MessageExchangeHeaderGrid>
             <MessageExchangeHeaderUserNmIdGrid>
              <table>
                <tr>
                  <td rowSpan={2}>
                    <img src={profPhoto1} style={profPhotoStyle} alt='プロフ写真' />
                  </td>
                  <td style={{textAlign: 'center'}}>
                    <div style={{fontWeight: 'bold'}}>
                      {messageUserDatas[0].nickName}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    @{messageUserDatas[0].messageUserAccountId}
                  </td>
                </tr>
              </table>
            </MessageExchangeHeaderUserNmIdGrid>

            <MessageMemberManageGrid>
              <label title='メンバー管理'>
                <IconButton onClick={memberManageProc}>
                  <MemberManageIcon sx={{ fontSize: '2rem' }} style={{color: '#000000'}}/>
                </IconButton>
              </label>
            </MessageMemberManageGrid>
            <MessageSettingGrid>
              <label title='メッセージ設定'>
                <IconButton onClick={messageSettingProc}>
                  <MessageSettingIcon sx={{ fontSize: '2rem' }} style={{color: '#000000'}}/>
                </IconButton>
              </label>
            </MessageSettingGrid>

          </MessageExchangeHeaderGrid>

          <div>
            <MessageExchangeContentGrid>

            </MessageExchangeContentGrid>
          </div>

          <div>
            <MessageExchangeSendGrid>
              <MessageExchangeLeftGrid>
                <label for="mediaPhoto" title='メディア'>
                  <MessageAddMediaIcon id="addMediaIcon" onMouseOver={mouseover} onMouseLeave={mouseout}  sx={{ fontSize: '2rem' }} style={{cursor: 'pointer', color: '#000000'}}/>
	                <input type="file" id="mediaPhoto" name="filePhoto" style={{display:'none'}} accept="image/*, audio/*, video/*" value={''}/>
                </label>
              </MessageExchangeLeftGrid>

              <MessageExchangeCenterGrid>
                <TextField
                  margin = "dense"
                  label = "新しいメッセージを入力"
                  multiline
                  fullWidth
                />
              </MessageExchangeCenterGrid>

              <MessageExchangeRightGrid>
                <label title='送信'>
                  <IconButton onClick={sendMessage}>
                    <MessageSendIcon sx={{ fontSize: '2rem' }} style={{color: '#000000'}}/>
                  </IconButton>
                </label>
              </MessageExchangeRightGrid>
            </MessageExchangeSendGrid>
          </div>
        </MessageExchangeGrid> */}
    </div>
  );
};

export default MessageSP;
