/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      messagegroupID
      userID
      sendAt
      content
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messagegroupID
        userID
        sendAt
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessageMember = /* GraphQL */ `
  query GetMessageMember($id: ID!) {
    getMessageMember(id: $id) {
      id
      messagegroupID
      userID
      groupAddedAt
      createdAt
      updatedAt
    }
  }
`;
export const listMessageMembers = /* GraphQL */ `
  query ListMessageMembers(
    $filter: ModelMessageMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messagegroupID
        userID
        groupAddedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessageGroup = /* GraphQL */ `
  query GetMessageGroup($id: ID!) {
    getMessageGroup(id: $id) {
      id
      userID
      messageGroupName
      createdAt
      MessageMembers {
        items {
          id
          messagegroupID
          userID
          groupAddedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      Messages {
        items {
          id
          messagegroupID
          userID
          sendAt
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listMessageGroups = /* GraphQL */ `
  query ListMessageGroups(
    $filter: ModelMessageGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        messageGroupName
        createdAt
        MessageMembers {
          items {
            id
            messagegroupID
            userID
            groupAddedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        Messages {
          items {
            id
            messagegroupID
            userID
            sendAt
            content
            createdAt
            updatedAt
          }
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      id
      eventID
      name
      price
      salesQuantity
      stockQuantity
      status
      TicketPurchases {
        userID
        status
        TicketPurchaseHistories {
          operation
          price
          operationDateTime
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        name
        price
        salesQuantity
        stockQuantity
        status
        TicketPurchases {
          userID
          status
          TicketPurchaseHistories {
            operation
            price
            operationDateTime
          }
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTicketPurchase = /* GraphQL */ `
  query GetTicketPurchase($id: ID!) {
    getTicketPurchase(id: $id) {
      userID
      status
      TicketPurchaseHistories {
        operation
        price
        operationDateTime
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const listTicketPurchases = /* GraphQL */ `
  query ListTicketPurchases(
    $filter: ModelTicketPurchaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketPurchases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        userID
        status
        TicketPurchaseHistories {
          operation
          price
          operationDateTime
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      thumbnailImageKey
      mainCategory
      overview
      status
      userID
      startDate
      startTime
      endDate
      endTime
      EventEntries {
        items {
          eventID
          userID
          status
          id
          createdAt
          updatedAt
          eventEventEntriesId
        }
        nextToken
      }
      postCode1
      postCode2
      prefecture
      city
      address
      hashtags
      Tickets {
        items {
          id
          eventID
          name
          price
          salesQuantity
          stockQuantity
          status
          TicketPurchases {
            userID
            status
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        thumbnailImageKey
        mainCategory
        overview
        status
        userID
        startDate
        startTime
        endDate
        endTime
        EventEntries {
          items {
            eventID
            userID
            status
            id
            createdAt
            updatedAt
            eventEventEntriesId
          }
          nextToken
        }
        postCode1
        postCode2
        prefecture
        city
        address
        hashtags
        Tickets {
          items {
            id
            eventID
            name
            price
            salesQuantity
            stockQuantity
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventEntry = /* GraphQL */ `
  query GetEventEntry($id: ID!) {
    getEventEntry(id: $id) {
      eventID
      userID
      status
      id
      createdAt
      updatedAt
      eventEventEntriesId
    }
  }
`;
export const listEventEntries = /* GraphQL */ `
  query ListEventEntries(
    $filter: ModelEventEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventEntries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        eventID
        userID
        status
        id
        createdAt
        updatedAt
        eventEventEntriesId
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      nickname
      gender
      birthdate
      job
      locale
      profile
      userIconKey
      twitterID
      instagramID
      status
      deletedAt
      rating
      EventEntries {
        items {
          eventID
          userID
          status
          id
          createdAt
          updatedAt
          eventEventEntriesId
        }
        nextToken
      }
      Events {
        items {
          id
          name
          thumbnailImageKey
          mainCategory
          overview
          status
          userID
          startDate
          startTime
          endDate
          endTime
          EventEntries {
            nextToken
          }
          postCode1
          postCode2
          prefecture
          city
          address
          hashtags
          Tickets {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      TicketPurchases {
        items {
          userID
          status
          TicketPurchaseHistories {
            operation
            price
            operationDateTime
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      MessageGroups {
        items {
          id
          userID
          messageGroupName
          createdAt
          MessageMembers {
            nextToken
          }
          Messages {
            nextToken
          }
          updatedAt
        }
        nextToken
      }
      MessageMembers {
        items {
          id
          messagegroupID
          userID
          groupAddedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      Messages {
        items {
          id
          messagegroupID
          userID
          sendAt
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        nickname
        gender
        birthdate
        job
        locale
        profile
        userIconKey
        twitterID
        instagramID
        status
        deletedAt
        rating
        EventEntries {
          items {
            eventID
            userID
            status
            id
            createdAt
            updatedAt
            eventEventEntriesId
          }
          nextToken
        }
        Events {
          items {
            id
            name
            thumbnailImageKey
            mainCategory
            overview
            status
            userID
            startDate
            startTime
            endDate
            endTime
            postCode1
            postCode2
            prefecture
            city
            address
            hashtags
            createdAt
            updatedAt
          }
          nextToken
        }
        TicketPurchases {
          items {
            userID
            status
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        MessageGroups {
          items {
            id
            userID
            messageGroupName
            createdAt
            updatedAt
          }
          nextToken
        }
        MessageMembers {
          items {
            id
            messagegroupID
            userID
            groupAddedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        Messages {
          items {
            id
            messagegroupID
            userID
            sendAt
            content
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpace = /* GraphQL */ `
  query GetSpace($id: ID!) {
    getSpace(id: $id) {
      id
      name
      overview
      size
      capacity
      postalCode
      prefCode
      city
      address
      contact
      feePerHour
      userID
      startDate
      endDate
      sunAvailableStartTime
      sunAvailableEndTime
      monAvailableStartTime
      monAvailableEndTime
      tueAvailableStartTime
      tueAvailableEndTime
      wedAvailableStartTime
      wedAvailableEndTime
      thuAvailableStartTime
      thuAvailableEndTime
      friAvailableStartTime
      friAvailableEndTime
      satAvailableStartTime
      satAvailableEndTime
      holAvailableStartTime
      holAvailableEndTime
      reservationPeriod
      reservationApproval
      AvailableFacilities {
        id
        name
      }
      SpaceCategories {
        id
        name
      }
      SpacePictures {
        index
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSpaces = /* GraphQL */ `
  query ListSpaces(
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        overview
        size
        capacity
        postalCode
        prefCode
        city
        address
        contact
        feePerHour
        userID
        startDate
        endDate
        sunAvailableStartTime
        sunAvailableEndTime
        monAvailableStartTime
        monAvailableEndTime
        tueAvailableStartTime
        tueAvailableEndTime
        wedAvailableStartTime
        wedAvailableEndTime
        thuAvailableStartTime
        thuAvailableEndTime
        friAvailableStartTime
        friAvailableEndTime
        satAvailableStartTime
        satAvailableEndTime
        holAvailableStartTime
        holAvailableEndTime
        reservationPeriod
        reservationApproval
        AvailableFacilities {
          id
          name
        }
        SpaceCategories {
          id
          name
        }
        SpacePictures {
          index
          key
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listEventsByStatusAndCreatedAt = /* GraphQL */ `
  query ListEventsByStatusAndCreatedAt(
    $status: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventsByStatusAndCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        thumbnailImageKey
        mainCategory
        overview
        status
        userID
        startDate
        startTime
        endDate
        endTime
        EventEntries {
          items {
            eventID
            userID
            status
            id
            createdAt
            updatedAt
            eventEventEntriesId
          }
          nextToken
        }
        postCode1
        postCode2
        prefecture
        city
        address
        hashtags
        Tickets {
          items {
            id
            eventID
            name
            price
            salesQuantity
            stockQuantity
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
