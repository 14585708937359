import React, { useCallback, useState } from "react";
import { formatToUTCDateTime } from "components/common/Utils";

// 外部パッケージ
import useMedia from "use-media";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useForm, Controller } from "react-hook-form";
import { useRecoilState } from "recoil";
import { userInputDataAtom } from "../common/UserInputDataAtom";

// MUI
import { TextField, Box, Button, MenuItem, FormHelperText } from "@mui/material";

// その他
import { locale } from "components/common/SelectValues";
import theme from "components/MuiTheme";
// import { venues } from "../SelectValues";

const styles = {
  h1: css`
    padding-top: 20px;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    opacity: 0.8;
  `,
  div: css`
    display: flex;
  `,
  p: css`
    font-size: 15px;
    padding-top: 15px;
  `,
  p1: css`
    font-size: 15px;
    padding: 10px;
  `,
  h2: css`
    padding-top: 110px;
    font-size: 48px;
    color: #fff;
    text-shadow: 0 0 0.2em rgba(0, 0, 0, 1);
    height: 50%;
  `,
};

const NewDetails = (props) => {
  const [userInputData, setUserInputData] = useRecoilState(userInputDataAtom);
  const [isDateError, setIsDateError] = useState(false);

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      ...userInputData.NewDetails,
    },
  });

  function calcTimeDiff(utcTime1, utcTime2) {
    const diffInMilliseconds = Math.abs(utcTime2 - utcTime1);
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60); // ミリ秒から時間に変換
    return diffInHours >= 24;
  }

  const validateDateRange = useCallback(() => {
    const data = getValues();
    const now = new Date();
    const startDate = new Date(formatToUTCDateTime(data.startDate, data.startTime));
    const endDate = new Date(formatToUTCDateTime(data.endDate, data.endTime));
    // 開始時刻が終了時刻より未来だったらエラー(trueにする)
    // 開始時刻が現在時刻より過去だったらエラー(trueにする)
    // 開始時刻と終了時刻の差が24時間以上だったらエラー(trueにする)
    const isDateError = startDate > endDate || startDate < now || calcTimeDiff(startDate, endDate);
    setIsDateError(isDateError);
    return isDateError;
  }, [getValues]);

  const onSubmit = (action) => {
    if (validateDateRange()) return; // バリデーションを実行
    if (action === "back") {
      props.handleBack();
    } else {
      props.changeBtnStatus();
      props.handleNext();
    }
    const data = getValues();
    setUserInputData({ ...userInputData, NewDetails: data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 css={styles.h1}>イベント名の設定（20文字以内）</h1>
      <Controller
        control={control}
        name="eventName"
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus
            id="inputEventName"
            color="secondary"
            margin="normal"
            required
            fullWidth
            label="例）朝風呂ランニング"
            inputProps={{ style: { fontSize: 14 }, maxLength: 20 }}
            InputLabelProps={{ style: { fontSize: 14 } }}
          />
        )}
      />
      <h1 css={styles.h1}>開催場所の設定</h1>
      <div css={styles.div}>
        <p css={styles.p}>郵便番号：</p>
        <Controller
          control={control}
          name="postCode1"
          render={({ field }) => (
            <TextField
              {...field}
              autoFocus
              id="inputPostCode1"
              color="secondary"
              margin="normal"
              required
              style={{ width: 75, marginRight: 10 }}
              label="111"
              inputProps={{ style: { fontSize: 14 }, maxLength: 3, minLength: 3, pattern: "^[0-9]+$" }}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />
          )}
        />
        <p css={styles.p1}>ー</p>
        <Controller
          control={control}
          name="postCode2"
          render={({ field }) => (
            <TextField
              {...field}
              autoFocus
              id="inputPostCode2"
              color="secondary"
              margin="normal"
              required
              style={{ width: 100, marginleft: 500 }}
              label="0031"
              inputProps={{ style: { fontSize: 14 }, maxLength: 4, minLength: 4, pattern: "^[0-9]+$" }}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />
          )}
        />
      </div>
      <div>
        <div css={styles.div}>
          <Controller
            control={control}
            name="prefectures"
            render={({ field }) => (
              <TextField
                select
                {...field}
                autoFocus
                id="inputPrefectures"
                color="secondary"
                margin="normal"
                required
                style={{ width: 160, marginRight: 10 }}
                label="都道府県"
                inputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                type="text"
              >
                {locale.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            control={control}
            name="cities"
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                id="inputCities"
                color="secondary"
                margin="normal"
                required
                style={{ width: 160, marginleft: 500 }}
                label="市区町村"
                inputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                type="text"
              />
            )}
          />
        </div>
        <Controller
          control={control}
          name="address"
          render={({ field }) => (
            <TextField
              {...field}
              autoFocus
              id="inputAddress"
              color="secondary"
              margin="normal"
              required
              fullWidth
              label="番地・建物名等"
              inputProps={{ style: { fontSize: 14 } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
              type="text"
            />
          )}
        />
      </div>
      <h1 css={styles.h1}>イベント開催日時の設定</h1>
      <div css={styles.div}>
        <Controller
          control={control}
          name="startDate"
          render={({ field }) => (
            <TextField
              {...field}
              onBlur={validateDateRange}
              id="inputStartDate"
              label="開催日"
              type="date"
              required
              color="secondary"
              variant="outlined"
              margin="normal"
              fullWidth
              error={isDateError}
              InputLabelProps={{ style: { fontSize: 14 } }}
              inputProps={{ style: { fontSize: 14 } }}
              style={{ marginRight: 10 }}
            />
          )}
        />
        <Controller
          control={control}
          name="startTime"
          render={({ field }) => (
            <TextField
              {...field}
              onBlur={validateDateRange}
              id="startTime"
              type="time"
              required
              color="secondary"
              variant="outlined"
              margin="normal"
              fullWidth
              error={isDateError}
              InputLabelProps={{ style: { fontSize: 14 } }}
              inputProps={{ style: { fontSize: 14 } }}
            />
          )}
        />
      </div>
      <div css={styles.div}>
        <Controller
          control={control}
          name="endDate"
          render={({ field }) => (
            <TextField
              {...field}
              onBlur={validateDateRange}
              id="endDate"
              label="終了日"
              type="date"
              required
              color="secondary"
              variant="outlined"
              margin="normal"
              fullWidth
              error={isDateError}
              InputLabelProps={{ style: { fontSize: 14 } }}
              inputProps={{ style: { fontSize: 14 } }}
              style={{ marginRight: 10 }}
            />
          )}
        />
        <Controller
          control={control}
          name="endTime"
          render={({ field }) => (
            <TextField
              {...field}
              onBlur={validateDateRange}
              id="endTime"
              type="time"
              required
              color="secondary"
              variant="outlined"
              margin="normal"
              fullWidth
              error={isDateError}
              InputLabelProps={{ style: { fontSize: 14 } }}
              inputProps={{ style: { fontSize: 14 } }}
            />
          )}
        />
      </div>

      {isDateError && (
        <FormHelperText sx={{ color: "red", textAlign: "center" }}>開始・終了日時をご確認下さい。</FormHelperText>
      )}
      {/* <Controller
        control={control}
        name="venue"
        render={({ field }) => (
          <TextField
            {...field}
            select
            id="venue"
            label="開催場所"
            required
            variant="outlined"
            color="secondary"
            margin="normal"
            fullWidth
            InputLabelProps={{ style: { fontSize: 14 } }}
          >
            {venues.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <div style={{ fontSize: 14, textAlign: "left" }}>{option.label}</div>
              </MenuItem>
            ))}
          </TextField>
        )}
      /> */}

      <Box sx={{ mb: 2 }}>
        <div>
          <Button onClick={() => onSubmit("back")} sx={{ mt: 1, mr: 1 }}>
            戻る
          </Button>
          <Button type="submit" disabled={props.status} variant="contained" sx={{ mt: 1, mr: 1 }}>
            次へ
          </Button>
        </div>
      </Box>
    </form>
  );
};

export default NewDetails;

const NewDetailsTitle = () => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  return (
    <div>
      {!isWide && (
        <h1 css={styles.h2}>
          イベントの基本情報を書く
          <br />
          <br />
          いつ、どこで、どんなイベント
          <br />
          を開催するのか
        </h1>
      )}
    </div>
  );
};

export { NewDetailsTitle };
