import React from "react";

// 外部パッケージ
import { useNavigate } from "react-router-dom";

// MUI
import {
  ThemeProvider,
  Button,
  Box,
  Typography,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// その他
import theme from "components/MuiTheme";
import { useAuth } from "components/auth/AuthCommon";
import LogoImg from "components/img/Eventer_logo_red.png";

const description = {
  textAlign: "left",
};

const buttonStyle = {
  marginTop: 15,
  height: 40,
};

// ここからメインのSignUpコンポーネント
function ChangePassword() {
  const [loading, setLoading] = React.useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const [values, setValues] = React.useState({});
  const handleClickShowPassword0 = () => {
    setValues({
      ...values,
      showPassword0: !values.showPassword0,
    });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowPassword1 = () => {
    setValues({
      ...values,
      showPassword1: !values.showPassword1,
    });
  };
  const handleMouseDownPassword0 = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const [state, setState] = React.useState({
    oldPassword: "",
    newPassword: "",
    newPassword1: "",
  });

  const [passMatch, setPassMatch] = React.useState(true);

  React.useEffect(() => {
    validatePassword();
  });

  const validatePassword = () => {
    state.newPassword === state.newPassword1 ? setPassMatch(true) : setPassMatch(false);
  };
  const handleChange1 = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const onChangePassword = async (event) => {
    if (auth.isLoading) {
      return;
    }
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const result = await auth.changePassword(data.get("oldPassword"), data.get("newPassword"));

    if (result.success) {
      navigate("/changePasswordCompleted");
    } else {
      alert(result.message);
    }
    setLoading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: theme.palette.grey[200],
        }}
      >
        <Box
          component="form"
          onSubmit={onChangePassword}
          noValidate
          style={{
            marginTop: 20,
            marginBottom: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: " 0 30px 30px 30px",
            background: "white",
            borderRadius: 5,
            width: 290,
          }}
        >
          <img src={LogoImg} style={{ width: 150, height: 150 }} alt="logo" />
          <Typography
            component="h1"
            variant="h1"
            style={{
              width: 300,
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            パスワード変更
          </Typography>
          <div style={description}>
            現在のパスワードと新しいパスワードを
            <br />
            入力してください。
          </div>
          <FormControl sx={{ paddingTop: 3.3, width: "30ch" }} variant="outlined">
            <InputLabel sx={{ paddingTop: 3.3 }} color="secondary" htmlFor="outlined-adornment-password">
              現在のパスワード
            </InputLabel>
            <OutlinedInput
              aria-label="Password"
              className="form-control"
              color="secondary"
              required
              fullWidth
              name="oldPassword"
              label="現在のパスワード"
              id="oldPassword"
              pattern="^[0-9a-zA-Z]+$"
              inputProps={{ minLength: 8 }}
              autoComplete="old-password"
              type={values.showPassword0 ? "text" : "password"}
              onChange={handleChange1}
              aria-required="true"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword0}
                    onMouseDown={handleMouseDownPassword0}
                    edge="end"
                  >
                    {values.showPassword0 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl sx={{ paddingTop: 3.3, width: "30ch" }} variant="outlined">
            <InputLabel sx={{ paddingTop: 3.3 }} color="secondary" htmlFor="outlined-adornment-password">
              新しいパスワード
            </InputLabel>
            <OutlinedInput
              aria-label="Password"
              className="form-control"
              color="secondary"
              required
              fullWidth
              name="newPassword"
              label="新しいパスワード"
              id="newPassword"
              pattern="^[0-9a-zA-Z]+$"
              inputProps={{ minLength: 8 }}
              autoComplete="new-password"
              type={values.showPassword ? "text" : "password"}
              onChange={handleChange1}
              aria-required="true"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl sx={{ paddingTop: 3.3, width: "30ch" }} variant="outlined">
            <InputLabel
              sx={{ paddingTop: 3.3 }}
              color={passMatch ? "secondary" : "error"}
              htmlFor="outlined-adornment-password"
            >
              {passMatch ? "パスワード（確認用）" : "新しいパスワードが一致しません"}
            </InputLabel>
            <OutlinedInput
              aria-label="Confirm Password"
              aria-required="true"
              aria-invalid={passMatch ? true : false}
              color={passMatch ? "secondary" : "error"}
              required
              fullWidth
              name="newPassword"
              label={passMatch ? "パスワード（確認用）" : "新しいパスワードが一致しません"}
              id="newPassword1"
              pattern="^[0-9a-zA-Z]+$"
              inputProps={{ minLength: 8 }}
              autoComplete="new-password"
              type={values.showPassword1 ? "text" : "password"}
              onChange={handleChange1}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword1}
                    edge="end"
                  >
                    {values.showPassword1 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <ThemeProvider theme={theme}>
            <Button
              style={{
                marginTop: 15,
              }}
              type="submit"
              fullWidth
              variant="contained"
            >
              {loading ? "変更中..." : "パスワードの変更"}
            </Button>
          </ThemeProvider>
          <Button style={buttonStyle} fullWidth onClick={() => navigate("/profile")}>
            戻る
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ChangePassword;
