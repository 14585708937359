import React from "react";

// 外部パッケージ
import useMedia from "use-media";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

//  MUI
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
// import YouTubeIcon from "@mui/icons-material/YouTube";
import List from "@mui/material/List";

// その他
// import { TikTokIcon } from "./common/Icons";
import FooterLinkList from "components/FooterLinkList";
import theme from "components/MuiTheme";

const styles = {
  footerDiv: css`
    background-color: #222222;

    > div:last-of-type {
      margin-bottom: 10px;
    }

    .link {
      color: #ffffff;
      margin-left: 10px;
      margin-right: 10px;
      text-decoration: none;
    }
  `,

  iconRowDiv: css`
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,

  companyNameRowDiv: css`
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
  `,

  companyInfoList: css`
    height: auto;
    display: flex;
    font-size: 0.9rem;
    font-weight: 300;
    justify-content: left;
  `,

  companyInfoListPc: css`
    flex-direction: row;
    align-items: center;
  `,

  companyInfoListSp: css`
    flex-direction: column;
    align-items: flex-start;
  `,

  link: css`
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    :hover {
      text-decoration: underline;
    }
  `,
};

const Footer = (props) => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });
  return (
    <div css={styles.footerDiv}>
      <div css={styles.iconRowDiv}>
        <a className="link" href="https://twitter.com/Eventer_inc" target="_blank" rel="noopener noreferrer">
          <TwitterIcon sx={{ color: "#FFFFFF" }} />
        </a>

        <a className="link" href="https://www.instagram.com/eventer_inc/" target="_blank" rel="noopener noreferrer">
          <InstagramIcon sx={{ color: "#FFFFFF" }} />
        </a>
        {/* <a
          className="link"
          href="https://www.youtube.com/channel/UC4j5XHae_pjdqqcj46WwSug"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YouTubeIcon sx={{ color: "#FFFFFF" }} />
        </a>
        <a
          className="link"
          href="https://www.youtube.com/channel/UC4j5XHae_pjdqqcj46WwSug"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TikTokIcon size={24} />
        </a> */}
      </div>

      <a css={styles.companyNameRowDiv} className="link" href="/">
        Eventer
      </a>

      <List css={[styles.companyInfoList, isWide ? styles.companyInfoListSp : styles.companyInfoListPc]}>
        {FooterLinkList.map((link) => (
          <div key={link.key}>
            {link.url ? (
              <Link css={styles.link} className="link" to={link.url}>
                {link.value}
              </Link>
            ) : (
              <a className="link" css={styles.link} href={link.externalUrl} target="_blank" rel="noopener noreferrer">
                {link.value}
              </a>
            )}
          </div>
        ))}
      </List>
    </div>
  );
};

export default Footer;
