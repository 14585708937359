import React from "react";

// 外部パッケージ
import useMedia from "use-media";

// その他
import MessagePC from "components/Message/MessagePC";
import MessageSP from "components/Message/MessageSP";

const Message = () => {
  const isWide = useMedia({ maxWidth: 800 });
  return <div>{isWide ? <MessageSP /> : <MessagePC />}</div>;
};

export default Message;
