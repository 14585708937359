import { useState, useCallback } from "react";
import { Storage } from "aws-amplify";
import imageCompression from "browser-image-compression";

/**
 * S3にファイルをアップロード・ダウンロードするHooks。
 * @returns アップロード・ダウンロード関数と状態を示すbooleanの配列
 */
export const useS3 = () => {
  const [isS3Loading, setIsS3Loading] = useState(false);

  type putFileToS3OptionsType = {
    prefix?: string;
    maxWidthOrHeight?: number;
  };
  /**
   * Function to upload a file to S3.
   * @param fileName The name of the file to upload.
   * @param fileData The data of the file to upload.
   * @param options Options for uploading the file.
   * @returns A Promise object that returns the uploaded data on success, or an error on failure.
   */
  const putFileToS3 = useCallback(async (fileName: string, fileData: File, options?: putFileToS3OptionsType) => {
    try {
      setIsS3Loading(true); // Update the loading state to true

      const prefix = options?.prefix;
      const maxWidthOrHeight = options?.maxWidthOrHeight || 600;

      let key = fileName;
      if (prefix) {
        key = `${prefix}/${fileName}`; // Add the prefix to the key
      }

      // TODO アップロードできるファイルのサイズを制限した方が良さそう
      const compressedFileData = await imageCompression(fileData, {
        useWebWorker: true,
        fileType: fileData.type,
        maxWidthOrHeight: maxWidthOrHeight,
      });

      const config = {
        level: "public",
        contentType: compressedFileData.type,
        metadata: {
          "Cache-Control": "public, max-age=31536000, immutable",
        },
      } as any;

      await Storage.put(key, compressedFileData, config);

      const response = {
        key: key,
        success: true,
        message: `画像のアップロードに成功しました。（Storage）`,
        error: null,
      };

      return Promise.resolve(response); // Upload success
    } catch (error: unknown) {
      const response = {
        key: null,
        success: false,
        message: `画像のアップロードに失敗しました。（Storage）`,
        error: (error as Error).message,
      };

      return Promise.reject(response); // Upload failure
    } finally {
      setIsS3Loading(false); // Update the loading state
    }
  }, []);

  type getFileFromS3OptionsType = {
    prefix?: string;
  };
  /**
   * Function to download a file from S3.
   * @param fileName The name of the file to download.
   * @param options Options for downloading the file.
   * @returns A Promise object that returns the downloaded data on success, or an error on failure.
   */
  const getFileFromS3 = useCallback(async (fileName: string, options?: getFileFromS3OptionsType) => {
    try {
      setIsS3Loading(true); // Update the loading state to true

      let key = fileName;
      if (options?.prefix) {
        key = `${options.prefix}/${fileName}`; // Add the prefix to the key
      }
      const result = await Storage.get(key);

      return result; // Download success
    } catch (error) {
      const response = {
        key: null,
        success: false,
        message: `画像のダウンロードに失敗しました。（Storage）`,
        error: (error as Error).message,
      };

      return Promise.reject(response); // Download failure
    } finally {
      setIsS3Loading(false); // Update the loading state
    }
  }, []);

  type RemoveFileFromS3OptionsType = {
    prefix?: string;
  };

  /**
   * Function to remove a file from S3.
   * @param fileName The name of the file to remove.
   * @param options Options for removing the file.
   * @returns A Promise object that returns a success message on success, or an error on failure.
   */
  const removeFileFromS3 = useCallback(async (fileName: string, options?: RemoveFileFromS3OptionsType) => {
    try {
      setIsS3Loading(true); // Update the loading state to true

      let key = fileName;
      if (options?.prefix) {
        key = `${options.prefix}/${fileName}`; // Add the prefix to the key
      }
      await Storage.remove(key);

      return Promise.resolve({ success: true, message: "File removed successfully." }); // Removal success
    } catch (error: unknown) {
      const response = {
        key: null,
        success: false,
        message: "画像の削除に失敗しました。 (Storage)",
        error: (error as Error).message,
      };

      return Promise.reject(response); // Removal failure
    } finally {
      setIsS3Loading(false); // Update the loading state
    }
  }, []);

  /**
   * Function to replace an existing file on S3 with a new one.
   * It first removes the old file and then uploads the new file.
   *
   * @param {string} oldFileName - The name of the file to be replaced.
   * @param {string} newFileName - The name of the new file to be uploaded.
   * @param {File} newFileData - The data of the new file to be uploaded.
   * @param {putFileToS3OptionsType & RemoveFileFromS3OptionsType} [options] - Options for removing the old file and uploading the new one.
   * @returns {Promise<any>} A Promise object that returns the uploaded data on success, or an error on failure.
   */
  const replaceFileToS3 = useCallback(
    async (
      oldFileName: string,
      newFileName: string,
      newFileData: File,
      options?: putFileToS3OptionsType & RemoveFileFromS3OptionsType
    ): Promise<any> => {
      try {
        setIsS3Loading(true); // Update the loading state to true

        // Remove old file
        await removeFileFromS3(oldFileName, options);

        // Upload new file
        const putResponse = await putFileToS3(newFileName, newFileData, options);

        return Promise.resolve(putResponse); // Update success
      } catch (error: unknown) {
        const response = {
          key: null,
          success: false,
          message: "画像の更新に失敗しました。（Storage）",
          error: (error as Error).message,
        };

        return Promise.reject(response); // Update failure
      } finally {
        setIsS3Loading(false); // Update the loading state
      }
    },
    [removeFileFromS3, putFileToS3]
  );

  return { putFileToS3, getFileFromS3, removeFileFromS3, replaceFileToS3, isS3Loading };
};
