import React from "react";

// MUI
import MessageSettingIcon from "@mui/icons-material/Settings";
import Button from "@mui/material/Button";

// 外部パッケージ
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// スタイル
const styles = {
  mesSetOutline: css`
    border: "solid #808080",
    borderWidth: "1px",
  `,
  mesSetDispNameBase: css`
    textAlign: "center",
    borderBottom: "solid #808080",
    borderWidth: "1px",
  `,
  mesSetDispNameStr: css`
    fontSize: "25px",
    fontWeight: "bold",
    display: "inline-block",
    `,
  mesSetCheckBoxMouseOver: css`
    cursor: "pointer";
  `,
  mesSetFuncTitle: css`
    textalign: "center";
  `,
  mesSetFuncStrMouseOver: css`
    fontWeight: "bold",
    fontSize: "1.15em",
    cursor: "pointer",
  `,
  mesSetDelFuncStrMouseOver: css`
  fontWeight: "bold",
  fontSize: "1.15em",
  cursor: "pointer",
  color: "red",
  `,
  mesSetLineSpace: css`
    margintop: "5px";
  `,
};

// const MesSetOutline = {
//   border: "solid #808080",
//   borderWidth: "1px",
// };

// const MesSetDispNameBase = {
//   textAlign: "center",
//   borderBottom: "solid #808080",
//   borderWidth: "1px",
// };

// const MesSetDispNameStr = {
//   fontSize: "25px",
//   fontWeight: "bold",
//   display: "inline-block",
// };

// const MesSetCheckBoxMouseOver = {
//   cursor: "pointer",
// };

// const MesSetFuncTitle = {
//   textAlign: "center",
// };

// const MesSetFuncStrMouseOver = {
//   fontWeight: "bold",
//   fontSize: "1.15em",
//   cursor: "pointer",
// };

// const MesSetDelFuncStrMouseOver = {
//   fontWeight: "bold",
//   fontSize: "1.15em",
//   cursor: "pointer",
//   color: "red",
// };

// const MesSetLineSpace = {
//   marginTop: "5px",
// };

const MessageSettingPC = (props) => {
  return (
    <div style={styles.mesSetOutline}>
      <div style={styles.mesSetDispNameBase}>
        <div style={styles.mesSetDispNameStr}>メッセージ設定</div>
        <MessageSettingIcon sx={{ fontSize: "2rem" }} style={{ color: "#000000" }} />
      </div>
      <p style={styles.mesSetFuncTitle}>
        <h2>＊＊＊＊＊＊＊＊＊＊＊＊＊【全般】＊＊＊＊＊＊＊＊＊＊＊＊＊</h2>
      </p>
      <div>
        <label>
          <input type="checkbox" name="" value={"1"} style={styles.mesSetCheckBoxMouseOver} />
          &ensp;<span style={styles.mesSetFuncStrMouseOver}>既読通知を表示する</span>
        </label>
        <p style={styles.mesSetLineSpace}>&emsp;&emsp;・相手側のメッセージであなたの既読をお知らせます。</p>
      </div>
      {props.isExistIndividual ? (
        <div>
          <br />
          <p style={styles.mesSetFuncTitle}>
            <h2>＊＊＊＊＊＊＊＊＊＊＊＊＊【個別】＊＊＊＊＊＊＊＊＊＊＊＊＊</h2>
          </p>
          <div>
            <label>
              <input type="checkbox" name="" value={"1"} style={styles.mesSetCheckBoxMouseOver} />
              &ensp;
              <span style={styles.mesSetFuncStrMouseOver}>通知をスヌーズする</span>
            </label>
            <p style={styles.mesSetLineSpace}>
              &emsp;&emsp;・イベント太郎（@EventTaro）さんからメッセージ通知をお知らせしないようにします。
            </p>
          </div>
          <br />
          <div>
            <label>
              <input type="checkbox" name="" value={"1"} style={styles.mesSetCheckBoxMouseOver} />
              &ensp;
              <span style={styles.mesSetFuncStrMouseOver}>ブロックする</span>
            </label>
            <p style={styles.mesSetLineSpace}>
              &emsp;&emsp;・イベント太郎（@EventTaro）さんからあなたにメッセージを送信できなくします。
            </p>
          </div>
          <br />
          <div>
            <label>
              <input type="checkbox" name="" value={"1"} style={styles.mesSetCheckBoxMouseOver} />
              &ensp;
              <span style={styles.mesSetDelFuncStrMouseOver}>メッセージを削除する</span>
            </label>
            <p style={styles.mesSetLineSpace}>
              &emsp;&emsp;・イベント太郎（@EventTaro）さんとのこれまでのメッセージのやり取りをすべて削除します。
            </p>
            <p style={styles.mesSetLineSpace}>&emsp;&emsp;※削除すると退出し、メッセージを表示できなくなります。</p>
            <p style={styles.mesSetLineSpace}>&emsp;&emsp;※相手側のメッセージ履歴は削除されません。</p>
          </div>
        </div>
      ) : (
        <></>
      )}
      <br />
      <p style={styles.mesSetFuncTitle}>
        <h2>＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊</h2>
      </p>
      <div style={{ textAlign: "center" }}>
        <Button type="submit" variant="contained" sx={{ mt: 1, mr: 1 }} style={{ backgroundColor: "#DB3531" }}>
          保存
        </Button>
      </div>
      <br />
    </div>
  );
};
export default MessageSettingPC;
