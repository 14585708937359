import React from "react";

// 外部パッケージ
import { useNavigate } from "react-router-dom";

// MUI
import { ThemeProvider, FormControlLabel, Checkbox, Button, Box, Typography } from "@mui/material";

// AWS
import { API, graphqlOperation } from "aws-amplify";

// その他
import { updateUser } from "graphql/mutations";
import theme from "components/MuiTheme";
import { useAuth } from "components/auth/AuthCommon";
import LogoImg from "components/img/Eventer_logo_red.png";
import { getDebugger } from "components/Debugger";

// スタイル
const root = {
  backgroundColor: theme.palette.grey[200],
  paddingTop: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: 14,
};

const rootLogIn = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: 20,
  padding: " 0 30px 30px 30px",
  background: "white",
  borderRadius: 5,
  width: 284,
};

const upper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const upperLogo = { width: 150, height: 150 };

const title = {
  width: 264,
  display: "flex",
  justifyContent: "center",
  fontSize: 24,
  paddingTop: 20,
  paddingBottom: 20,
};

const loginButton = {
  marginTop: 10,
};

const buttonStyle = {
  marginTop: 15,
  height: 40,
};

var debug = getDebugger(false);
// TODO [Lambda]退会時に退会完了メール送信
function DeleteUser() {
  // チェックボックスをチェックするとボタンが押せるようにする
  const [isChecked, setIsChecked] = React.useState(false);
  const [isDBLoading, setIsDBLoading] = React.useState(false);
  const checkboxPressed = () => {
    setIsChecked((prevState) => !prevState);
  };

  const auth = useAuth();
  const navigate = useNavigate();

  const onDeleteUserPressed = async (event) => {
    if (auth.isLoading) return;
    event.preventDefault();
    deleteCognitoUser();
    updateAccountStatusInUser();
    navigate("/");
  };

  const deleteCognitoUser = async (event) => {
    const result = await auth.deleteUser();
    if (result.success) {
      alert(result.message);
    } else {
      alert(result.message);
    }
  };

  const updateAccountStatusInUser = async (async) => {
    const variables = {
      input: {
        id: auth.userID,
        status: "DELETED",
      },
    };

    try {
      setIsDBLoading(true);
      await API.graphql(graphqlOperation(updateUser, variables));
    } catch {
      debug("退会処理中にエラーが発生しました。");
    } finally {
      setIsDBLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box style={root}>
        <Box component="form" onSubmit={onDeleteUserPressed} noValidate style={rootLogIn}>
          <div style={upper}>
            <img src={LogoImg} style={upperLogo} alt="logo" />
            <Typography component="h1" variant="h1" style={title}>
              アカウントを削除しますか？
            </Typography>
            <div>
              お客様の個人情報を恒久的にEventerのシステムから削除します。
              <br />
              参加者の方はイベントにアクセス出来なくなり、主催者の方は全てのイベントが終了またはキャンセルされている必要があります。
              <br />
              削除した会員情報の復元はできません。
            </div>
          </div>
          <FormControlLabel
            control={<Checkbox onClick={checkboxPressed} color="primary" />}
            checked={isChecked}
            label="同意して退会する"
          />
          <Button disabled={!isChecked} type="submit" fullWidth variant="contained" style={loginButton}>
            {auth.isLoading || isDBLoading ? "退会中..." : "退会する"}
          </Button>

          <Button style={buttonStyle} fullWidth onClick={() => navigate("/profile")}>
            戻る
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default DeleteUser;
