import React, { useEffect } from "react";

// 外部パッケージ
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// AWS
import { API, graphqlOperation } from "aws-amplify";

// MUI
import { Checkbox, Tooltip, Rating } from "@mui/material";
import { pink } from "@mui/material/colors";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleIcon from "@mui/icons-material/People";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import WcIcon from "@mui/icons-material/Wc";
import WifiIcon from "@mui/icons-material/Wifi";
import TvIcon from "@mui/icons-material/Tv";
import KitchenIcon from "@mui/icons-material/Kitchen";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import SpeakerIcon from "@mui/icons-material/Speaker";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import DeckIcon from "@mui/icons-material/Deck";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CameraIndoorIcon from "@mui/icons-material/CameraIndoor";
import PowerIcon from "@mui/icons-material/Power";

// その他
import theme from "components/MuiTheme";
import { UserIcon } from "components/common/UserIcon";
import { useAuth } from "components/auth/AuthCommon";
import { spaces } from "components/common/SelectValues";
import { convertValueToLabel } from "components/common/Utils";
import { getSpace } from "graphql/queries";

// TODO: [S3]イベントIDからサムネイルを取得する
// TODO: [DB]イベント情報の取得
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import line0 from "components/img/home/LINE_ALBUM_211123_0.jpg";
import line1 from "components/img/home/LINE_ALBUM_211123_1.jpg";
import line2 from "components/img/home/LINE_ALBUM_211123_2.jpg";
import line3 from "components/img/home/LINE_ALBUM_211123_3.jpg";
import line4 from "components/img/home/LINE_ALBUM_211123_4.jpg";
import line5 from "components/img/home/LINE_ALBUM_211123_5.jpg";
import line6 from "components/img/home/LINE_ALBUM_211123_6.jpg";
import line7 from "components/img/home/LINE_ALBUM_211123_7.jpg";
import line8 from "components/img/home/LINE_ALBUM_211123_8.jpg";
import line9 from "components/img/home/LINE_ALBUM_211123_9.jpg";
import line10 from "components/img/home/LINE_ALBUM_211123_10.jpg";
import line11 from "components/img/home/LINE_ALBUM_211123_11.jpg";
import line12 from "components/img/home/LINE_ALBUM_211123_12.jpg";
import line13 from "components/img/home/LINE_ALBUM_211123_13.jpg";
import line14 from "components/img/home/LINE_ALBUM_211123_14.jpg";
import line15 from "components/img/home/LINE_ALBUM_211123_15.jpg";
import line16 from "components/img/space/1.png";
import line17 from "components/img/space/2.png";
import line18 from "components/img/space/3.png";
import line19 from "components/img/space/4.png";
import line20 from "components/img/space/5.png";
import line21 from "components/img/space/6.png";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

// ここからスタイル↓
const styles = {
  spaceThumbnailDiv: css`
    width: 330px;
    background-color: #f1f1f1;
    text-align: left;
    margin: 5px 5px 5px 5px;
    border-radius: 10px;
    position: relative;
  `,
  spaceThumbnailImg: css`
    width: 330px;
    height: 330px;
    border-radius: 10px 10px 0px 0px;
  `,

  spaceRow1: css`
    display: flex;
    justify-content: space-between;
    margin: 5px 10px 5px 15px;
  `,

  spaceName: css`
    font-weight: 800;
    display: block;
    vertical-align: center;
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    :hover {
      text-decoration: underline;
    }
  `,

  spaceRow2: css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    align-self: center;
    margin: 5px 10px 5px 10px;
  `,

  categorySpan: css`
    font-size: 0.9rem;
    background-color: ${theme.palette.secondary.light};
    color: ${theme.palette.secondary.contrastText};
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.6rem;
    margin-right: 0.1rem;
    font-weight: 500;
  `,

  spaceRow3: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 5px 5px 10px;
  `,

  spaceRow3Col: css`
    display: flex;
    flex-direction: column;
  `,

  spaceRow3Col2: css`
    display: flex;
    flex-direction: column;
  `,

  spaceRow3Col2Row1: css`
    display: flex;
    flex-direction: row;

    div {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
    }

    & :not(:last-child) {
      margin-right: 0.5rem;
    }
  `,

  spaceRow3Col2Row2: css`
    display: flex;
    flex-direction: row;

    div {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
    }

    & div:not(:last-of-type) {
      margin-right: 0.5rem;
    }
  `,

  ownerEvaluation: css`
    display: flex;
    align-items: center;
  `,
};

const favariteIconStyle = {
  display: "block",
  paddingTop: "0",
  defaultColor: "default",
  color: pink[600],
  "&.Mui-checked": {
    color: pink[600],
    paddingRight: "auto",
  },
  position: "absolute",
  top: "2%",
  right: "2%",
};

// TODO [DB][S3]DBからサムネイル画像のキーを取得し、S3からダウンロードする
// ベータ版用
const getSpaceThumbnail = (spaceId) => {
  let url = "";
  if (spaceId === "line0") url = line0;
  else if (spaceId === "line1") url = line1;
  else if (spaceId === "line2") url = line2;
  else if (spaceId === "line3") url = line3;
  else if (spaceId === "line4") url = line4;
  else if (spaceId === "line5") url = line5;
  else if (spaceId === "line6") url = line6;
  else if (spaceId === "line7") url = line7;
  else if (spaceId === "line8") url = line8;
  else if (spaceId === "line9") url = line9;
  else if (spaceId === "line10") url = line10;
  else if (spaceId === "line11") url = line11;
  else if (spaceId === "line12") url = line12;
  else if (spaceId === "line13") url = line13;
  else if (spaceId === "line14") url = line14;
  else if (spaceId === "line15") url = line15;
  else if (spaceId === "line16") url = line16;
  else if (spaceId === "line17") url = line17;
  else if (spaceId === "line18") url = line18;
  else if (spaceId === "line19") url = line19;
  else if (spaceId === "line20") url = line20;
  else if (spaceId === "line21") url = line21;
  return <img src={url} css={styles.spaceThumbnailImg} alt="サムネイル" />;
};

const SpaceThumbnail = (props) => {
  const [value] = React.useState(5);
  const auth = useAuth();
  const navigate = useNavigate();
  const [currentState, setCurrentState] = React.useState({});
  const toEvent = () => {
    navigate("/event", { state: { currentState: currentState, userID: auth.userID } });
  };

  const [values, setValues] = React.useState({
    name: "",
    categoryID: "",
    size: "",
    capacity: "",
    postalCode: "",
    prefCode: "",
    city: "",
    contact: "",
    feePerHour: "",
    userID: "",
  });

  // 初期処理
  useEffect(() => {
    const runAPI = async () => {
      if (props.spaceID === "") return;
      const result = await API.graphql(graphqlOperation(getSpace, { id: props.spaceID }));
      setValues({
        name: result.data.getSpace.name,
        categoryID: result.data.getSpace.categoryID,
        size: result.data.getSpace.size,
        capacity: result.data.getSpace.capacity,
        postalCode: result.data.getSpace.postalCode,
        prefCode: result.data.getSpace.prefCode,
        city: result.data.getSpace.city,
        address: result.data.getSpace.address,
        contact: result.data.getSpace.contact,
        feePerHour: result.data.getSpace.feePerHour,
        AvailableFacilities: result.data.getSpace.AvailableFacilities,
        startDate: result.data.getSpace.startDate,
        endDate: result.data.getSpace.endDate,
        userID: result.data.getSpace.userID,
      });
    };
    runAPI().catch((error) => {
      console.log("スペースの取得に失敗しました");
    });
  });

  return (
    <div css={styles.spaceThumbnailDiv}>
      {/* {terest} */}
      {/* <img src={ThumbnailImg0} style={thumbnailImgStyle} alt='サムネイル' />  */}
      {getSpaceThumbnail(props.spaceId)}
      <Checkbox style={favariteIconStyle} {...label} icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
      <div css={styles.spaceRow1}>
        <div css={styles.spaceName}> {values.name} </div>
        <span css={styles.categorySpan}>{convertValueToLabel(spaces, values.categoryID)}</span>
      </div>
      <div css={styles.spaceRow2}>
        <Tooltip title="電源">
          <PowerIcon sx={{ color: "#999999", marginRight: "-5px" }} fontSize={"small"} />
        </Tooltip>
        <Tooltip title="エアコン">
          <DeviceThermostatIcon sx={{ color: "#999999", marginRight: "-5px" }} fontSize={"small"} />
        </Tooltip>
        <Tooltip title="トイレ">
          <WcIcon sx={{ color: "#999999" }} fontSize={"small"} />
        </Tooltip>
        <Tooltip title="Wi-Fi">
          <WifiIcon sx={{ color: "#999999" }} fontSize={"small"} />
        </Tooltip>
        <Tooltip title="モニター">
          <TvIcon sx={{ color: "#999999" }} fontSize={"small"} />
        </Tooltip>
        <Tooltip title="冷蔵庫">
          <KitchenIcon sx={{ color: "#999999" }} fontSize={"small"} />
        </Tooltip>
        <Tooltip title="電子レンジ">
          <MicrowaveIcon sx={{ color: "#999999" }} fontSize={"small"} />
        </Tooltip>
        <Tooltip title="キッチン">
          <RestaurantIcon sx={{ color: "#999999" }} fontSize={"small"} />
        </Tooltip>
        <Tooltip title="スピーカー">
          <SpeakerIcon sx={{ color: "#999999" }} fontSize={"small"} />
        </Tooltip>
        <Tooltip title="BBQ設備">
          <OutdoorGrillIcon sx={{ color: "#999999" }} fontSize={"small"} />
        </Tooltip>
        <Tooltip title="着替えルーム">
          <CheckroomIcon sx={{ color: "#999999" }} fontSize={"small"} />
        </Tooltip>
        <Tooltip title="テラス">
          <DeckIcon sx={{ color: "#999999" }} fontSize={"small"} />
        </Tooltip>
        <Tooltip title="敷地内無料駐車場">
          <LocalParkingIcon sx={{ color: "#999999" }} fontSize={"small"} />
        </Tooltip>
        <Tooltip title="敷地内有料駐車場">
          <DriveEtaIcon sx={{ color: "#999999" }} fontSize={"small"} />
        </Tooltip>

        <Tooltip title="ヨガマット">
          <SelfImprovementIcon sx={{ color: "#999999" }} fontSize={"small"} />
        </Tooltip>
        <Tooltip title="防犯カメラ">
          <CameraIndoorIcon sx={{ color: "#999999" }} fontSize={"small"} />
        </Tooltip>
      </div>
      <div css={styles.spaceRow3}>
        <div css={styles.spaceRow3Col1}>
          <div css={styles.ownerIcon}></div>
        </div>
        <div css={styles.spaceRow3Col2}>
          <div css={styles.spaceRow3Col2Row1}>
            <div>
              <LocationOnIcon sx={{ color: "#999999" }} fontSize={"small"} />
              {values.prefCode}
              {values.city}
            </div>
            <div>
              <HomeIcon sx={{ color: "#999999" }} fontSize={"small"} />
              {values.size}㎡
            </div>
            <div>
              <PeopleIcon sx={{ color: "#999999" }} fontSize={"small"} />〜{values.capacity}人
            </div>
          </div>
          <div css={styles.spaceRow3Col2Row2}>
            <div>
              <CurrencyYenIcon sx={{ color: "#999999" }} fontSize={"small"} />
              {values.feePerHour}円／h
            </div>
            <div>
              <UserIcon
                userID={values.userID}
                size={25}
                options={{ labeled: true, enableLink: true }}
                alt="プロフィール"
              />
              {/* // FIXME valuesから点数を取得してRatingのvalueと「5.0」の箇所を書き換えましょう。テーブルに項目がなかったら修正するので言ってください！ */}
              (<Rating value={5.0} readOnly size="small" /> 5.0)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpaceThumbnail;
