/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      messagegroupID
      userID
      sendAt
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      messagegroupID
      userID
      sendAt
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      messagegroupID
      userID
      sendAt
      content
      createdAt
      updatedAt
    }
  }
`;
export const createMessageMember = /* GraphQL */ `
  mutation CreateMessageMember(
    $input: CreateMessageMemberInput!
    $condition: ModelMessageMemberConditionInput
  ) {
    createMessageMember(input: $input, condition: $condition) {
      id
      messagegroupID
      userID
      groupAddedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageMember = /* GraphQL */ `
  mutation UpdateMessageMember(
    $input: UpdateMessageMemberInput!
    $condition: ModelMessageMemberConditionInput
  ) {
    updateMessageMember(input: $input, condition: $condition) {
      id
      messagegroupID
      userID
      groupAddedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageMember = /* GraphQL */ `
  mutation DeleteMessageMember(
    $input: DeleteMessageMemberInput!
    $condition: ModelMessageMemberConditionInput
  ) {
    deleteMessageMember(input: $input, condition: $condition) {
      id
      messagegroupID
      userID
      groupAddedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMessageGroup = /* GraphQL */ `
  mutation CreateMessageGroup(
    $input: CreateMessageGroupInput!
    $condition: ModelMessageGroupConditionInput
  ) {
    createMessageGroup(input: $input, condition: $condition) {
      id
      userID
      messageGroupName
      createdAt
      MessageMembers {
        items {
          id
          messagegroupID
          userID
          groupAddedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      Messages {
        items {
          id
          messagegroupID
          userID
          sendAt
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateMessageGroup = /* GraphQL */ `
  mutation UpdateMessageGroup(
    $input: UpdateMessageGroupInput!
    $condition: ModelMessageGroupConditionInput
  ) {
    updateMessageGroup(input: $input, condition: $condition) {
      id
      userID
      messageGroupName
      createdAt
      MessageMembers {
        items {
          id
          messagegroupID
          userID
          groupAddedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      Messages {
        items {
          id
          messagegroupID
          userID
          sendAt
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteMessageGroup = /* GraphQL */ `
  mutation DeleteMessageGroup(
    $input: DeleteMessageGroupInput!
    $condition: ModelMessageGroupConditionInput
  ) {
    deleteMessageGroup(input: $input, condition: $condition) {
      id
      userID
      messageGroupName
      createdAt
      MessageMembers {
        items {
          id
          messagegroupID
          userID
          groupAddedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      Messages {
        items {
          id
          messagegroupID
          userID
          sendAt
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
      eventID
      name
      price
      salesQuantity
      stockQuantity
      status
      TicketPurchases {
        userID
        status
        TicketPurchaseHistories {
          operation
          price
          operationDateTime
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
      eventID
      name
      price
      salesQuantity
      stockQuantity
      status
      TicketPurchases {
        userID
        status
        TicketPurchaseHistories {
          operation
          price
          operationDateTime
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
      eventID
      name
      price
      salesQuantity
      stockQuantity
      status
      TicketPurchases {
        userID
        status
        TicketPurchaseHistories {
          operation
          price
          operationDateTime
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTicketPurchase = /* GraphQL */ `
  mutation CreateTicketPurchase(
    $input: CreateTicketPurchaseInput!
    $condition: ModelTicketPurchaseConditionInput
  ) {
    createTicketPurchase(input: $input, condition: $condition) {
      userID
      status
      TicketPurchaseHistories {
        operation
        price
        operationDateTime
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateTicketPurchase = /* GraphQL */ `
  mutation UpdateTicketPurchase(
    $input: UpdateTicketPurchaseInput!
    $condition: ModelTicketPurchaseConditionInput
  ) {
    updateTicketPurchase(input: $input, condition: $condition) {
      userID
      status
      TicketPurchaseHistories {
        operation
        price
        operationDateTime
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteTicketPurchase = /* GraphQL */ `
  mutation DeleteTicketPurchase(
    $input: DeleteTicketPurchaseInput!
    $condition: ModelTicketPurchaseConditionInput
  ) {
    deleteTicketPurchase(input: $input, condition: $condition) {
      userID
      status
      TicketPurchaseHistories {
        operation
        price
        operationDateTime
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      thumbnailImageKey
      mainCategory
      overview
      status
      userID
      startDate
      startTime
      endDate
      endTime
      EventEntries {
        items {
          eventID
          userID
          status
          id
          createdAt
          updatedAt
          eventEventEntriesId
        }
        nextToken
      }
      postCode1
      postCode2
      prefecture
      city
      address
      hashtags
      Tickets {
        items {
          id
          eventID
          name
          price
          salesQuantity
          stockQuantity
          status
          TicketPurchases {
            userID
            status
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      thumbnailImageKey
      mainCategory
      overview
      status
      userID
      startDate
      startTime
      endDate
      endTime
      EventEntries {
        items {
          eventID
          userID
          status
          id
          createdAt
          updatedAt
          eventEventEntriesId
        }
        nextToken
      }
      postCode1
      postCode2
      prefecture
      city
      address
      hashtags
      Tickets {
        items {
          id
          eventID
          name
          price
          salesQuantity
          stockQuantity
          status
          TicketPurchases {
            userID
            status
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      thumbnailImageKey
      mainCategory
      overview
      status
      userID
      startDate
      startTime
      endDate
      endTime
      EventEntries {
        items {
          eventID
          userID
          status
          id
          createdAt
          updatedAt
          eventEventEntriesId
        }
        nextToken
      }
      postCode1
      postCode2
      prefecture
      city
      address
      hashtags
      Tickets {
        items {
          id
          eventID
          name
          price
          salesQuantity
          stockQuantity
          status
          TicketPurchases {
            userID
            status
            id
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEventEntry = /* GraphQL */ `
  mutation CreateEventEntry(
    $input: CreateEventEntryInput!
    $condition: ModelEventEntryConditionInput
  ) {
    createEventEntry(input: $input, condition: $condition) {
      eventID
      userID
      status
      id
      createdAt
      updatedAt
      eventEventEntriesId
    }
  }
`;
export const updateEventEntry = /* GraphQL */ `
  mutation UpdateEventEntry(
    $input: UpdateEventEntryInput!
    $condition: ModelEventEntryConditionInput
  ) {
    updateEventEntry(input: $input, condition: $condition) {
      eventID
      userID
      status
      id
      createdAt
      updatedAt
      eventEventEntriesId
    }
  }
`;
export const deleteEventEntry = /* GraphQL */ `
  mutation DeleteEventEntry(
    $input: DeleteEventEntryInput!
    $condition: ModelEventEntryConditionInput
  ) {
    deleteEventEntry(input: $input, condition: $condition) {
      eventID
      userID
      status
      id
      createdAt
      updatedAt
      eventEventEntriesId
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      nickname
      gender
      birthdate
      job
      locale
      profile
      userIconKey
      twitterID
      instagramID
      status
      deletedAt
      rating
      EventEntries {
        items {
          eventID
          userID
          status
          id
          createdAt
          updatedAt
          eventEventEntriesId
        }
        nextToken
      }
      Events {
        items {
          id
          name
          thumbnailImageKey
          mainCategory
          overview
          status
          userID
          startDate
          startTime
          endDate
          endTime
          EventEntries {
            nextToken
          }
          postCode1
          postCode2
          prefecture
          city
          address
          hashtags
          Tickets {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      TicketPurchases {
        items {
          userID
          status
          TicketPurchaseHistories {
            operation
            price
            operationDateTime
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      MessageGroups {
        items {
          id
          userID
          messageGroupName
          createdAt
          MessageMembers {
            nextToken
          }
          Messages {
            nextToken
          }
          updatedAt
        }
        nextToken
      }
      MessageMembers {
        items {
          id
          messagegroupID
          userID
          groupAddedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      Messages {
        items {
          id
          messagegroupID
          userID
          sendAt
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      nickname
      gender
      birthdate
      job
      locale
      profile
      userIconKey
      twitterID
      instagramID
      status
      deletedAt
      rating
      EventEntries {
        items {
          eventID
          userID
          status
          id
          createdAt
          updatedAt
          eventEventEntriesId
        }
        nextToken
      }
      Events {
        items {
          id
          name
          thumbnailImageKey
          mainCategory
          overview
          status
          userID
          startDate
          startTime
          endDate
          endTime
          EventEntries {
            nextToken
          }
          postCode1
          postCode2
          prefecture
          city
          address
          hashtags
          Tickets {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      TicketPurchases {
        items {
          userID
          status
          TicketPurchaseHistories {
            operation
            price
            operationDateTime
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      MessageGroups {
        items {
          id
          userID
          messageGroupName
          createdAt
          MessageMembers {
            nextToken
          }
          Messages {
            nextToken
          }
          updatedAt
        }
        nextToken
      }
      MessageMembers {
        items {
          id
          messagegroupID
          userID
          groupAddedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      Messages {
        items {
          id
          messagegroupID
          userID
          sendAt
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      nickname
      gender
      birthdate
      job
      locale
      profile
      userIconKey
      twitterID
      instagramID
      status
      deletedAt
      rating
      EventEntries {
        items {
          eventID
          userID
          status
          id
          createdAt
          updatedAt
          eventEventEntriesId
        }
        nextToken
      }
      Events {
        items {
          id
          name
          thumbnailImageKey
          mainCategory
          overview
          status
          userID
          startDate
          startTime
          endDate
          endTime
          EventEntries {
            nextToken
          }
          postCode1
          postCode2
          prefecture
          city
          address
          hashtags
          Tickets {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      TicketPurchases {
        items {
          userID
          status
          TicketPurchaseHistories {
            operation
            price
            operationDateTime
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      MessageGroups {
        items {
          id
          userID
          messageGroupName
          createdAt
          MessageMembers {
            nextToken
          }
          Messages {
            nextToken
          }
          updatedAt
        }
        nextToken
      }
      MessageMembers {
        items {
          id
          messagegroupID
          userID
          groupAddedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      Messages {
        items {
          id
          messagegroupID
          userID
          sendAt
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSpace = /* GraphQL */ `
  mutation CreateSpace(
    $input: CreateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    createSpace(input: $input, condition: $condition) {
      id
      name
      overview
      size
      capacity
      postalCode
      prefCode
      city
      address
      contact
      feePerHour
      userID
      startDate
      endDate
      sunAvailableStartTime
      sunAvailableEndTime
      monAvailableStartTime
      monAvailableEndTime
      tueAvailableStartTime
      tueAvailableEndTime
      wedAvailableStartTime
      wedAvailableEndTime
      thuAvailableStartTime
      thuAvailableEndTime
      friAvailableStartTime
      friAvailableEndTime
      satAvailableStartTime
      satAvailableEndTime
      holAvailableStartTime
      holAvailableEndTime
      reservationPeriod
      reservationApproval
      AvailableFacilities {
        id
        name
      }
      SpaceCategories {
        id
        name
      }
      SpacePictures {
        index
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSpace = /* GraphQL */ `
  mutation UpdateSpace(
    $input: UpdateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    updateSpace(input: $input, condition: $condition) {
      id
      name
      overview
      size
      capacity
      postalCode
      prefCode
      city
      address
      contact
      feePerHour
      userID
      startDate
      endDate
      sunAvailableStartTime
      sunAvailableEndTime
      monAvailableStartTime
      monAvailableEndTime
      tueAvailableStartTime
      tueAvailableEndTime
      wedAvailableStartTime
      wedAvailableEndTime
      thuAvailableStartTime
      thuAvailableEndTime
      friAvailableStartTime
      friAvailableEndTime
      satAvailableStartTime
      satAvailableEndTime
      holAvailableStartTime
      holAvailableEndTime
      reservationPeriod
      reservationApproval
      AvailableFacilities {
        id
        name
      }
      SpaceCategories {
        id
        name
      }
      SpacePictures {
        index
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpace = /* GraphQL */ `
  mutation DeleteSpace(
    $input: DeleteSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    deleteSpace(input: $input, condition: $condition) {
      id
      name
      overview
      size
      capacity
      postalCode
      prefCode
      city
      address
      contact
      feePerHour
      userID
      startDate
      endDate
      sunAvailableStartTime
      sunAvailableEndTime
      monAvailableStartTime
      monAvailableEndTime
      tueAvailableStartTime
      tueAvailableEndTime
      wedAvailableStartTime
      wedAvailableEndTime
      thuAvailableStartTime
      thuAvailableEndTime
      friAvailableStartTime
      friAvailableEndTime
      satAvailableStartTime
      satAvailableEndTime
      holAvailableStartTime
      holAvailableEndTime
      reservationPeriod
      reservationApproval
      AvailableFacilities {
        id
        name
      }
      SpaceCategories {
        id
        name
      }
      SpacePictures {
        index
        key
      }
      createdAt
      updatedAt
    }
  }
`;
