import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    top: true;
    iphone13: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      light: "#e25d5a",
      main: "#DB3531",
      dark: "#992522",
      contrastText: "#fff",
    },
    secondary: {
      light: "#33a6b9",
      main: "#0090a8",
      dark: "#006475",
      contrastText: "#fff",
    },
  },

  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontWeight: 700,
      fontSize: "1.5rem",
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      top: 1020,
      iphone13: 430,
    },
  },
});

export default theme;
