import React, { useEffect, useContext } from "react";

// 外部パッケージ
import useMedia from "use-media";
import { useForm, Controller } from "react-hook-form";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

// MUI
import { Button, Box, TextField, FormControlLabel, Checkbox, Grid } from "@mui/material";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import WcIcon from "@mui/icons-material/Wc";
import WifiIcon from "@mui/icons-material/Wifi";
import TvIcon from "@mui/icons-material/Tv";
import KitchenIcon from "@mui/icons-material/Kitchen";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import SpeakerIcon from "@mui/icons-material/Speaker";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import DeckIcon from "@mui/icons-material/Deck";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CameraIndoorIcon from "@mui/icons-material/CameraIndoor";
import PowerIcon from "@mui/icons-material/Power";

// その他
import { UserInputData } from "components/AddSpace/AddSpace";
import theme from "components/MuiTheme";
import { getDebugger } from "components/Debugger";

var debug = getDebugger(false);

const styles = {
  facility: css`
    display: flex;
    font-size: 15px;
  `,
  h1: css`
    padding-top: 20px;
    display: flex;
    font-size: 15px;
    font-weight: bold;
    opacity: 0.8;
  `,
  h2: css`
    padding-top: 110px;
    font-size: 48px;
    color: #fff;
    text-shadow: 0 0 0.2em rgba(0, 0, 0, 1);
    height: 50%;
  `,
};

const facilities = [
  {
    id: 0,
    item: (
      <div css={styles.facility}>
        <PowerIcon style={{ color: "#999999" }} />
        <div>電源　　</div>
      </div>
    ),
    name: "power",
  },
  {
    id: 1,
    item: (
      <div css={styles.facility}>
        <CheckroomIcon style={{ color: "#999999" }} />
        <div>着替えスペース</div>
      </div>
    ),
    name: "cloth",
  },
  {
    id: 2,
    item: (
      <div css={styles.facility}>
        <DeviceThermostatIcon style={{ color: "#999999" }} />
        <div>エアコン</div>
      </div>
    ),
    name: "air",
  },
  {
    id: 3,
    item: (
      <div css={styles.facility}>
        <MicrowaveIcon style={{ color: "#999999" }} />
        <div>電子レンジ</div>
      </div>
    ),
    name: "range",
  },
  {
    id: 4,
    item: (
      <div css={styles.facility}>
        <RestaurantIcon style={{ color: "#999999" }} />
        <div>キッチン</div>
      </div>
    ),
    name: "kit",
  },
  {
    id: 5,
    item: (
      <div css={styles.facility}>
        <WifiIcon style={{ color: "#999999" }} />
        <div>Wi-Fi</div>
      </div>
    ),
    name: "wifi",
  },
  {
    id: 6,
    item: (
      <div css={styles.facility}>
        <TvIcon style={{ color: "#999999" }} />
        <div>モニター</div>
      </div>
    ),
    name: "tv",
  },
  {
    id: 7,
    item: (
      <div css={styles.facility}>
        <CameraIndoorIcon style={{ color: "#999999" }} />
        <div>防犯カメラ</div>
      </div>
    ),
    name: "camera",
  },
  {
    id: 8,
    item: (
      <div css={styles.facility}>
        <SpeakerIcon style={{ color: "#999999" }} />
        <div>音声機器</div>
      </div>
    ),
    name: "music",
  },
  {
    id: 9,
    item: (
      <div css={styles.facility}>
        <OutdoorGrillIcon style={{ color: "#999999" }} />
        <div>BBQ設備</div>
      </div>
    ),
    name: "bbq",
  },
  {
    id: 10,
    item: (
      <div css={styles.facility}>
        <WcIcon style={{ color: "#999999" }} />
        <div>トイレ　</div>
      </div>
    ),
    name: "toilet",
  },
  {
    id: 11,
    item: (
      <div css={styles.facility}>
        <SelfImprovementIcon style={{ color: "#999999" }} />
        <div>ヨガマット</div>
      </div>
    ),
    name: "mat",
  },
  {
    id: 12,
    item: (
      <div css={styles.facility}>
        <DeckIcon style={{ color: "#999999" }} />
        <div>テラス　</div>
      </div>
    ),
    name: "teras",
  },
  {
    id: 13,
    item: (
      <div css={styles.facility}>
        <LocalParkingIcon style={{ color: "#999999" }} />
        <div>敷地内無料駐車場</div>
      </div>
    ),
    name: "parking",
  },
  {
    id: 14,
    item: (
      <div css={styles.facility}>
        <KitchenIcon style={{ color: "#999999" }} />
        <div>冷蔵庫　</div>
      </div>
    ),
    name: "rif",
  },
  {
    id: 15,
    item: (
      <div css={styles.facility}>
        <DriveEtaIcon style={{ color: "#999999" }} />
        <div>敷地内有料駐車場</div>
      </div>
    ),
    name: "car",
  },
];

const Facility = (props) => {
  const { currentState, setCurrentState } = useContext(UserInputData);

  const onSubmit = (action) => {
    if (action === "back") {
      props.handleBack();
    } else {
      props.handleNext();
    }
    const data = getValues();
    setCurrentState({ ...currentState, Facility: data });
  };

  const { control, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      power: false,
      air: false,
      range: false,
      wifi: false,
      kit: false,
      tv: false,
      camera: false,
      music: false,
      bbq: false,
      toilet: false,
      cloth: false,
      teras: false,
      parking: false,
      rif: false,
      car: false,
      mat: false,
      contact: "",
    },
  });

  // 保持している値を表示する
  useEffect(() => {
    if (currentState.Facility) {
      setValue("power", currentState.Facility.power);
      setValue("air", currentState.Facility.air);
      setValue("range", currentState.Facility.range);
      setValue("wifi", currentState.Facility.wifi);
      setValue("kit", currentState.Facility.kit);
      setValue("tv", currentState.Facility.tv);
      setValue("camera", currentState.Facility.camera);
      setValue("music", currentState.Facility.music);
      setValue("bbq", currentState.Facility.bbq);
      setValue("toilet", currentState.Facility.toilet);
      setValue("cloth", currentState.Facility.cloth);
      setValue("teras", currentState.Facility.teras);
      setValue("parking", currentState.Facility.parking);
      setValue("rif", currentState.Facility.rif);
      setValue("car", currentState.Facility.car);
      setValue("mat", currentState.Facility.mat);
      setValue("contact", currentState.Facility.contact);
    }
  }, [currentState.Facility, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h1 css={styles.h1}>貸し出し可能設備</h1>
        <form onSubmit={handleSubmit((data) => debug("data", data.item_ids))}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {facilities.map((facility, index) => (
              <Controller
                name={facility.name}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    control={<Checkbox checked={value} onChange={onChange} />}
                    key={facility.id}
                    label={facility.item}
                  />
                )}
              />
            ))}
          </Grid>
        </form>
        <h1 css={styles.h1}>プランナーへの連絡事項</h1>
        <Controller
          control={control}
          name="contact"
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              rows={6}
              required
              fullWidth
              id="inputContact"
              label="設備利用上の注意点。"
              color="secondary"
              margin="normal"
              inputProps={{ maxLength: 500, style: { fontSize: 14 } }}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />
          )}
        />
      </div>
      <Box sx={{ mb: 2 }}>
        <div>
          <Button onClick={() => onSubmit("back")} sx={{ mt: 1, mr: 1 }}>
            戻る
          </Button>
          <Button type="submit" variant="contained" sx={{ mt: 1, mr: 1 }}>
            次へ
          </Button>
        </div>
      </Box>
    </form>
  );
};

export default Facility;

const FacilityTitle = () => {
  const isWide = useMedia({ maxWidth: theme.breakpoints.values.top });

  return <div>{!isWide && <h1 css={styles.h2}>提供できる設備をお選びください。</h1>}</div>;
};

export { FacilityTitle };
