import { atom } from "recoil";
import { getCurrentDate } from "components/common/Utils";

/**
 * Object structure for the NewDetails.
 */
interface NewDetails {
  eventName: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  postCode1: string;
  postCode2: string;
  prefectures: string;
  cities: string;
  address: string;
}

/**
 * Object structure for the NewCategoryTarget.
 */
interface NewCategoryTarget {
  hashtag1: string;
  hashtag2: string;
  hashtag3: string;
  hashtag4: string;
  mainCategory: string;
}

/**
 * Object structure for the NewTicketSupport.
 */
interface NewTicketSupport {
  ticketName: string;
  ticketPrice: number;
  ticketSalesNumber: number;
}

/**
 * Object structure for the NewOverview.
 */
interface NewOverview {
  overview: string;
}

/**
 * Object structure for the UserInputData.
 */
interface UserInputData {
  thumbnailImageFile?: File;
  thumbnailImageKey?: string;
  requiresUploadThumbnail: boolean;
  requiresRemoveOldThumbnail: boolean;
  NewDetails: NewDetails;
  NewCategoryTarget: NewCategoryTarget;
  NewTicketSupport: NewTicketSupport;
  NewOverview: NewOverview;
}

/**
 * Atom to manage user input data
 */
export const userInputDataAtom = atom<UserInputData>({
  key: "userInputDataAtom",
  default: {
    requiresUploadThumbnail: false,
    requiresRemoveOldThumbnail: false,
    NewDetails: {
      eventName: "",
      startDate: getCurrentDate(),
      endDate: getCurrentDate(),
      startTime: "00:00",
      endTime: "00:00",
      postCode1: "",
      postCode2: "",
      prefectures: "",
      cities: "",
      address: "",
    },
    NewCategoryTarget: {
      hashtag1: "",
      hashtag2: "",
      hashtag3: "",
      hashtag4: "",
      mainCategory: "",
    },
    NewTicketSupport: {
      ticketName: "",
      ticketPrice: 0,
      ticketSalesNumber: 0,
    },
    NewOverview: {
      overview: "",
    },
  },
});
