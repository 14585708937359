import { MasterType } from "./SelectValues";

/**
 * 現在の日時をYYYY-MM-DD形式で返す
 * @return retStr {string} - 現在の日時(YYYY-MM-DD)
 */
export const getCurrentDate = () => {
  let retStr = "";
  let now = new Date();
  retStr += now.getFullYear() + "-";
  retStr += (now.getMonth() + 1).toString().padStart(2, "0") + "-";
  retStr += now.getDate().toString().padStart(2, "0");
  return retStr;
};

/**
 * 日付と時間からUTC時刻へ変換
 * @param {String} date - YYYY-MM-DD
 * @param {String} time - hh:mm
 * @return {String} - YYYY-MM-DDThh:mm:00.000Z (9時間遅くなる)
 */
export const formatToUTCDateTime = (date: string, time: string): string => {
  const dt = new Date(date + " " + time);
  const UTCDateTime = `${dt.getUTCFullYear()}-${("0" + (dt.getUTCMonth() + 1)).slice(-2)}-${(
    "0" + dt.getUTCDate()
  ).slice(-2)}T${("0" + dt.getUTCHours()).slice(-2)}:${("0" + dt.getUTCMinutes()).slice(-2)}:00.000Z`;
  return UTCDateTime;
};

/**
 * 開始日時と終了日時を画面表示用の文字列にする
 * @param {String} startDateTime - 開始日時
 * @param {String} endDateTime - 終了日時
 * @return {String} - YYYY年MM月DD日(week) hh:mm〜hh:mm
 */
export const formatDateTimeToEventDateTime = (startDateTime: string, endDateTime: string): string => {
  let sdt = new Date(startDateTime);
  let edt = new Date(endDateTime);
  const weeks = ["日", "月", "火", "水", "木", "金", "土"];
  const dateTime = `${sdt.getFullYear()}年${sdt.getMonth() + 1}月${sdt.getDate()}日(${weeks[sdt.getDay()]}) ${(
    "0" + sdt.getHours()
  ).slice(-2)}:${("0" + sdt.getMinutes()).slice(-2)}〜${("0" + edt.getHours()).slice(-2)}:${(
    "0" + edt.getMinutes()
  ).slice(-2)}`;
  return dateTime;
};

/**
 * 各種マスタの値をラベルに変換する
 * @param {MasterType} master - マスタ
 * @param {String} value - 値
 * @return {String} - ラベル、見つからない場合は空文字
 */
export const convertValueToLabel = (master: MasterType, value: string) => {
  const ret = master.find((el) => el.value === value);
  if (ret !== undefined) return ret.label;
  else return "";
};

type DateFormatterOptions = {
  isUTC?: boolean;
  dateDivider?: string;
  hasTime?: boolean;
  timeDivider?: string;
  dateTimeDivider?: string;
};

/**
 * Formats a Date object according to the specified options.
 *
 * @param {Date} date - The date to format.
 * @param {DateFormatterOptions} [options] - The options for formatting the date.
 * @param {boolean} [options.isUTC=false] - Use UTC instead of local time.
 * @param {string} [options.dateDivider=""] - The divider for date parts.
 * @param {boolean} [options.hasTime=false] - Include time in the output.
 * @param {string} [options.timeDivider=""] - The divider for time parts.
 * @param {string} [options.dateTimeDivider=""] - The divider between date and time.
 *
 * @returns {string} The formatted date string.
 */
export const formatDate = (date: Date, options?: DateFormatterOptions): string => {
  const { isUTC = false, dateDivider = "", hasTime = false, timeDivider = "", dateTimeDivider = "" } = options || {};

  const pad = (num: number): string => String(num).padStart(2, "0");

  const year = isUTC ? date.getUTCFullYear() : date.getFullYear();
  const month = (isUTC ? date.getUTCMonth() : date.getMonth()) + 1;
  const day = isUTC ? date.getUTCDate() : date.getDate();
  const hours = isUTC ? date.getUTCHours() : date.getHours();
  const minutes = isUTC ? date.getUTCMinutes() : date.getMinutes();
  const seconds = isUTC ? date.getUTCSeconds() : date.getSeconds();

  const datePart = `${year}${dateDivider ? dateDivider : ""}${pad(month)}${dateDivider ? dateDivider : ""}${pad(day)}`;

  if (hasTime) {
    const timePart = `${pad(hours)}${timeDivider ? timeDivider : ""}${pad(minutes)}${
      timeDivider ? timeDivider : ""
    }${pad(seconds)}`;
    return `${datePart}${dateTimeDivider}${timePart}`;
  }

  return datePart;
};
